import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const HopperSizeCalculator = ({ calculateResult, hopperSizeData }) => {

    const handleChange = (event) => {

        let { name, value } = event.target;

        // Convert to number if it's a float-like string (e.g., ".2" to "0.2")
        if (value.startsWith('.') && !isNaN(value)) {
            value = `0${value}`;
        }

        // Ensure the value is converted to a number if it's numeric
        const numericValue = parseFloat(value);

        // Update hopperSizeData with the numeric value
        hopperSizeData = { ...hopperSizeData, [name]: numericValue };

        let flag = true;

        // Validate all values in hopperSizeData
        for (let key in hopperSizeData) {
            if (hopperSizeData[key] <= 0 || isNaN(hopperSizeData[key])) {
                flag = false;
            }
        }

        if (flag) {
            calculateResult(hopperSizeData);
        }

    }

    useEffect(() => {

        // console.log( hopperSizeData )

    }, [hopperSizeData])

    return (
        <div>

            <div style={{ fontWeight: "bold", fontSize: '25px' }}>
                <span> Input </span>
            </div>

            <hr></hr>

            <Form>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Drying Time Min (Hours)</Form.Label> </Col>
                        <Col sm={4}>
                            <Form.Control name="minDryingTime" type="text" defaultValue={hopperSizeData.minDryingTime} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Drying Time Max (Hours)</Form.Label> </Col>
                        <Col sm={4}>
                            <Form.Control name="maxDryingTime" type="text" defaultValue={hopperSizeData.maxDryingTime} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Cycle Time (sec)</Form.Label> </Col>

                        <Col sm={4}>
                            <Form.Control name="cycleTime" type="text" defaultValue={hopperSizeData.cycleTime} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Shot Weight (gms) </Form.Label> </Col>

                        <Col sm={4}>
                            <Form.Control name="shotWeight" type="text" defaultValue={hopperSizeData.shotWeight} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </div>
    )
}

export default HopperSizeCalculator