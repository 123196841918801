import React, { useEffect } from 'react';
import { SpreadsheetComponent, SheetsDirective, SheetDirective, getCell, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-spreadsheet';
import { getRangeIndexes } from '@syncfusion/ej2-spreadsheet';
import '../../../assets/custom-stylesheet/grid_stylecss.css';

const AttributeGrid = ({ rowCount, AttributecolCount, AttributeGridData, setAttributeGridData, AttributeSpreadsheet, setSelectedRow, SelectedRow, PopulateAttributeSheet, setSelectedCol, SelectedCol, setArgs, column }) => {

    const protectSettings = { selectCells: true };

    const scrollSettings = {
        isFinite: true,
        enableVirtualization: false,
    };

    let isPaste = false;

    const dialogBeforeOpen = (args) => {

        if (args.dialogName === 'EditAlertDialog') {
            args.cancel = true
            // args.content = 'This cell is read only';
        }

        // Edit the dialog content using the dialogBeforeOpen event.
        if (args.dialogName === 'ValidationErrorDialog') {
            args.cancel = true
        }

    }

    const getIndex = () => {

        return new Promise((resolve, reject) => {

            // Getting the index of the selected row and storing in a variable
            let cell = AttributeSpreadsheet.current.getActiveSheet().activeCell;

            let cellIdx = getRangeIndexes(cell);

            // console.log(cellIdx)

            setSelectedRow(cellIdx[0]);

            if (column.length < 2 || cellIdx[1] === 0) {
                setSelectedCol(null);
            }
            else {
                setSelectedCol(cellIdx[1])
            }

            resolve()

        })

    }

    const getSheetData = () => {

        return new Promise((resolve, reject) => {

            // Getting the data from the AttributeSpreadsheet cells
            let dataObj = {};
            let dataArray = [];

            for (let i = 1; i <= rowCount; i++) {

                for (let j = 0; j < column.length; j++) {

                    dataObj[`value${j}`] = !getCell(i, j, AttributeSpreadsheet.current.getActiveSheet()).value ? '' : getCell(i, j, AttributeSpreadsheet.current.getActiveSheet()).value

                }

                dataArray.push(dataObj)

                dataObj = {}

            }

            resolve(dataArray)

        })

    }

    const cellEditing = (args) => {

        if (args.value !== args.oldValue && !isNaN(args.value)) {

            AttributeSpreadsheet.current.updateCell({ value: args.value }, args.address);

            GatherData()

        }

    };

    function countPassFail(data) {

        const result = {};

        // Iterate through each object in the array
        data.forEach(item => {

            // Iterate through each property in the object
            Object.entries(item).forEach(([key, value]) => {

                // Skip the first property 'value0'
                if (key !== 'value0') {

                    // Initialize the property in the result object if it doesn't exist
                    if (!result[key]) {
                        result[key] = { Pass: 0, Fail: 0 };
                    }

                    // Increment the count based on the value
                    if (value === 'Pass') {
                        result[key].Pass++;
                    } else if (value === 'Fail') {
                        result[key].Fail++;
                    }

                }
            });
        });

        return result;
    }

    const UpdateCount = (passFailCounts) => {

        const alphabetArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        // console.log(passFailCounts)

        for (let i = 1; i < AttributecolCount; i++) {

            AttributeSpreadsheet.current.updateCell({ value: `Pass = ${passFailCounts[`value${i}`] ? passFailCounts[`value${i}`]["Pass"] : 0} : Fail = ${passFailCounts[`value${i}`] ? passFailCounts[`value${i}`]["Fail"] : 0}` }, `${alphabetArray[i]}1`)

        }

    }

    const GatherData = () => {

        getIndex().then(() => {

            getSheetData().then((DataArrayObj) => {

                setAttributeGridData(DataArrayObj)

                // console.log(DataArrayObj)

                const Counts = countPassFail(DataArrayObj);

                // console.log(Counts)

                // Display the results
                UpdateCount(Counts);

                PopulateAttributeSheet(DataArrayObj)

            })

        })

    }

    function UnlockCells() {

        // PopulateAttributeSheet(AttributeGridData)

        const alphabetArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        for (let i = 1; i <= rowCount; i++) {

            AttributeSpreadsheet.current.updateCell({ value: i }, `A${i + 1}`); // Set the serial number in the first column

        }

        // ************************ Last cell of 1st column shows count.
        AttributeSpreadsheet.current.updateCell({ value: "Count" }, `A1`);

        AttributeSpreadsheet.current.cellFormat({ fontSize: '12pt' }, `A1:Z1`);

        AttributeSpreadsheet.current.cellFormat({ backgroundColor: '#fff', fontSize: '15pt' }, `B2:Z${rowCount + 1}`);

        for (let i = 1; i <= AttributecolCount; i++) {

            AttributeSpreadsheet.current.cellFormat({ backgroundColor: '#e0e0eb' }, `${alphabetArray[i - 1]}1`);

        }

        GatherData()

    }

    function beforeCellUpdate(args) {

        // Skip the cell update for paste action that contains characters, If you need you can skip this for all type of actions performed in the spreadsheet.
        if (isPaste) {

            let pastedValue = args.cell.value;

            // Match alphabets and special characters
            var regex = /[a-zA-Z]/g;

            if (pastedValue && pastedValue.toString().match(regex)) {
                args.cancel = true;
            }

            isPaste = false;

        }

    }

    function actionBegin(args) {

        if (args.args.eventArgs && args.args.eventArgs.requestType === 'paste') {
            isPaste = true;
        }

    }

    const beforeCellRenderHandler = (args) => {

        // PopulateAttributeSheet(AttributeGridData)

        setArgs(args)

        for (let i = 0; i < column.length; i++) {

            if (
                args.colIndex === i &&
                args.element.classList.contains('e-header-cell')
            ) {
                const text = column[i].header
                args.element.innerText = text;

            }

        }

    }

    useEffect(() => {

        // Populating Column 1 with serial number. *****************************
        const alphabetArray = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        for (let i = 1; i <= rowCount; i++) {

            AttributeSpreadsheet.current.updateCell({ value: i }, `A${i + 1}`); // Set the serial number in the first column

        }

        // ************************ Last cell of 1st column shows count.
        AttributeSpreadsheet.current.updateCell({ value: "Count" }, `A1`);

        // ************************** Css for Count Cell of every columnn.
        AttributeSpreadsheet.current.cellFormat({ backgroundColor: '#fff' }, `B1:Z${AttributeSpreadsheet.current.getActiveSheet().rowCount}`);

        for (let i = 1; i <= AttributecolCount; i++) {

            AttributeSpreadsheet.current.cellFormat({ backgroundColor: '#e0e0eb' }, `${alphabetArray[i - 1]}1`);

        }

        const handleKeyDown = (event) => {

            const alphabetArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

            // Check if the pressed key is 'P' or 'F'
            if ((event.key === 'P' || event.key === 'p') && SelectedRow !== 0) {

                AttributeSpreadsheet.current.updateCell({ value: "Pass" }, `${alphabetArray[SelectedCol]}${SelectedRow + 1}`);

                // console.log(`${alphabetArray[SelectedCol]}${SelectedRow+1}`)

            }
            else if ((event.key === 'F' || event.key === 'f') && SelectedRow !== 0) {

                AttributeSpreadsheet.current.updateCell({ value: "Fail" }, `${alphabetArray[SelectedCol]}${SelectedRow + 1}`);

                // console.log(`${alphabetArray[SelectedCol]}${SelectedRow+1}`)

            }
            else {

            }

        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };

    }, [rowCount, AttributeSpreadsheet, AttributecolCount, SelectedRow, SelectedCol])

    useEffect(() => {

        PopulateAttributeSheet(AttributeGridData)

        // eslint-disable-next-line
    }, [AttributeGridData])

    return (
        <div>

            <div className='mb-2' style={{ color: 'red', fontSize: '15px' }}>
                <span> Enter 'P' for Pass, 'F' for Fail. </span>
            </div>

            <div className="spreadsheet" id="Attribute_Sheet" onMouseEnter={UnlockCells}>

                <SpreadsheetComponent
                    ref={AttributeSpreadsheet}
                    onBlur={GatherData}
                    cellEdited={GatherData}
                    cellEditing={cellEditing}
                    className='attributeGrid'
                    width={"100%"}
                    height={300}
                    showFormulaBar={false}
                    showSheetTabs={false}
                    showRibbon={false}
                    scrollSettings={scrollSettings}
                    beforeCellRender={beforeCellRenderHandler}
                    dialogBeforeOpen={dialogBeforeOpen.bind(this)}
                    beforeCellUpdate={beforeCellUpdate.bind(this)}
                    actionBegin={actionBegin.bind(this)}
                    allowAutoFill={false} enableContextMenu={false} created={GatherData}
                >

                    <SheetsDirective>

                        <SheetDirective
                            frozenRows={1}
                            frozenColumns={1}
                            rowCount={rowCount + 1}
                            colCount={AttributecolCount}
                            isProtected={true}
                            protectSettings={protectSettings}
                            >

                            <ColumnsDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                                <ColumnDirective width={130}></ColumnDirective>
                            </ColumnsDirective>

                        </SheetDirective>

                    </SheetsDirective>

                </SpreadsheetComponent>

            </div>
        </div>
    )
}

export default AttributeGrid;