import React, { useEffect } from "react";
import "../../App.css";
import "../../../assets/custom-stylesheet/grid_stylecss.css";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  getCell,
} from "@syncfusion/ej2-react-spreadsheet";

import { getRangeIndexes } from "@syncfusion/ej2-spreadsheet";

const ZoneGrid = ({
  column,
  ZoneSpreadsheet,
  setColToBeDeleted,
  ZoneGridData,
  setZoneGridData,
  colCount,
  setArgs,
  PopulateZoneSheet,
  RenderHeaders,
}) => {
  const protectSettings = { selectCells: true };

  const scrollSettings = { isFinite: true };

  const getIndex = () => {
    let dataArray = [];

    // Getting the index of the selected row and storing in a variable
    let cell = ZoneSpreadsheet.current.getActiveSheet().activeCell;

    let cellIdx = getRangeIndexes(cell);

    if (column.length < 2 || cellIdx[1] === 0) {
      setColToBeDeleted(null);
    } else {
      setColToBeDeleted(cellIdx[1]);
    }

    // Getting the data from the ZoneSpreadsheet cells
    let dataObj = {};

    for (let i = 0; i < ZoneGridData.length; i++) {
      for (let j = 0; j < column.length; j++) {
        dataObj[`value${j}`] = !getCell(
          i,
          j,
          ZoneSpreadsheet.current.getActiveSheet()
        ).value
          ? ""
          : getCell(i, j, ZoneSpreadsheet.current.getActiveSheet()).value;
      }

      dataArray.push(dataObj);

      dataObj = {};
    }

    setZoneGridData(dataArray);

    // console.log(dataArray)
  };

  const onCreated = () => {
    PopulateZoneSheet(ZoneGridData);
  };

  function UnlockCells() {
    PopulateZoneSheet(ZoneGridData);

    ZoneSpreadsheet.current.lockCells(`A1:Z${ZoneGridData.length}`, false);

    ZoneSpreadsheet.current.setColumnsWidth(120, ["A:Z"]);
  }

  const beforeCellRenderHandler = (args) => {
    PopulateZoneSheet(ZoneGridData);

    setArgs(args);

    for (let i = 0; i < column.length; i++) {
      if (
        args.colIndex === i &&
        args.element.classList.contains("e-header-cell")
      ) {
        const text = column[i].header;
        args.element.innerText = text;
      }
    }
  };

  const cellEditing = (args) => {
    if (args.value !== args.oldValue && !isNaN(args.value)) {
      ZoneSpreadsheet.current.updateCell(
        { value: args.value },
        args.address
      );

      getIndex();
    }
  };

  useEffect(() => {
    
    RenderHeaders();

    // eslint-disable-next-line
  }, [column]);

  function actionBegin(args) {

    if (args.action === 'clipboard' && args.args.eventArgs.requestType === 'paste') {
      //Set the type to 'Values' to paste only the values.
      args.args.eventArgs.type = 'Values';
    }

  }

  return (
    <div>
      <div className="spreadsheet mt-1" onMouseEnter={UnlockCells}>
        <SpreadsheetComponent
          onClick={getIndex}
          ref={ZoneSpreadsheet}
          width={700}
          height={80}
          cellEditing={cellEditing}
          cellEdited={getIndex}
          showFormulaBar={false}
          showSheetTabs={false}
          showRibbon={false}
          scrollSettings={scrollSettings}
          allowAutoFill={false}
          beforeCellRender={beforeCellRenderHandler}
          created={onCreated}
          enableContextMenu={false}
          allowImage={false} actionBegin={actionBegin}
        >
          <SheetsDirective>
            <SheetDirective
              rowCount={1}
              colCount={colCount}
              isProtected={true}
              protectSettings={protectSettings}
            ></SheetDirective>
          </SheetsDirective>
        </SpreadsheetComponent>
      </div>
    </div>
  );
};

export default ZoneGrid;
