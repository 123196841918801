import React, { useState, useRef, useEffect } from "react";
import ShotGrid from "./ShotGrid";
// Hook to get the parameter's from the URL
import { useParams } from "react-router-dom";
// Syncfusion chart control
import {
  ChartComponent,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  AxesDirective,
  AxisDirective,
  ScatterSeries,
  Tooltip,
  Legend,
} from "@syncfusion/ej2-react-charts";
import AddRow from "./AddRow";
import {
  HtmlEditor,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "reactstrap";
import DataService from "../../../services/ApiService";
import EditColumnHeader from "./EditColumnHeader";
import ShotCalcGrid from "./ShotCalcGrid";
import { toast } from "react-toastify";

const ShotRepeatability = ({ showAlert, ShotData, ToSaveShotData }) => {
  let ShotSpreadsheet = useRef();

  // To store the session Id getting from URL
  const [SessionId, setSessionId] = useState();

  // Getting session Id from URL
  var { sessionId } = useParams();

  const [Header1, setHeader1] = useState("Injection Speed Low");
  const [Header2, setHeader2] = useState("Injection Speed Medium");
  const [Header3, setHeader3] = useState("Injection Speed High");

  const [Acceptable_variation, setAcceptable_variation] = useState(null);

  let [Average, setAverage] = useState([]);
  let [Range, setRange] = useState([]);
  let [MaxPart, setMaxPart] = useState([]);
  let [MinPart, setMinPart] = useState([]);
  let [Percentage, setPercentage] = useState([]);

  // State and Event for the comment modal
  const [show, setShow] = useState(false);

  const [EditHeaderModal, setEditHeaderModal] = useState();

  const [EmptyHeaderError, setEmptyHeaderError] = useState(false);

  const toggleEmptyHeaderError = () => setEmptyHeaderError(!EmptyHeaderError);

  const toggleEditHeaderModal = () => {
    if (!Header1 || !Header2 || !Header3) {
      toggleEmptyHeaderError();
    } else {
      setEditHeaderModal(!EditHeaderModal);

      ShotSpreadsheet.current.refresh();

      setEmptyHeaderError(false);
    }
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  };

  // A state variable to store and set the value of textarea
  const [Comment, setComment] = useState("");

  const [Alert, setAlert] = useState(false);

  const [ShotGridData, setShotGridData] = useState([]);

  const [rowCount, setRowCount] = useState(10);

  const [rowToBeDeleted, setRowToBeDeleted] = useState();

  // Boolean variable to switch between the save and update button
  const [showSave, setShowSave] = useState(true);

  const [args, setArgs] = useState("");

  // As the user enter's the number of row's it get's set in this variable.
  const [row, setRow] = useState(5);

  // Set's the visibility of the modal which we use to get the number of row's to be generated which is imported in Viscosity Grid.
  const [ShotAddRowModal, setShotAddRowModal] = useState();

  const ToggleAddRowModal = () => {
    setShotAddRowModal(!ShotAddRowModal);
  };

  const PopulateShotSheet = (CalcData) => {
    for (let i = 0; i < CalcData.length; i++) {
      ShotSpreadsheet.current.updateCell(
        { value: CalcData[i][`Shot ${i + 1}`] },
        `A${i + 1}`
      );
      ShotSpreadsheet.current.updateCell(
        { value: CalcData[i]["value1"] },
        `B${i + 1}`
      );
      ShotSpreadsheet.current.updateCell(
        { value: CalcData[i]["value2"] },
        `C${i + 1}`
      );
      ShotSpreadsheet.current.updateCell(
        { value: CalcData[i]["value3"] },
        `D${i + 1}`
      );
    }
  };

  // This is the event to do the above said thing.
  const addRow = (e) => {
    e.preventDefault();

    // Storing the number entered
    setRow(e.target.value);
    // console.log(e.target.value);
  };

  const RenderHeaders = () => {
    // Condition to check whether the rendered element is header cell.
    if (
      args.colIndex === 0 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Shot";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 1 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = Header1;
      args.element.innerText = text;
    }
    if (
      args.colIndex === 2 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = Header2;
      args.element.innerText = text;
    }
    if (
      args.colIndex === 3 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = Header3;
      args.element.innerText = text;
    }

    // console.log(args)
  };

  // This is the event which gets called as the user click's ok in the add row modal.
  // what it does is it run's a loop as many times the row variable is and along with that it pushes an object containing all the key's based on the grid with an id generated using nanoid library and then set's the row1 in the main array i.e ShotGridData.

  // Then using editFormData object, handleEditFormChange and handleEditFormSubmit we store the data in these objects as the user enter's in the grid's input field's
  const increaseRow = () => {
    // Updating the total rows variable
    setRowCount(parseInt(rowCount) + parseInt(row));

    setAlert(true);

    setRow(null);
  };

  // This is the event which deletes the row as clicked on the delete icon, id of the row gets passed and using filter method that row is filtered out.
  const deleteRow2 = (id) => {
    ShotSpreadsheet.current.delete(rowToBeDeleted, rowToBeDeleted, "Row", 0);

    setRowCount(parseInt(rowCount) - 1);

    ShotGridData.splice(rowToBeDeleted, 1);

    PopulateShotSheet(ShotGridData);

    // console.log(ShotGridData)

    setAlert(true);
  };

  // Event to set the data entered in the textarea
  const getComment = (e) => {
    if (e.target) {
      setComment(e.target.value);
    } else {
      setComment(e.value); // Get the RTE value
      setAlert(true);
    }
  };

  function calculateNiceNumbers(min, max, numTicks) {
    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
      niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
      niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
      niceStep = 5 * magnitudePow;
    } else {
      niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from(
      { length: numNiceTicks },
      (_, i) => niceMin + i * niceStep
    );

    return {
      min: niceMin,
      max: niceMax,
      step: niceStep,
      ticks: niceTicks,
    };
  }

  function generateChartData(Average, Max, Min, Variation) {
    const result = [];
    let dataPoint = {};

    for (let i = 0; i < Average.length; i++) {
      if (i === 0) {
        dataPoint = {
          col_header: `${Header1} (Col${i + 1})`,
          Average: Average[i],
          min: Min[i],
          max: Max[i],
          Variation: Variation[i],
        };
      } else if (i === 1) {
        dataPoint = {
          col_header: `${Header2} (Col${i + 1})`,
          Average: Average[i],
          min: Min[i],
          max: Max[i],
          Variation: Variation[i],
        };
      } else if (i === 2) {
        dataPoint = {
          col_header: `${Header3} (Col${i + 1})`,
          Average: Average[i],
          min: Min[i],
          max: Max[i],
          Variation: Variation[i],
        };
      }

      result.push(dataPoint);
    }

    return result;
  }

  const [ChartData, setChartData] = useState([]);

  const [PrimaryYNumbers, setPrimaryYNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const [SecondaryYNumbers, setSecondaryYNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  // Event to set the Min, Max and Interval of graph i.e scalling the graph
  const scaleGraph = () => {
    if (ShotGridData.length > 0) {
      const outputArray = generateChartData(
        Average,
        MaxPart,
        MinPart,
        Percentage
      );

      let combinedArray = [...Average, ...MaxPart, ...MinPart];

      // Calculate min and max values
      const minValue = Math.min(...combinedArray);
      const maxValue = Math.max(...combinedArray);

      // console.log(minValue, maxValue)

      setChartData(outputArray);

      setPrimaryYNumbers(calculateNiceNumbers(minValue, maxValue, 5));

      let YMargin =
        ((parseFloat(Math.max(...Percentage)) -
          parseFloat(Math.min(...Percentage))) /
          100) *
        10;

      if (parseFloat(Math.min(...Percentage)) > minValue) {
        setSecondaryYNumbers(
          calculateNiceNumbers(
            minValue - YMargin,
            Math.max(...Percentage) + YMargin,
            5
          )
        );
      } else {
        setSecondaryYNumbers(
          calculateNiceNumbers(
            parseFloat(Math.min(...Percentage)) - YMargin,
            Math.max(...Percentage) + YMargin,
            5
          )
        );
      }
    } else {
    }
  };

  const [ToPlotChart, setToPlotChart] = useState(true);

  useEffect(() => {
    if (ToPlotChart) scaleGraph();

    // eslint-disable-next-line
  }, [ShotGridData, Percentage]);

  useEffect(() => {
    const data = {
      session: SessionId,
      Headers: {
        Header1: Header1,
        Header2: Header2,
        Header3: Header3,
      },
      Acceptable_variation: Acceptable_variation,
      ShotGridData: ShotGridData,
      Comment: Comment ? Comment : "N/A",
    };

    // console.log(data)

    showAlert.current = true;

    ShotData.current = data;

    // eslint-disable-next-line
  }, [Header1, Header2, Header3, Acceptable_variation, ShotGridData, Comment]);

  const GetData = (SessionId) => {
    DataService.GetShotData(SessionId)
      .then((res) => {
        if (res.data) {
          ToSaveShotData.current = false;
          setHeader1(res.data.Headers.Header1);
          setHeader2(res.data.Headers.Header2);
          setHeader3(res.data.Headers.Header3);
          setShotGridData(res.data.ShotGridData);
          setAcceptable_variation(res.data.Acceptable_variation);
          setComment(res.data.Comment);
          setShowSave(false);
        } else {
          setShotGridData([]);
        }
      })
      .catch((err) => {});
  };

  // Event that will be called as soon as the Viscosity Curve Page load's so that if there is data available will get fetched
  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));

    if (SessionId) {
      GetData(SessionId);
    } else {
    }

    // console.log(SessionId)

    // eslint-disable-next-line
  }, [sessionId, SessionId]);

  return (
    <div>
      <EditColumnHeader
        EditHeaderModal={EditHeaderModal}
        toggleEditHeaderModal={toggleEditHeaderModal}
        setHeader1={setHeader1}
        setHeader2={setHeader2}
        setHeader3={setHeader3}
        Header1={Header1}
        Header2={Header2}
        Header3={Header3}
        RenderHeaders={RenderHeaders}
        Alert={Alert}
        EmptyHeaderError={EmptyHeaderError}
      />
      <div className="card equipmentDash p-3 ml-2 mt-4">
        <section
          className="b-primary b-r-4 mb-2"
          // style={{
          //   border: "1px solid #573DAC",
          //   marginBottom: "10px",
          //   marginTop: "25px",
          // }}
        >
          <div className="m1">
            <div className="pt-2 pb-2 pr-2 pl-1">
              <div className="d-flex col-md-12 mb-2">
                <div className="d-flex">
                  <div className="m-1 w-75">Acceptable Minimum Variation :</div>

                  <div>
                    <input
                      className="form-control mt-1"
                      onChange={(e) => setAcceptable_variation(e.target.value)}
                      name="Pressure_Units"
                      defaultValue={Acceptable_variation}
                      onKeyPress={(event) => {
                        if (!/[-0.0-9.0]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      type="text"
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div>
                    <button
                      className="btn btn-info btn-air-info mr-2 ml-2"
                      type="button"
                      onClick={ToggleAddRowModal}
                    >
                      {" "}
                      Add Row{" "}
                    </button>
                  </div>

                  <div>
                    <button
                      className="btn btn-warning btn-air-warning mr-2"
                      type="button"
                      onClick={deleteRow2}
                    >
                      {" "}
                      Delete Row{" "}
                    </button>
                  </div>

                  <div>
                    <button
                      className="btn btn-primary btn-air-primary mr-2"
                      type="button"
                      onClick={toggleEditHeaderModal}
                    >
                      {" "}
                      Edit Column Header{" "}
                    </button>

                    <AddRow
                      ToggleAddRowModal={ToggleAddRowModal}
                      ShotAddRowModal={ShotAddRowModal}
                      addRow={addRow}
                      increaseRow={increaseRow}
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div>
                    <button
                      className="btn btn-primary btn-air-primary mr-2"
                      type="button"
                      onClick={handleShow}
                    >
                      Comment
                    </button>
                  </div>

                  <div>
                    <button
                      className="btn btn-primary btn-air-primary mr-2"
                      onClick={scaleGraph}
                    >
                      {" "}
                      Show Graph{" "}
                    </button>
                  </div>

                  <Modal isOpen={show} centered>
                    <ModalHeader toggleEditHeaderModal={handleClose}>
                      Add Comment
                    </ModalHeader>
                    <ModalBody>
                      <RichTextEditorComponent
                        change={getComment}
                        value={Comment}
                        saveInterval="1"
                        toolbarSettings={toolbarSettings}
                        height={250}
                      >
                        <Inject services={[Toolbar, HtmlEditor]} />
                      </RichTextEditorComponent>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="dark" onClick={handleClose}>
                        {" "}
                        Save & Close{" "}
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="d-flex m-2">
              <div
                className="mt-2 mb-2 ml-2 mr-0 col-md-6"
                style={{ width: "50%" }}
              >
                <div className="mb-1" onClick={() => setToPlotChart(false)}>
                  <ShotGrid
                    ShotSpreadsheet={ShotSpreadsheet}
                    ShotGridData={ShotGridData}
                    setShotGridData={setShotGridData}
                    rowCount={rowCount}
                    setRowToBeDeleted={setRowToBeDeleted}
                    PopulateShotSheet={PopulateShotSheet}
                    Acceptable_variation={Acceptable_variation}
                    Header1={Header1}
                    Header2={Header2}
                    Header3={Header3}
                    setArgs={setArgs}
                    RenderHeaders={RenderHeaders}
                    setAverage={setAverage}
                    setRange={setRange}
                    setMaxPart={setMaxPart}
                    setMinPart={setMinPart}
                    setPercentage={setPercentage}
                    MaxPart={MaxPart}
                    MinPart={MinPart}
                  />
                </div>

                <div className="mt-3">
                  <ShotCalcGrid
                    Average={Average}
                    MaxPart={MaxPart}
                    MinPart={MinPart}
                    Range={Range}
                    Header1={Header1}
                    Header2={Header2}
                    Header3={Header3}
                    Percentage={Percentage}
                    Acceptable_variation={Acceptable_variation}
                  />
                </div>
              </div>

              <div className="mt-2 mb-2 col-md-6" style={{ width: "50%" }}>
                <div className="ml-1">
                  <ChartComponent
                    className="equipmentShortChart"
                    width="100%"
                    height="460"
                    border={{ width: 1, color: "darkblue" }}
                    tooltip={{ enable: true }}
                    primaryXAxis={{ valueType: "Category" }}
                    primaryYAxis={{
                      title: "Part Weights",
                      minimum: PrimaryYNumbers.min ? PrimaryYNumbers.min : 0,
                      maximum: PrimaryYNumbers.max ? PrimaryYNumbers.max : 0,
                      interval: PrimaryYNumbers.step ? PrimaryYNumbers.step : 0,
                    }}
                  >
                    <Inject
                      services={[
                        ScatterSeries,
                        Category,
                        DataLabel,
                        Tooltip,
                        Legend,
                      ]}
                    />

                    <AxesDirective>
                      {/* Secondary Y Axis */}
                      <AxisDirective
                        majorGridLines={{ width: 0 }}
                        title="% Variation"
                        name="secondaryY"
                        opposedPosition={true}
                        minimum={
                          SecondaryYNumbers.min ? SecondaryYNumbers.min : 0
                        }
                        maximum={
                          SecondaryYNumbers.max ? SecondaryYNumbers.max : 0
                        }
                        interval={
                          SecondaryYNumbers.step ? SecondaryYNumbers.step : 0
                        }
                      />
                    </AxesDirective>

                    <SeriesCollectionDirective>
                      {/* Scatter Series 1 with Primary X and Y Axes */}
                      <SeriesDirective
                        dataSource={ChartData}
                        xName="col_header"
                        yName="Average"
                        type="Scatter"
                        name="Average"
                        marker={{ width: 10, height: 10 }}
                      />

                      {/* Scatter Series 2 with Primary X Axis and Secondary Y Axis */}
                      <SeriesDirective
                        dataSource={ChartData}
                        xName="col_header"
                        yName="min"
                        type="Scatter"
                        name="Min"
                        marker={{ width: 10, height: 10 }}
                      />

                      <SeriesDirective
                        dataSource={ChartData}
                        xName="col_header"
                        yName="max"
                        type="Scatter"
                        name="Max"
                        marker={{ width: 10, height: 10 }}
                      />

                      <SeriesDirective
                        dataSource={ChartData}
                        xName="col_header"
                        yName="Variation"
                        type="Scatter"
                        name="%Variation"
                        yAxisName="secondaryY"
                        marker={{ width: 10, height: 10 }}
                      />
                    </SeriesCollectionDirective>
                  </ChartComponent>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <section className="card" style={{ border: "1px solid #573DAC" }}>
        
      </section> */}
    </div>
  );
};

export default ShotRepeatability;
