import React, { useState } from "react";
import "../App.css";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import MoldingCalculatorForm from "./MoldingCalculatorForm";
import MoldingCalculatorFormOutput from "./MoldingCalculatorFormOutput";
import ShotSizeCalculator from "./ShotSizeCalculator";
import ShotSizeTable from "./ShotSizeTable";
import RegrindCalculator from "./RegrindCalculator";
import RegrindOutputTables from "./RegrindOutputTables";
import HopperSizeCalculator from "./HopperSizeCalculator";
import HopperSizeOutput from "./HopperSizeOutput";
import WaterTable from "./waterFlowCalcs/WaterTable";
import FlowTypeMetric from "./waterFlowCalcs/FlowTypeMetric";
import FlowTypeEnglish from "./waterFlowCalcs/FlowTypeEnglish";
import MinFlowRateEnglish from "./waterFlowCalcs/MinFlowRateEnglish";
import MinFlowRateMetric from "./waterFlowCalcs/MinFlowRateMetric";
import ProcessTransfer from "./ProcessTransfer";
import "./style.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { psi_To_MPa, psi_To_bar } from "../FuncsForConversions/Pressure";

const Calculators = () => {
  const [moldingCalculationsInput, setMoldingCalculationsInput] = useState({
    partVolume: "",
    runnerVolume: "",
    specificGravity: "",
    projectedArea: "",
    runnerProjectedArea: "",
    numberOfRunners: "",
    numberOfCavities: "",
    averageCycleTime: "",
    shotCapacity: "",
    tonsPerInch: "",
    screwDiameter: "",
  });

  const [moldingResult, setMoldingResult] = useState({
    partWeight: "",
    runnerWeight: "",
    shotWeight: "",
    approximateResidenceTime: "",
    percentUsageOfBarrel: "",
    requiredTonnage: "",
    partsPerHour: "",
    partsPerEightHour: "",
    partsPerTwentyFourHour: "",
    moldTime: "",
    materialPerHour: "",
    materialPerEightHour: "",
    materialPerTwentyFourHour: "",
  });

  let [hopperInputData, setHopperInputData] = useState({
    minDryingTime: "",
    maxDryingTime: "",
    shotWeight: "",
    cycleTime: "",
  });

  const [hopperSizeResult, setHopperSizeResult] = useState({
    minSize: "",
    maxSize: "",
  });

  let [processResult, setProcessResult] = useState({
    shotSize: "",
    transferPosition: "",
    shotSizeTransfer: "",
    injectionPressure1: "",
    injectionPressure2: "",
    injectionPressure3: "",
    fillTime: "",
    caloInjSpeed: "",
    holdingPressure1: "",
    holdingPressure2: "",
    holdingPressure3: "",
    backPressure: "",
    screwRPM: "",
  });

  const [RegrindData, setRegrindData] = useState({
    singlePartWeight: "",
    cavities: "",
    singleRunnerWeight: "",
    runner: "",
  });

  const [RegrindResult, setRegrindResult] = useState({
    cavityWeight: "",
    runnerWeight: "",
    totalShotWeight: "",
    partWeight: "",
    totalRunnerWeight: "",
    regrindAmount: "",
    runnerWeightPercent: "",
    singleCavityWeight: "",
    singleRunnerWeight: "",
  });

  let [processInput, setProcessInput] = useState({
    intensificationRatio: "",
    screwDiameter: "",
    intensificationRatioTarget: "",
    screwDiameterTarget: "",
    shotSize: "",
    transferPosition: "",
    injectionPressure1: "",
    injectionPressure2: "",
    injectionPressure3: "",
    fillTime: "",
    holdingPressure1: "",
    holdingPressure2: "",
    holdingPressure3: "",
    backPressure: "",
    screwRPM: "",
  });

  const calculateHopperSizeResult = (hopperSizeData) => {
    let minSizeCalc =
      (hopperSizeData.minDryingTime * 3600 * hopperSizeData.shotWeight) /
      hopperSizeData.cycleTime /
      1000;

    let maxSizeCalc =
      (hopperSizeData.maxDryingTime * 3600 * hopperSizeData.shotWeight) /
      hopperSizeData.cycleTime /
      1000;

    setHopperSizeResult({
      minSize: Number(minSizeCalc).toFixed(0),
      maxSize: Number(maxSizeCalc).toFixed(0),
    });

    setHopperInputData({ ...hopperSizeData });
  };

  const calculateMoldingCalculations = (moldingCalculationsInput) => {
    let partWeightCalc =
      moldingCalculationsInput.partVolume *
      moldingCalculationsInput.specificGravity;

    let runnerWeightCalc =
      moldingCalculationsInput.runnerVolume *
      moldingCalculationsInput.specificGravity;

    let shotWeightCalc =
      partWeightCalc * moldingCalculationsInput.numberOfCavities +
      runnerWeightCalc * moldingCalculationsInput.numberOfRunners;

    let approximateResidenceTimeCalc =
      (moldingCalculationsInput.shotCapacity /
        (shotWeightCalc * (moldingCalculationsInput.specificGravity / 1.06))) *
      moldingCalculationsInput.averageCycleTime;

    let percentUsageOfBarrelCalc =
      shotWeightCalc *
      (moldingCalculationsInput.specificGravity /
        1.06 /
        moldingCalculationsInput.shotCapacity) *
      100;

    let requiredTonnageCalc =
      (moldingCalculationsInput.projectedArea *
        moldingCalculationsInput.numberOfCavities +
        moldingCalculationsInput.runnerProjectedArea *
          moldingCalculationsInput.numberOfRunners) *
      moldingCalculationsInput.tonsPerInch;

    let partsPerHourCalc =
      (3600 / moldingCalculationsInput.averageCycleTime) *
      moldingCalculationsInput.numberOfCavities;

    let partsPerEightHourCalc =
      (3600 / moldingCalculationsInput.averageCycleTime) *
      moldingCalculationsInput.numberOfCavities *
      8;

    let partsPerTwentyFourHourCalc =
      (3600 / moldingCalculationsInput.averageCycleTime) *
      moldingCalculationsInput.numberOfCavities *
      24;

    let moldTimeCalc =
      ((100 / moldingCalculationsInput.numberOfCavities) *
        moldingCalculationsInput.averageCycleTime) /
      60;

    let materialPerHourCalc =
      (3600 / moldingCalculationsInput.averageCycleTime) * shotWeightCalc;

    let materialPerEightHourCalc = materialPerHourCalc * 8;

    let materialPerTwentyFourHourCalc = materialPerHourCalc * 24;

    setMoldingResult({
      partWeight: partWeightCalc,
      runnerWeight: runnerWeightCalc,
      shotWeight: shotWeightCalc,
      approximateResidenceTime: approximateResidenceTimeCalc,
      percentUsageOfBarrel: percentUsageOfBarrelCalc,
      requiredTonnage: requiredTonnageCalc,
      partsPerHour: partsPerHourCalc,
      partsPerEightHour: partsPerEightHourCalc,
      partsPerTwentyFourHour: partsPerTwentyFourHourCalc,
      moldTime: moldTimeCalc,
      materialPerHour: materialPerHourCalc,
      materialPerEightHour: materialPerEightHourCalc,
      materialPerTwentyFourHour: materialPerTwentyFourHourCalc,
    });

    setMoldingCalculationsInput({ ...moldingCalculationsInput });
  };

  const calculateProcessResult = (processInput) => {
    let shotSizeCalc =
      Math.pow(
        processInput.screwDiameter / processInput.screwDiameterTarget,
        2
      ) * processInput.shotSize;
    let shotSizeTransferCalc =
      processInput.shotSize - processInput.transferPosition;
    let intensificationRatioCalc =
      processInput.intensificationRatio /
      processInput.intensificationRatioTarget;
    let screwRPMCalc =
      (processInput.screwDiameter / processInput.screwDiameterTarget) *
      processInput.screwRPM;

    let CalcInjSpeed = (processInput.shotSize - processInput.transferPosition) / processInput.fillTime

    let shotSizeTransferCalcEng = ((3.14 * processInput.screwDiameter * processInput.screwDiameter) / 4) * (processInput.shotSize - processInput.transferPosition)

    let shotSizeTransferCalcEngAct = (3 * shotSizeTransferCalcEng) / (3.14 * processInput.screwDiameterTarget * processInput.screwDiameterTarget)

    let BackPressure1Eng = (processInput.backPressure * processInput.intensificationRatio) / processInput.intensificationRatioTarget

    let BackPressure2 = psi_To_bar(BackPressure1Eng, 2)

    let BackPressure3 = psi_To_MPa(BackPressure1Eng, 2)

    // console.log(BackPressure2)

    setProcessResult({
      shotSize: shotSizeCalc.toFixed(2),
      shotSizeTransfer: shotSizeTransferCalc,
      shotSizeTransferCalcEng: shotSizeTransferCalcEngAct.toFixed(2),
      BackPressure1: BackPressure1Eng.toFixed(2),
      BackPressure2: BackPressure2,
      BackPressure3: BackPressure3,
      injectionPressure1: (
        intensificationRatioCalc * processInput.injectionPressure1
      ).toFixed(2),
      injectionPressure2: (
        intensificationRatioCalc * processInput.injectionPressure2
      ).toFixed(2),
      injectionPressure3: (
        intensificationRatioCalc * processInput.injectionPressure3
      ).toFixed(2),
      holdingPressure1: (
        intensificationRatioCalc * processInput.holdingPressure1
      ).toFixed(2),
      holdingPressure2: (
        intensificationRatioCalc * processInput.holdingPressure2
      ).toFixed(2),
      holdingPressure3: (
        intensificationRatioCalc * processInput.holdingPressure3
      ).toFixed(2),
      caloInjSpeed: CalcInjSpeed.toFixed(2),
      screwRPM: screwRPMCalc.toFixed(2),
    })

    setProcessInput({ ...processInput });
  };

  const calculateRegrindAmount = (x, pass, generation) => {
    if (pass - generation < 1) return 0;
    else if (pass - generation === 1) {
      return Math.pow(x / 100, generation) * 100;
    }

    return Math.pow(x / 100, generation) * (1 - x / 100) * 100;
  };

  const calculateRegrindResult = (RegrindData) => {
    let cavityWeightCalc = RegrindData.singlePartWeight * RegrindData.cavities;
    let runnerWeightCalc = RegrindData.singleRunnerWeight * RegrindData.runner;
    let partWeightCalc =
      (cavityWeightCalc * 100) / (cavityWeightCalc + runnerWeightCalc);
    let totalRunnerWeightCalc =
      (runnerWeightCalc * 100) / (cavityWeightCalc + runnerWeightCalc);
    let regrindAmountCalc = calculateRegrindAmount(
      totalRunnerWeightCalc,
      RegrindData.pass,
      RegrindData.gen
    );

    setRegrindResult({
      cavityWeight: cavityWeightCalc,
      runnerWeight: runnerWeightCalc,
      totalShotWeight: cavityWeightCalc + runnerWeightCalc,
      partWeight: partWeightCalc,
      totalRunnerWeight: totalRunnerWeightCalc,
      regrindAmount: regrindAmountCalc,
      runnerWeightPercent: totalRunnerWeightCalc,
      singlePartWeight: RegrindData.singlePartWeight,
      singleRunnerWeight: RegrindData.singleRunnerWeight,
    });

    setRegrindData({ ...RegrindData });
  };

  let headertext = [
    { text: "Molding Calculator" },
    { text: "Shot Size Estimation" },
    { text: "Hopper Size Calculations" },
    { text: "Regrind Calculations" },
    { text: "Water Flow Calculations" },
    { text: "Process Transfer" },
  ];

  function content0() {
    return (
      <div className="card pt-3 pb-3 ml-1">
        {/* <div className="b-primary b-r-4 mb-2 p-3"> */}
        <div className="d-flex">
          <div className="col-md-6">
            <div className="b-primary b-r-4 p-2">
              <MoldingCalculatorForm
                calculateResult={calculateMoldingCalculations}
                result={moldingResult}
                moldingCalculationsInput={moldingCalculationsInput}
                setMoldingCalculationsInput={setMoldingCalculationsInput}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="b-primary b-r-4 p-2">
              <MoldingCalculatorFormOutput result={moldingResult} />
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }

  function content1() {
    return (
      <div className="card pt-3 pb-3 ml-1">
        <div className="d-flex">
          <div className="col-md-6">
            <div className="b-primary b-r-4 p-2" style={{ height: "690px" }}>
              <ShotSizeCalculator />
            </div>
          </div>

          <div className="col-md-6">
            <div className="b-primary b-r-4 pt-2 pr-2 pb-4 pl-2">
              <ShotSizeTable />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function content2() {
    return (
      <div className="card pt-3 pb-3 ml-1">
        <div className="d-flex">
          <div className="col-md-6">
            <div className="b-primary b-r-4 p-2">
              <HopperSizeCalculator
                calculateResult={calculateHopperSizeResult}
                hopperSizeData={hopperInputData}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="b-primary b-r-4 pt-2 pr-2 pl-2"
              style={{ paddingBottom: 100 }}
            >
              <HopperSizeOutput result={hopperSizeResult} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function content3() {
    return (
      <div className="card ml-1">
        <div className="">
          <div className="b-primary b-r-4 p-2 m-3">
            <RegrindCalculator
              calculateResult={calculateRegrindResult}
              RegrindResult={RegrindResult}
              RegrindData={RegrindData}
            />
          </div>

          <div className="b-primary b-r-4 p-2 m-3">
            <RegrindOutputTables
              result={RegrindResult}
              calculateRegrindAmount={calculateRegrindAmount}
            />
          </div>
        </div>
      </div>
    );
  }

  function content4() {
    return (
      <div className="card pt-3 pb-3 ml-1">
        <div>
          <div className="d-flex">
            <div className="col-md-3">
              <div className="b-primary b-r-4 p-2">
                <FlowTypeEnglish />
              </div>
            </div>

            <div className="col-md-3">
              <div className="b-primary b-r-4 p-2">
                <MinFlowRateEnglish />
              </div>
            </div>

            <div className="col-md-3">
              <div className="b-primary b-r-4  pt-2 pr-2 pl-2 pb-4">
                <FlowTypeMetric />
              </div>
            </div>

            <div className="col-md-3">
              <div className="b-primary b-r-4 p-2">
                <MinFlowRateMetric />
              </div>
            </div>
          </div>

          <div className="mt-2">
            <div className="d-flex">
              <div className="col-md-6 mt-2">
                <div className="b-primary b-r-4 p-2">
                  <WaterTable toDisplay={1} />
                </div>
              </div>

              <div className="col-md-6 mt-2">
                <div className="b-primary b-r-4  pt-2 pr-2 pb-3 pl-2">
                  <WaterTable toDisplay={2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function content5() {
    return (
      <div className="card pt-3 pb-3 ml-1">
        <div className="col-md-12">
          <div className="b-primary b-r-4 p-3" style={{ height: "830px" }}>
            <ProcessTransfer
              calculateResult={calculateProcessResult}
              result={processResult}
              processInput={processInput}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="col-md-12">
        {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}
        <TabComponent
          heightAdjustMode="Auto"
          id="defaultTab"
          style={{ padding: "0 16" }}
        >
          <TabItemsDirective>
            <TabItemDirective header={headertext[0]} content={content0} />
            <TabItemDirective header={headertext[1]} content={content1} />
            <TabItemDirective header={headertext[2]} content={content2} />
            <TabItemDirective header={headertext[3]} content={content3} />
            <TabItemDirective header={headertext[4]} content={content4} />
            <TabItemDirective header={headertext[5]} content={content5} />
          </TabItemsDirective>
        </TabComponent>
      </div>

      {/* <Tabs defaultActiveKey="Molding Calculator" className="mb-3" fill>

        <Tab eventKey="Molding Calculator" title="Molding Calculator">

          <Container>

            <Row>

              <Col>
                <MoldingCalculatorForm calculateResult={calculateMoldingCalculations} result={moldingResult} />
              </Col>
              <Col>
                <MoldingCalculatorFormOutput result={moldingResult} />
              </Col>

            </Row>

          </Container>

        </Tab>

        <Tab eventKey="Shot Size Estimation" title="Shot Size Estimation">

          <Container>
            <Row>
              <Col>
                <ShotSizeCalculator />
              </Col>
              <Col>
                <ShotSizeTable />
              </Col>
            </Row>
          </Container>

        </Tab>

        <Tab
          eventKey="Hopper Size Calculations"
          title="Hopper Size Calculations"
        >
          <Container>
            <Row>
              <Col>
                <HopperSizeCalculator
                  calculateResult={calculateHopperSizeResult}
                />
              </Col>
              <Col>
                <HopperSizeOutput result={hopperSizeResult} />
              </Col>
            </Row>
          </Container>
        </Tab>

        <Tab eventKey="Regrind Calculations" title="Regrind Calculations">
          <Container>
            <Row>
              <Col>
                <RegrindCalculator calculateResult={calculateRegrindResult} RegrindResult={RegrindResult} />
              </Col>
            </Row>
            <Row>
              <Col>
                <RegrindOutputTables result={RegrindResult} calculateRegrindAmount={calculateRegrindAmount} />
              </Col>
            </Row>
          </Container>
        </Tab>

        <Tab eventKey="Water Flow Calculations" title="Water Flow Calculations">

          <Container>

            <Row>
              <Col>
                <FlowTypeEnglish />
              </Col>
              <Col>
                <MinFlowRateEnglish />
              </Col>
              <Col>
                <FlowTypeMetric />
              </Col>
              <Col>
                <MinFlowRateMetric />
              </Col>
            </Row>

            <Row>
              <Col>
                <WaterTable toDisplay={1} />
              </Col>
              <Col>
                <WaterTable toDisplay={2} />
              </Col>
            </Row>

          </Container>

        </Tab>

        <Tab eventKey="Process Transfer" title="Process Transfer">
          <ProcessTransfer calculateResult={calculateProcessResult} result={processResult} />
        </Tab>

      </Tabs> */}
    </>
  );
};

export default Calculators;
