import React, { useEffect, useState, useRef } from "react";
import "../App.css";

// Tab view component from syncfusion to navigate through six steps study
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

// Event having multiple method's to deal with the back-end
import DataService from "../../services/ApiService";

// Import all the six step's component which are created outside this file in same folder to code complexity
import ManagementUnit from "./ManagementUnit";

import { ToastContainer } from "react-toastify";
import Header2 from "../common/header-component/header2";

import { Button } from "reactstrap";
import Loader from "../../assets/Loader";

// Using a Function Component
const OptionSetting = ({ user }) => {
  const history = useHistory();

  const showAlert = useRef(false);

  const ToSaveUnitsData = useRef(true);

  const UnitsData = useRef({
    user: 1,
    Unit_Data: [
      {
        Weight_Units: "",
      },
    ],
  });

  let Stored_Units = JSON.parse(sessionStorage.getItem("StoredUnits")) || [];

  const [StoredUnits, setStoredUnits] = useState(Stored_Units);

  const [isLoading, setIsLoading] = useState(true);

  // Instance of dialog box which will be shown after saving
  const SavedandExitdialogBox = document.getElementById(
    "SavedandExitdialogBox"
  );

  // Instance of dialog box Asking for saving data before leaving study
  const dialogBox = document.getElementById("dialogBox");

  const BackToMold = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
    } else {
      history.push("/dashboard/mold");
    }
  };

  // Declare the names of the tab's
  let headertext = [
    { text: "User Management" },
    { text: "Unit Management" },
    { text: "Company Logo" },
  ];

  // These are the event's which will get called when clicked on the respective step's tab and once they are called they render the component of that respective tab which we have imported above
  function content0() {
    return <div></div>;
  }

  function content1() {
    return (
      <div>
        <ManagementUnit
          user={user}
          ToSaveUnitsData={ToSaveUnitsData}
          UnitsData={UnitsData}
          StoredUnits={StoredUnits}
          showAlert={showAlert}
        />
      </div>
    );
  }

  function content2() {
    return <div></div>;
  }

  const saveData = () => {
    return new Promise((resolve, reject) => {
      if (ToSaveUnitsData.current) {
        DataService.SaveUnits(UnitsData.current)
          .then((res) => {
            ToSaveUnitsData.current = false;

            showAlert.current = false;
          })
          .catch((err) => {});
      } else {
        DataService.UpdateUnits(user.id, UnitsData.current)
          .then((res) => {
            // console.log(UnitsData);

            ToSaveUnitsData.current = false;

            showAlert.current = false;
          })
          .catch((err) => {});
      }

      resolve();
    });
  };

  const GetUnits = (data) => {
    if (user) {
      DataService.GetUnits(user.id).then((res) => {
        // console.log(data[0].Unit_Data[0]);

        if (res.data.message) {
          UnitsData.current = {
            user: user.id,

            Unit_Data: [
              {
                Area: {
                  unit_id: data.Unit_Data[0].Area[0].unit_id,
                  decimals: data.Unit_Data[0].Area[0].decimals,
                  unit_name: ` ${data.Unit_Data[0].Area[0].unit_name}`,
                },
                Time: {
                  unit_id: data.Unit_Data[0].Time[0].unit_id,
                  decimals: data.Unit_Data[0].Time[0].decimals,
                  unit_name: ` ${data.Unit_Data[0].Time[0].unit_name}`,
                },
                Speed: {
                  unit_id: data.Unit_Data[0].Speed[0].unit_id,
                  decimals: data.Unit_Data[0].Speed[0].decimals,
                  unit_name: ` ${data.Unit_Data[0].Speed[0].unit_name}`,
                },
                Weight: {
                  unit_id: data.Unit_Data[0].Weight[0].unit_id,
                  decimals: data.Unit_Data[0].Weight[0].decimals,
                  unit_name: ` ${data.Unit_Data[0].Weight[0].unit_name}`,
                },
                Distance: {
                  unit_id: data.Unit_Data[0].Distance[0].unit_id,
                  decimals: data.Unit_Data[0].Distance[0].decimals,
                  unit_name: ` ${data.Unit_Data[0].Distance[0].unit_name}`,
                },
                Pressure: {
                  unit_id: data.Unit_Data[0].Pressure[0].unit_id,
                  decimals: data.Unit_Data[0].Pressure[0].decimals,
                  unit_name: ` ${data.Unit_Data[0].Pressure[0].unit_name}`,
                },
                Temperature: {
                  unit_id: data.Unit_Data[0].Temperature[0].unit_id,
                  decimals: data.Unit_Data[0].Temperature[0].decimals,
                  unit_name: ` ${data.Unit_Data[0].Temperature[0].unit_name}`,
                },
              },
            ],
          };
        } else {
          ToSaveUnitsData.current = false;

          UnitsData.current = res.data;

          // console.log(UnitsData.current);
        }
      });
    }
  };

  useEffect(() => {
    GetUnits(StoredUnits);
  }, [user]);

  // This function will be called when the user clicks on the save button of the confirmation dialog box before exiting the study
  const SaveandExit = () => {
    saveData().then(() => {
      closeDialog();
      SavedandExitdialogBox.classList.remove("hidden");
    });
  };

  // Event to finally close and exit study after saving
  const closeandExitSavedDialog = () => {
    const saveddialogBox = document.getElementById("SavedandExitdialogBox");

    saveddialogBox.classList.add("hidden");

    history.push("/dashboard/mold");
  };

  // Event to close the saving confirmation dialog
  const closeDialog = () => {
    const dialogBox = document.getElementById("dialogBox");

    dialogBox.classList.add("hidden");
  };

  // This function will be called when the user clicks on the save button of the study
  const SavedModal = () => {
    saveData().then(() => {
      const saveddialogBox = document.getElementById("SaveddialogBox");

      saveddialogBox.classList.remove("hidden");
    });
  };

  // Event to close the dialog which will be shown after saving
  const closeSavedDialog = () => {
    const saveddialogBox = document.getElementById("SaveddialogBox");

    saveddialogBox.classList.add("hidden");
  };

  const [ShowTabs, setShowTabs] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowTabs(false);
    }, 500);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <Header2 Title="Option Settings" />
      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex ">
            <div>
              <span
                className="BreadCrum"
                onClick={BackToMold}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "14px" }}>
                {" "}
                Options{" "}
              </span>
            </div>
          </div>
        </div>

        {/* To alert while going back to sessions page */}
        <div id="dialogBox" className="hidden">
          <h5> Nautilus </h5>
          <hr></hr>
          <div className="dialog-content">
            <p> Do you want to save the settings ? </p>
            <Button className="mr-1" id="closeDialogBtn" onClick={SaveandExit}>
              {" "}
              Yes{" "}
            </Button>
            <Button
              className="mr-1"
              id="closeDialogBtn"
              onClick={closeandExitSavedDialog}
            >
              {" "}
              No{" "}
            </Button>
            <Button className="mr-1" id="closeDialogBtn" onClick={closeDialog}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </div>

        {/* To show when data is saved and when clicked on close button exit */}
        <div id="SavedandExitdialogBox" className="hidden">
          <h5> Nautilus </h5>
          <hr></hr>
          <div className="dialog-content">
            <p> Saved Successfully. </p>
            <Button
              className="mr-1"
              id="closeDialogBtn"
              onClick={closeandExitSavedDialog}
            >
              {" "}
              Close{" "}
            </Button>
          </div>
        </div>

        {/* To show when data is saved, when clicked on saved button */}
        <div id="SaveddialogBox" className="hidden">
          <h5> Nautilus </h5>
          <hr></hr>
          <div className="dialog-content">
            <p> Saved Successfully. </p>
            <Button
              className="mr-1"
              id="closeDialogBtn"
              onClick={closeSavedDialog}
            >
              {" "}
              Close{" "}
            </Button>
          </div>
        </div>

        <div
          /*className="study-container"*/ id="Container"
          className="container-fluid"
        >
          <div className="d-flex justify-content-start">
            <div style={{ width: "100%" }} className="d-flex">
              {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}
              <TabComponent heightAdjustMode="Auto" id="defaultTab">
                {ShowTabs ? (
                  <TabItemsDirective>
                    <TabItemDirective
                      header={headertext[0]}
                      content={content0}
                    />
                    <TabItemDirective
                      header={headertext[2]}
                      content={content2}
                    />
                    <TabItemDirective
                      header={headertext[1]}
                      content={content1}
                    />
                  </TabItemsDirective>
                ) : (
                  <TabItemsDirective>
                    <TabItemDirective
                      header={headertext[1]}
                      content={content1}
                    />
                  </TabItemsDirective>
                )}
              </TabComponent>
            </div>

            <div>
              <button
                className="btn btn-secondary btn-air-secondary"
                type="button"
                onClick={SavedModal}
              >
                {" "}
                Save{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(OptionSetting);
