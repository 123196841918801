// Tonnage
export function UStons_To_Metrictons(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.907185;
  return Decimals
    ? parseFloat(CalcValue).toFixed(Decimals)
    : parseFloat(CalcValue);
}

export function Metrictons_To_UStons(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 1.10231;
  return Decimals
    ? parseFloat(CalcValue).toFixed(Decimals)
    : parseFloat(CalcValue);
}

export function UStons_To_kN(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 8.89644;
  return Decimals
    ? parseFloat(CalcValue).toFixed(Decimals)
    : parseFloat(CalcValue);
}

export function kN_To_UStons(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.1019716213;
  return Decimals
    ? parseFloat(CalcValue).toFixed(Decimals)
    : parseFloat(CalcValue);
}

export function Metrictons_To_kN(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 9.80665;
  return Decimals
    ? parseFloat(CalcValue).toFixed(Decimals)
    : parseFloat(CalcValue);
}

export function kN_To_Metrictons(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.101972;
  return Decimals
    ? parseFloat(CalcValue).toFixed(Decimals)
    : parseFloat(CalcValue);
}
