import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DataService from "../../../services/ApiService";

import {
  Category,
  ChartComponent,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { Button } from "react-scroll";

const MainEffectChart = (mold) => {
  const [data, setData] = useState({
    message: null,
    plus_values: [],
    minus_values: [],
    variable_limits: null,
  });

  const [fetchVal, setFetchVal] = useState(true);
  const { sessionId } = useParams();

  const [noOfDimensions, setnoOfDimensions] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState("");

  const [showPlot, setShowPlot] = useState(false);

  const fetchChartData = async () => {
    const decry = atob(sessionId);
    DataService.MainEffectPlot(decry).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    const decry = atob(sessionId);
    // console.log(decry)

    //Dropdown=>cavity sets data
    DataService.GetDefineResponse(decry, mold.mold)
      .then((res) => {
        const dimensionJSON = res.data["dimension"];

        const dataArray = Array.isArray(dimensionJSON)
          ? dimensionJSON
          : [dimensionJSON];

        setnoOfDimensions(dataArray);
      })
      .then(fetchChartData())
      .catch((err) => {
        // console.error("Error sending data to Django:", err);
      });

    if (fetchVal) {
      setFetchVal(false);
      // setShowPlot(true);
    }
  }, [fetchVal, sessionId]);

  const findInteractions = (plusValues, minusValues) => {
    const interactions = {};

    for (const plusEntry of plusValues) {
      const plusKey = plusEntry[0];

      //replace "_plus", "_minus" from pluskey

      const minusKey = plusKey.replace("_plus", "_minus");

      const correspondingEntry = minusValues.find(
        (entry) => entry[0] === minusKey
      );

      if (correspondingEntry && plusKey.includes("*")) {
        const key = plusKey.split("_plus")[0]; // Extracting the variable names

        const value = [plusEntry[1], correspondingEntry[1]]; // Pairing the values

        interactions[key] = value;
      }
    }
    return interactions;
  };

  const interactions = findInteractions(data.plus_values, data.minus_values);
  // console.log("interactions", interactions);

  const generateInteractionData = (interactions) => {
    const interactionData = {};

    // Iterate through each interaction
    for (const [interaction, values] of Object.entries(interactions)) {
      const variablePoints = [
        { x: 1, y: values[1] },
        { x: 2, y: values[0] },
      ];

      // Store data points array in the variableData object
      interactionData[interaction] = variablePoints;
    }

    return interactionData;
  };

  const interactionData = generateInteractionData(interactions);
  // console.log("Interaction Data:", interactionData);

  const variableData = {};

  for (const variable in data.variable_limits) {
    // Get the high and low limits for the current variable
    const [highLimit, lowLimit] = data.variable_limits[variable];

    // Find the plus value entry for the current variable
    const plusEntry = data.plus_values.find(
      (entry) => entry[0] === `${variable}_plus`
    );

    // If plus value is found, extract the value, otherwise set it to 0
    const plusValue = plusEntry ? plusEntry[1] : 0;

    // Find the minus value entry for the current variable
    const minusEntry = data.minus_values.find(
      (entry) => entry[0] === `${variable}_minus`
    );

    // If minus value is found, extract the value, otherwise set it to 0
    const minusValue = minusEntry ? minusEntry[1] : 0;

    // Create data points array for the current variable
    const variablePoints = [
      { x: highLimit, y: plusValue },
      { x: lowLimit, y: minusValue },
    ];

    // Store data points array in the variableData object
    variableData[variable] = variablePoints;
  }

  const calculatePrimaryXAxisLimits = (variableLimits) => {
    // console.log("variableLimitsvariableLimitsvariableLimits",variableLimits)
    const primaryXAxisLimits = {};

    for (const variable in variableLimits) {
      const [high, low] = variableLimits[variable];

      const highLimit = parseInt(high) + 0.1 * parseInt(high); // Calculate high limit with 10% increase

      const lowLimit = parseInt(low) - 0.1 * parseInt(low); // Calculate low limit with 10% decrease

      primaryXAxisLimits[variable] = { highLimit, lowLimit };
      // console.log("xlimits:", primaryXAxisLimits[variable]);
    }
    return primaryXAxisLimits;
  };

  // Calculate primary X-axis limits
  const primaryXAxisLimits = calculatePrimaryXAxisLimits(data.variable_limits);
  // console.log("primary x axis", primaryXAxisLimits);

  const primaryyAxis = {
    majorGridLines: { width: 0 },
    lineStyle: { color: "black" },
  };

  const renderMainFactorCharts = (variableData, primaryXAxisLimits) => {
    return (
      <div className="col-md-12 b-primary">
        <div className="text-center m-2 mt-3">
          <h6>
            <b>Main Factors</b>
          </h6>
        </div>

        <div className="d-flex">
          {Object.entries(variableData).map(([variable, data]) => (
            <div key={variable} style={{ marginRight: "20px" }}>
              <h6>{variable.replace(/_/g," ")} Chart</h6>

              <ChartComponent
                tooltip={{ enable: true }}
                border={{ width: 1, color: "darkblue" }}
                primaryYAxis={primaryyAxis}
                primaryXAxis={{
                  minimum: primaryXAxisLimits[variable].lowLimit,
                  maximum: primaryXAxisLimits[variable].highLimit,
                  lineStyle: { color: "black" },
                }}
                height="280vh"
                width="350wh"
              >
                <Inject services={[Legend, Category, Tooltip, LineSeries]} />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={data}
                    xName="x"
                    yName="y"
                    type="Line"
                    marker={{ visible: true }}
                    width={2}
                    fill="black"
                    animation={{enable:false}}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderInteractionCharts = (interactionData, primaryXAxisLimits) => {
    return (
      <div className="col-md-12 b-primary mt-2">
        <div className="text-center m-2 mt-3">
          <h6>
            {" "}
            <b> Interaction Factors </b>{" "}
          </h6>
        </div>

        <div className="d-flex">
          {Object.entries(interactionData).map(([variable, data]) => (
            <div className="m-1" key={variable}>
              <h6>{variable.replace(/_/g," ")} Chart</h6>

              <ChartComponent
                tooltip={{ enable: true }}
                border={{ width: 1, color: "darkblue" }}
                primaryYAxis={primaryyAxis}
                primaryXAxis={{
                  minimum: 0,
                  maximum: 3,
                  lineStyle: { color: "black" },
                }}
                height="280vh"
                width="355wh"
              >
                <Inject services={[Legend, Category, Tooltip, LineSeries]} />

                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={data}
                    xName="x"
                    yName="y"
                    type="Line"
                    marker={{ visible: true }}
                    width={2}
                    fill="black"
                    animation={{enable:false}}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          ))}
        </div>
      </div>
    );
  };



  const handleCompute = () => {
    return new Promise(async (resolve, reject) => {
      const selectedDimension = noOfDimensions[selectedIndex];

      try {
        const decry = atob(sessionId);
        DataService.SelectDropdown(decry, {
          selected: selectedDimension,
        })
          .then((res) => {})
          .catch((err) => {
            // console.error("Error sending data to Django:", err);
          });

        resolve();
      } catch (error) {
        // console.error("Error sending data to selected_response API:", error);
        reject(error);
      }
    });
  };

  
  const handleIndexChange = (event) => {

    const selectedValue = event.target.value;

    if (selectedValue === "") {
      // Handle the default placeholder case if needed
      setSelectedIndex(null);
      setShowPlot(false);
      return;
    }

    setSelectedIndex(parseInt(event.target.value, 10));
    setFetchVal(true);
    setShowPlot(false)
  };

  const handleClick =() =>{
    if (selectedIndex !== null) {
      handleCompute();
      setShowPlot(true);
    } else {
      alert("Please select a dimension before plotting the chart.");
    }
  }
  

  return (
    <>
      <div className="b-primary b-r-4 p-2">

        <div className="d-flex">

        <div className="col-md-2">
          <select className="mb-2 mt-2" value={selectedIndex} onChange={handleIndexChange}>
          <option value="" disabled>
                Select a dimension
              </option>
            {noOfDimensions.map((dimension, index) => (
              <option key={index} value={index}>
                {dimension.charAt(0).toUpperCase() + dimension.slice(1)}
              </option>
            ))}
          </select>
          </div>
          <div className="col-md-1">
          <Button onClick={handleClick} className="ml-1 mb-2 mt-2 btn btn-secondary btn-air-secondary">Plot Chart</Button>
        </div>

        </div>

        {showPlot ? ( // Conditionally render the ContourPlot component
          <div>
            <div>
              {renderMainFactorCharts(variableData, primaryXAxisLimits)}
            </div>

            <div>
              {renderInteractionCharts(interactionData, primaryXAxisLimits)}
            </div>
          </div>
        ) : (
          " "
        )}
      </div>
    </>
  );
};

export default MainEffectChart;
