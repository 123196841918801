import React from "react";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  // ColumnsDirective,
  // ColumnDirective,
} from "@syncfusion/ej2-react-spreadsheet";
// import { getCellIndexes, getCellAddress } from '@syncfusion/ej2-spreadsheet';

const ViscocityGrid = ({
  ViscosityGridData,
  rowCount,
  ViscositySpreadsheet,
  PopulateViscositySheet,
  showAlert,
  getData
}) => {
  const protectSettings = { selectCells: true };

  const scrollSettings = {
    isFinite: true,
    enableVirtualization: false,
  };

  let isPaste = false;

  const dialogBeforeOpen = (args) => {
    if (args.dialogName === "EditAlertDialog") {
      args.cancel = true;
    }

    // Edit the dialog content using the dialogBeforeOpen event.
    if (args.dialogName === "ValidationErrorDialog") {
      args.cancel = true;
    }
  }

  function onCreated() {
    //Applies data validation to specified range in the active sheet.
    ViscositySpreadsheet.current.addDataValidation(
      {
        type: "Decimal",
        operator: "Between",
        value1: "-10000.0000",
        value2: "10000.0000",
      },
      `A1:C${ViscositySpreadsheet.current.getActiveSheet().rowCount || 10}`
    );

    ViscositySpreadsheet.current.lockCells(
      `A1:C${ViscositySpreadsheet.current.getActiveSheet().rowCount || 10}`,
      false
    );

    PopulateViscositySheet(ViscosityGridData);
  }

  const UnlockCells = () => {
    ViscositySpreadsheet.current.getActiveSheet().rowCount =
      rowCount > 0 ? rowCount : 10;
  };

  function beforeCellUpdate(args) {
    // Skip the cell update for paste action that contains characters, If you need you can skip this for all type of actions performed in the spreadsheet.
    if (isPaste) {
      let pastedValue = args.cell.value;

      // Match alphabets and special characters
      var regex = /[a-zA-Z]/g;

      if (pastedValue && pastedValue.toString().match(regex)) {
        args.cancel = true;
      }

      isPaste = false;
    }
  }

  function actionBegin(args) {
    if (args.args.eventArgs && args.args.eventArgs.requestType === "paste") {
      isPaste = true;

      showAlert.current = true;
    }

    if (args.action === 'clipboard' && args.args.eventArgs.requestType === 'paste') {
      //Set the type to 'Values' to paste only the values.
      args.args.eventArgs.type = 'Values';

      showAlert.current = true;
    }

  }

  const cellEditing = (args) => {
    if (args.value !== args.oldValue && !isNaN(args.value)) {
      ViscositySpreadsheet.current.updateCell(
        { value: args.value },
        args.address
      );

      showAlert.current = true;

      getData();

      // console.log(args)
    } else {
      showAlert.current = false;
    }
  };

  const beforeCellRenderHandler = (args) => {
    // Condition to check whether the rendered element is header cell.
    if (
      args.colIndex === 0 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Injection Speed";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 1 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Fill Time";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 2 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Peak Inj Press";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 3 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Viscosity";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 4 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Shear Rate";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 5 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Absolute Drop Viscosity";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 6 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "%Drop Viscosity";
      args.element.innerText = text;
    }
  };

  return (
    <div className="viscosity-grid" onMouseEnter={UnlockCells}>
      <div className="spreadsheet ml-2" id="Viscosity_Sheet">
        <SpreadsheetComponent
          onBlur={getData}
          cellEdited={getData}
          cellEditing={cellEditing}
          ref={ViscositySpreadsheet}
          height={400}
          width={"100%"}
          showFormulaBar={false}
          showSheetTabs={false}
          showRibbon={false}
          dialogBeforeOpen={dialogBeforeOpen.bind(this)}
          scrollSettings={scrollSettings}
          allowAutoFill={false}
          created={onCreated}
          beforeCellRender={beforeCellRenderHandler}
          beforeCellUpdate={beforeCellUpdate.bind(this)}
          actionBegin={actionBegin.bind(this)}
          enableContextMenu={false}
          allowImage={false}
        >
          <SheetsDirective>
            <SheetDirective
              rowCount={rowCount}
              colCount={7}
              isProtected={true}
              protectSettings={protectSettings}
            >
              {/* <ColumnsDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
              </ColumnsDirective> */}
            </SheetDirective>
          </SheetsDirective>
        </SpreadsheetComponent>
      </div>
    </div>
  );
};

export default ViscocityGrid;
