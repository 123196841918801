import React, { useState, Fragment, useEffect } from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import '../assets/custom-stylesheet/header_style.css';
import Loader from './common/loader';
import { checkAuthenticated, get_machines, get_materials, get_user_units, get_molds, load_user, get_units } from '../actions/auth';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { logoutPrevSession } from '../actions/auth';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const App = ({ user, checkAuthenticated, load_user, get_units, get_user_units, get_materials, get_machines, get_molds, ...props }) => {

    // To remove the unwanted occured warnings and bugs in browser console
    // console.log = console.warn = console.error = () => { }

    let token = sessionStorage.getItem("access");

    const [SessionExpired, setSessionExpired] = useState(false);

    const ToggleSessionAlert = () => {

        setSessionExpired(!SessionExpired);

    }

    const BackToLogIn = () => {
        window.location = '/login'
    }

    function parseJwt(token) {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    }

    const getRemainingTime = (exp) => {
        // Get the current time in seconds (UNIX timestamp)
        const currentTime = Math.floor(Date.now() / 1000);

        // Calculate the remaining time by subtracting the current time from the expiration time
        const remainingTime = exp - currentTime;

        // console.log('CT', currentTime, 'EXP', exp, 'RT', remainingTime)

        // Return the remaining time in seconds
        return remainingTime > 0 ? remainingTime : 0; // If expired, return 0
    };

    useEffect(() => {

        if (token) {

            let data = parseJwt(token)

            getRemainingTime(data.exp)

            setTimeout(async () => {

                let isAuthenticated = await checkAuthenticated(getRemainingTime(data.exp));

                if (isAuthenticated === false) {
                    ToggleSessionAlert(!SessionExpired);
                }

            }, getRemainingTime(data.exp) * 1000)

        }

        load_user();

        // eslint-disable-next-line
    }, [checkAuthenticated, load_user]); // Only run when these specific functions change

    let UserEmail = sessionStorage.getItem("UserEmail");

    useEffect(() => {

        const logoutPrevSession = () => {

            const body = { "email": UserEmail }

            axios.post(
                `${process.env.REACT_APP_API_URL}/user/logout/`,
                body
            ).then((res) => {
                // console.log(res);
            })
        }

        // Add event listeners
        window.addEventListener('beforeunload', logoutPrevSession);
        window.addEventListener('unload', logoutPrevSession);

        return () => {
            // Cleanup the event listeners on component unmount
            window.removeEventListener('beforeunload', logoutPrevSession);
            window.removeEventListener('unload', logoutPrevSession);
        };

    }, [])

    const [isIdle, setIsIdle] = useState(false);

    const ONE_HOUR = 60 * 60 * 1000; // 1 hour in milliseconds

    const onIdle = () => {

        ToggleSessionAlert(!SessionExpired);

    };

    useEffect(() => {
        let idleTimer = null;

        // Reset idle timer whenever user activity is detected
        const resetIdleTimer = () => {
            setIsIdle(false);
            clearTimeout(idleTimer);
            idleTimer = setTimeout(() => {
                setIsIdle(true);
                if (onIdle) onIdle();  // Call the onIdle callback when user goes idle
            },
                ONE_HOUR
                // 5000
            );
        };

        // Add event listeners for user activity
        window.addEventListener('mousemove', resetIdleTimer);
        window.addEventListener('keydown', resetIdleTimer);
        window.addEventListener('scroll', resetIdleTimer);
        window.addEventListener('click', resetIdleTimer);

        // Initialize the idle timer when component mounts
        resetIdleTimer();

        // Cleanup event listeners and timeout on component unmount
        return () => {
            clearTimeout(idleTimer);
            window.removeEventListener('mousemove', resetIdleTimer);
            window.removeEventListener('keydown', resetIdleTimer);
            window.removeEventListener('scroll', resetIdleTimer);
            window.removeEventListener('click', resetIdleTimer);
        };
    }, [ONE_HOUR, onIdle]);

    useEffect(() => {

        if (user) {
            get_units()
            get_user_units(user.id);
            get_materials(user.id);
            get_machines(user.id);
            get_molds(user.id);
        }

        // eslint-disable-next-line
    }, [user])

    return (
        <Fragment>
            <Modal isOpen={SessionExpired} centered={true}>
                <ModalHeader>
                    Nautilus Web
                </ModalHeader>

                <ModalBody>
                    <span> Since Your session has expired, You were logged out of your account. Please press <strong> Log Out </strong> to log in to the Nautilus Web Dashboard again. </span>
                </ModalBody>

                <ModalFooter>
                    <Button color="dark" onClick={BackToLogIn}>
                        Log Out
                    </Button>
                </ModalFooter>
            </Modal>
            <Loader />
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Header />
                    <Sidebar />
                    <div className="page-body">
                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, { checkAuthenticated, load_user, get_units, get_user_units, get_materials, get_machines, get_molds, logoutPrevSession })(App);
