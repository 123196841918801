import React, { useEffect } from "react";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-spreadsheet";
import "../../../assets/custom-stylesheet/grid_stylecss.css";
const ScrewMainGrid = ({
  rowCount,
  ScrewRotationGridData,
  getData,
  ScrewRotationSpreadsheet,
  PopulateScrewSheet,
  setRowCount,
}) => {
  const protectSettings = { selectCells: true };

  const scrollSettings = {
    isFinite: true,
    enableVirtualization: false,
  };

  let isPaste = false;

  const dialogBeforeOpen = (args) => {
    if (args.dialogName === "EditAlertDialog") {
      args.cancel = true;
      // args.content = 'This cell is read only';
    }

    // Edit the dialog content using the dialogBeforeOpen event.
    if (args.dialogName === "ValidationErrorDialog") {
      args.cancel = true;
    }
  };

  function onCreated() {
    //Applies data validation to specified range in the active sheet.
    ScrewRotationSpreadsheet.current.addDataValidation(
      {
        type: "Decimal",
        operator: "Between",
        value1: "-1000.0",
        value2: "1000.0",
      },
      `A1:C${ScrewRotationSpreadsheet.current.getActiveSheet().rowCount}`
    );

    ScrewRotationSpreadsheet.current.lockCells(
      `A1:B${ScrewRotationSpreadsheet.current.getActiveSheet().rowCount}`,
      false
    );

    PopulateScrewSheet(ScrewRotationGridData);
  }

  function UnlockCells() {
    ScrewRotationSpreadsheet.current.lockCells(
      `A1:B${ScrewRotationSpreadsheet.current.getActiveSheet().rowCount}`,
      false
    );

    PopulateScrewSheet(ScrewRotationGridData);
  }

  function beforeCellUpdate(args) {
    // Skip the cell update for paste action that contains characters, If you need you can skip this for all type of actions performed in the spreadsheet.
    if (isPaste) {
      let pastedValue = args.cell.value;

      // Match alphabets and special characters
      var regex = /[a-zA-Z]/g;

      if (pastedValue && pastedValue.toString().match(regex)) {
        args.cancel = true;
      }

      isPaste = false;
    }
  }

  function actionBegin(args) {
    if (args.args.eventArgs && args.args.eventArgs.requestType === "paste") {
      isPaste = true;
    }

    if (
      args.action === "clipboard" &&
      args.args.eventArgs.requestType === "paste"
    ) {
      //Set the type to 'Values' to paste only the values.
      args.args.eventArgs.type = "Values";
    }
  }

  const cellEditing = (args) => {
    if (args.value !== args.oldValue && !isNaN(args.value)) {
      ScrewRotationSpreadsheet.current.updateCell(
        { value: args.value },
        args.address
      );

      getData();
    }
  };

  const beforeCellRenderHandler = (args) => {
    // PopulateScrewSheet(ScrewRotationGridData);

    // Condition to check whether the rendered element is header cell.
    if (
      args.colIndex === 0 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Screw Rotation Speed";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 1 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Actual Melt Temp";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 2 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Target";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 3 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Delta Temp";
      args.element.innerText = text;
    }
  };

  useEffect(() => {
    PopulateScrewSheet(ScrewRotationGridData);

    setTimeout(() => {
      setRowCount(ScrewRotationGridData.length > 0 ? ScrewRotationGridData.length : 5);
    }, 1000);

    // eslint-disable-next-line
  }, [ScrewRotationGridData]);

  return (
    <>
      <div
        className="spreadsheet"
        id="Screw_Sheet"
        onMouseEnter={UnlockCells}
        onMouseLeave={getData}
      >
        <SpreadsheetComponent
          onClick={getData}
          cellEdited={getData}
          cellEditing={cellEditing}
          ref={ScrewRotationSpreadsheet}
          height={350}
          width={"100%"}
          showFormulaBar={false}
          showSheetTabs={false}
          showRibbon={false}
          dialogBeforeOpen={dialogBeforeOpen.bind(this)}
          scrollSettings={scrollSettings}
          allowAutoFill={false}
          created={onCreated}
          beforeCellRender={beforeCellRenderHandler}
          beforeCellUpdate={beforeCellUpdate.bind(this)}
          actionBegin={actionBegin.bind(this)}
          enableContextMenu={false}
          allowImage={false}
          className="screwMainGrid"
        >
          <SheetsDirective>
            <SheetDirective
              rowCount={rowCount}
              colCount={4}
              isProtected={true}
              protectSettings={protectSettings}
            >
              <ColumnsDirective>
                <ColumnDirective width={120}></ColumnDirective>
                <ColumnDirective width={120}></ColumnDirective>
                <ColumnDirective width={120}></ColumnDirective>
                <ColumnDirective width={120}></ColumnDirective>
              </ColumnsDirective>
            </SheetDirective>
          </SheetsDirective>
        </SpreadsheetComponent>
      </div>
    </>
  );
};

export default ScrewMainGrid;
