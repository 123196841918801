import React, { useState, useEffect } from 'react';
import XBarChart from './XBarChart';
import MRBarChart from './MRBarChart';
import Loader from '../../../assets/Loader'
import DropDownMenu from './DropDownMenu';

const D2 = [1.128, 1.128, 1.693, 2.059, 2.326, 2.534, 2.704, 2.847, 2.97, 3.078, 3.173, 3.258, 3.336, 3.407, 3.472];

function calculateAverage(values, subGroupSize) {

    let total = 0;
    let counter = 0;

    for (let i = 0; i < values.length; i += subGroupSize) {
        if (i + subGroupSize > values.length) {
            break;
        }

        for (let j = i; j < i + subGroupSize; j++) {
            total += parseFloat(values[j]);
            counter++;
        }
    }

    return total / counter;

}

function calculateRange(values, subGroupSize) {

    let rangeList = [];

    if (subGroupSize === 1) {

        for (let i = 1; i < values.length; i++) {
            rangeList.push(Math.abs(parseFloat(values[i]) - parseFloat(values[i - 1])));
        }

    } else {

        for (let i = 0; i < values.length; i += subGroupSize) {

            if (i + subGroupSize > values.length) {
                break;
            } else {
                let maxVal = parseFloat(values[i]);
                let minVal = parseFloat(values[i]);

                for (let j = i; j < i + subGroupSize; j++) {
                    if (maxVal < parseFloat(values[j])) {
                        maxVal = parseFloat(values[j]);
                    }

                    if (minVal > parseFloat(values[j])) {
                        minVal = parseFloat(values[j]);
                    }
                }

                rangeList.push(maxVal - minVal);
            }
        }
    }

    return rangeList;
}

function calculatePotentialSD(values, subGroupSize) {

    const range = calculateRange(values, subGroupSize);
    const average = calculateAverage(range, 1);
    return average / D2[subGroupSize - 1];

}

function calculateRBar(R, NoOfPoints) {

    let RBar = 0;

    let Rsum = 0;

    for (let i = 0; i < R.length; i++) {
        Rsum += parseFloat(R[i]);
    }

    if (NoOfPoints > 0) {
        RBar = Rsum / NoOfPoints;
    }

    return RBar;
}

function UCLForMR_OfXBarMR(MRBar) {

    let UCL = 0;

    UCL = 3.267 * MRBar;

    return UCL;

}

const ControlChart = ({ PCSpreadsheet, SelectedColData, HeaderArray, SelectedColHeader, PCSheetRowCount, setSelectedColHeader, setSelectedColData, CalculateBin, setBin_Interval, CreateChartData, setChartData, setComputeClicked, PerformCalcs }) => {

    const [upperLimitXBar, setUpperLimitXBar] = useState('');
    const [targetXBar, setTargetXBar] = useState('');
    const [lowerLimitXBar, setLowerLimitXBar] = useState('');

    const [upperLimitMRBar, setUpperLimitMRBar] = useState('');
    const [targetMRBar, setTargetMRBar] = useState('');

    const [subgroupSize, setSubgroupSize] = useState('');
    const [xBarValues, setXBarValues] = useState([]);
    const [rBarValues, setRBarValues] = useState([]);

    const [minValue, setMinValue] = useState(0); // Initial minimum value
    const [maxValue, setMaxValue] = useState(24); // Initial maximum value

    const handleDecreaseRange = () => {

        // Decrease the minimum and maximum values by 1
        if (minValue <= 0) { }
        else {
            setMinValue(minValue - 1);
            setMaxValue(maxValue - 1);
        }
    };


    const handleIncreaseRange = () => {

        // Increase the minimum and maximum values by 1
        if (maxValue > xBarValues.length) { }
        else {
            setMinValue(minValue + 1);
            setMaxValue(maxValue + 1);
        }
    };


    const decreaseSheetRange = () => {
        if (minValue <= 0) { }
        else {
            const newMin = Math.max(minValue - 25, 0);
            const newMax = Math.min(newMin + 25, xBarValues.length);

            setMinValue(newMin);
            setMaxValue(newMax);
        }
    }


    const increaseSheetRange = () => {

        if (maxValue <= xBarValues.length) {
            const newMax = Math.min(maxValue + 24, xBarValues.length);
            const newMin = Math.max(newMax - 24, 0);

            setMinValue(newMin);
            setMaxValue(newMax);
        }

    }

    useEffect(() => {

        // Calculate RBar when rBarValues change
        let RBar = calculateRBar(rBarValues, rBarValues.length - 1);

        //console.log('RBar: ', RBar);
        let UCLDisp = UCLForMR_OfXBarMR(RBar);

        // console.log(UCLDisp)

        setUpperLimitMRBar(UCLDisp);

        setTargetMRBar(RBar);

    }, [rBarValues, SelectedColData]); // This useEffect will trigger whenever rBarValues change

    useEffect(() => {

        const handleCompute = () => {

            if (PCSpreadsheet) {

                setSubgroupSize(SelectedColData.sub_group_size);

                // Convert subgroupSize to integer
                const subgroupSizeInt = parseInt(SelectedColData.sub_group_size);

                // console.log(subgroupSizeInt);

                const mean = calculateAverage(SelectedColData.values, subgroupSizeInt);

                const sigma3 = 3;

                const stdDeviation = calculatePotentialSD(SelectedColData.values, subgroupSizeInt);

                const usl = mean + sigma3 * stdDeviation;

                const lsl = mean - sigma3 * stdDeviation;

                // console.log(usl, lsl)

                setLowerLimitXBar(lsl);

                setUpperLimitXBar(usl);

                setTargetXBar(mean);

                const xBarData = [];
                const rBarData = [];
                const mrBarData = [];

                for (let i = 0; i < SelectedColData.values.length; i += subgroupSizeInt) {

                    const subgroup = SelectedColData.values.slice(i, i + subgroupSizeInt);

                    // Skip subgroups with less than the required number of elements
                    if (subgroup.length !== subgroupSizeInt) {
                        continue;
                    }

                    // Calculate average
                    const average = subgroup.reduce((sum, value) => sum + value, 0) / subgroupSizeInt;

                    xBarData.push(average);

                    if (subgroupSize !== 1) {

                        const max = Math.max(...subgroup);
                        const min = Math.min(...subgroup);
                        const difference = max - min;

                        rBarData.push(difference);

                    }

                }

                setXBarValues(xBarData)

                // console.log(xBarData)

                if (subgroupSize === 1) {

                    for (let i = 1; i < xBarData.length; i++) {

                        const difference = Math.abs(xBarData[i] - xBarData[i - 1]);

                        mrBarData.push(difference)

                    }

                    setRBarValues(mrBarData)

                }

                else {

                    setRBarValues(rBarData)

                }

            }

            // console.log(SelectedColData)

        };

        handleCompute()

        // eslint-disable-next-line
    }, [SelectedColData])

    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className='m-1'>

            {isLoading ? (
                <Loader />
            ) : (<></>)}

            <div className='card p-3 ml-2 mt-2'>

                <div className='b-primary b-r-4 mb-2'>

                    <DropDownMenu
                        id={"ControlChartDropDown"}
                        HeaderArray={HeaderArray} SelectedColHeader={SelectedColHeader} PCSheetRowCount={PCSheetRowCount} setIsLoading={setIsLoading} PCSpreadsheet={PCSpreadsheet} setSelectedColData={setSelectedColData} CalculateBin={CalculateBin} CreateChartData={CreateChartData} setBin_Interval={setBin_Interval} setChartData={setChartData} setComputeClicked={setComputeClicked} setSelectedColHeader={setSelectedColHeader} PerformCalcs={PerformCalcs} />
                </div>

                <div className='b-primary b-r-4 mb-2 mt-2'>

                    <div className='p-2'>
                        <XBarChart
                            chartData={xBarValues.map((y, index) => ({ x: index + 1, y }))} // x-values are indices + 1
                            upperLimit={parseFloat(upperLimitXBar)}
                            lowerLimit={parseFloat(lowerLimitXBar)}
                            average={parseFloat(targetXBar)}
                            minValue={minValue}
                            maxValue={maxValue}
                        />
                    </div>

                    <div className='p-2'>
                        <MRBarChart
                            chartData={rBarValues.map((y, index) => ({ x: subgroupSize === 1 ? index + 2 : index + 1, y }))}
                            upperLimit={upperLimitMRBar}
                            lowerLimit={0}
                            average={targetMRBar}
                            subGroupSize={subgroupSize}
                            minValue={minValue}
                            maxValue={maxValue}
                        />
                    </div>

                    <div className='btn-container text-center mb-1'>

                        <div>
                            <button className="btn btn-pill btn-primary btn-air-primary mt-1 mr-2" onClick={decreaseSheetRange}> {"<<"} </button>
                            <button className="btn btn-pill btn-primary btn-air-primary mt-1 mr-2" onClick={handleDecreaseRange}> {"<"} </button>
                            <button className="btn btn-pill btn-primary btn-air-primary mt-1 mr-2" onClick={handleIncreaseRange}> {">"} </button>
                            <button className="btn btn-pill btn-primary btn-air-primary mt-1 mr-2" onClick={increaseSheetRange}> {">>"} </button>
                        </div>

                    </div>

                </div>

            </div>

            {/* <div className='card ml-3' style={{ border: '1px solid #573DAC' }}>

                <div className='p-2'>
                    <XBarChart
                        chartData={xBarValues.map((y, index) => ({ x: index + 1, y }))} // x-values are indices + 1
                        upperLimit={parseFloat(upperLimitXBar)}
                        lowerLimit={parseFloat(lowerLimitXBar)}
                        average={parseFloat(targetXBar)}
                        minValue={minValue}
                        maxValue={maxValue}
                    />
                </div>

                <div className='p-2'>
                    <MRBarChart
                        chartData={rBarValues.map((y, index) => ({ x: subgroupSize === 1 ? index + 2 : index + 1, y }))}
                        upperLimit={upperLimitMRBar}
                        lowerLimit={0}
                        average={targetMRBar}
                        subGroupSize={subgroupSize}
                        minValue={minValue}
                        maxValue={maxValue}
                    />
                </div>

                <div className='btn-container text-center mb-1'>

                    <div>
                        <button className="btn btn-pill btn-primary btn-air-primary mt-1 mr-2" onClick={decreaseSheetRange}> {"<<"} </button>
                        <button className="btn btn-pill btn-primary btn-air-primary mt-1 mr-2" onClick={handleDecreaseRange}> {"<"} </button>
                        <button className="btn btn-pill btn-primary btn-air-primary mt-1 mr-2" onClick={handleIncreaseRange}> {">"} </button>
                        <button className="btn btn-pill btn-primary btn-air-primary mt-1 mr-2" onClick={increaseSheetRange}> {">>"} </button>
                    </div>

                </div>

            </div> */}

        </div>
    )
}

export default ControlChart;