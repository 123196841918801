import React, { useState, useRef, useEffect } from "react";
import Header2 from "../common/header-component/header2";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import ProcessCapability from "./ProcessCapability/ProcessCapability";

import DataService from "../../services/ApiService";

import { Button } from "reactstrap";

const QualityDashboard = () => {
  const history = useHistory();

  const showAlert = useRef(false);

  const [MoldId, setMoldId] = useState();
  const [MoldName, setMoldName] = useState("mold");
  const [Session_Name, setSession_Name] = useState("");
  // Session Id getting from the URL
  const [Session_Id, setSession_Id] = useState();

  const GoToMolds = useRef(false);

  // Boolean variable to switch between the save and update button
  const ToSaveQualityData = useRef(true);
  const ToSaveAttributeData = useRef(true);
  const ToSavePostMoldData = useRef(true);

  const QualityData = useRef([]);
  const AttributeData = useRef([]);
  const PostMoldData = useRef([]);

  const saveData = () => {
    return new Promise((resolve, reject) => {
      if (ToSaveQualityData.current) {
        // console.log(QualityData.current)

        DataService.SaveCapabilityData(QualityData.current)
          .then((res) => {
            ToSaveQualityData.current = false;

            showAlert.current = false;
          })
          .catch((err) => {});
      } else {
        // console.log(QualityData.current)

        DataService.UpdateCapabilityData(Session_Id, QualityData.current)
          .then((res) => {
            ToSaveQualityData.current = false;

            showAlert.current = false;
          })
          .catch((err) => {});
      }

      if (ToSaveAttributeData.current) {
        // console.log(AttributeData.current)

        DataService.SaveAttributeData(AttributeData.current)
          .then((res) => {
            ToSaveAttributeData.current = false;

            showAlert.current = false;
          })
          .catch((err) => {});
      } else {
        // console.log(AttributeData.current)

        DataService.UpdateAttributeData(Session_Id, AttributeData.current)
          .then((res) => {
            ToSaveAttributeData.current = false;

            showAlert.current = false;
          })
          .catch((err) => {});
      }

      if (ToSavePostMoldData.current) {
        // console.log(AttributeData.current)

        DataService.SavePostMoldData(PostMoldData.current)
          .then((res) => {
            ToSavePostMoldData.current = false;

            showAlert.current = false;
          })
          .catch((err) => {});
      } else {
        // console.log(AttributeData.current)

        DataService.UpdatePostMoldData(Session_Id, PostMoldData.current)
          .then((res) => {
            ToSavePostMoldData.current = false;

            showAlert.current = false;
          })
          .catch((err) => {});
      }

      resolve();
    });
  };

  // This function will be called when the user clicks on the save button of the study
  const SavedModal = () => {
    saveData().then(() => {
      const saveddialogBox = document.getElementById("SaveddialogBox");
      saveddialogBox.classList.remove("hidden");
    });
  };

  // Instance of dialog box Asking for saving data before leaving study
  const dialogBox = document.getElementById("dialogBox");

  // Instance of dialog box which will be shown after saving
  const SavedandExitdialogBox = document.getElementById(
    "SavedandExitdialogBox"
  );

  // Event to get back to Session's
  const BackToMold = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
    } else {
      if (GoToMolds.current) {
        history.push("/dashboard");
      } else {
        history.push(`/mold`);
      }
    }
  };

  const BackToDashboard = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
      GoToMolds.current = true;
    } else {
      history.push("/dashboard");
    }
  };

  // Event to close the saving confirmation dialog
  const closeDialog = () => {
    const dialogBox = document.getElementById("dialogBox");

    dialogBox.classList.add("hidden");
  };

  // Event to finally close and exit study after saving
  const closeandExitSavedDialog = () => {
    const saveddialogBox = document.getElementById("SavedandExitdialogBox");

    saveddialogBox.classList.add("hidden");

    if (GoToMolds.current) {
      history.push("/dashboard");
    } else {
      history.push(`/mold`);
    }
  };

  // Event to close the dialog which will be shown after saving
  const closeSavedDialog = () => {
    const saveddialogBox = document.getElementById("SaveddialogBox");

    saveddialogBox.classList.add("hidden");
  };

  const SaveandExit = () => {
    saveData().then(() => {
      closeDialog();
      SavedandExitdialogBox.classList.remove("hidden");
    });
  };

  return (
    <>
      {/* ************************************************ Saving Modals *******************************************/}
      {/* To alert while going back to sessions page */}
      <div id="dialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Do you want to save Quality Data ? </p>
          <Button className="mr-1" id="closeDialogBtn" onClick={SaveandExit}>
            {" "}
            Yes{" "}
          </Button>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            No{" "}
          </Button>
          <Button className="mr-1" id="closeDialogBtn" onClick={closeDialog}>
            {" "}
            Cancel{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved and when clicked on close button exit */}
      <div id="SavedandExitdialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved, when clicked on saved button */}
      <div id="SaveddialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      <Header2 Title="Quality Data" />

      <div className="mt-2 container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">
            <div>
              <span
                className="BreadCrum"
                onClick={BackToDashboard}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span
                className="BreadCrum"
                onClick={BackToMold}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Mold{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "14px" }}>
                {" "}
                Quality Data{" "}
              </span>
            </div>
          </div>

          <div>
            <div className="ml-4">
              <span className="Mold-Session">
                {" "}
                Session Name : {Session_Name}{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="study-container">
          {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}

          <div>
            <ProcessCapability
              setSession_Id={setSession_Id}
              setSession_Name={setSession_Name}
              setMoldId={setMoldId}
              setMoldName={setMoldName}
              SavedModal={SavedModal}
              // ************
              showAlert={showAlert}
              ToSaveQualityData={ToSaveQualityData}
              QualityData={QualityData}
              // ************
              ToSaveAttributeData={ToSaveAttributeData}
              AttributeData={AttributeData}
              // *************
              ToSavePostMoldData={ToSavePostMoldData}
              PostMoldData={PostMoldData}
            />
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default QualityDashboard;
