import React from "react";
import "../../assets/custom-stylesheet/app2_style.css";
import "../../assets/custom-stylesheet/samplepage_style.css";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import ScienticMPdf from "./ScientificMolding.pdf";

// function loadPdf() {
//     //Get the PDF Viewer instance
//      var pdfviewer = document.getElementById('container').ej2_instances[0];
// // load PDF document in PDF Viewer
// pdfviewer.load(‘MoldQualification.pdf', null);
//  }

const ScientificMold = () => {
  return (
    <>
      <div id="container">
     
        <iframe
          src={ScienticMPdf}
          width="100%"
          height="600px"
          title="PDF Viewer"
        />
      
      </div>
    </>
  );
};
export default ScientificMold;
