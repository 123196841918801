import React, { useState, useEffect, useRef } from "react";
import "../App.css";

// Tab view component from syncfusion to navigate through six steps study
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

import { ToastContainer } from "react-toastify";
import Header2 from "../common/header-component/header2";
import {
  get_user_units,
  get_units,
  get_materials,
  get_machines,
  get_molds,
} from "../../actions/auth";
import { connect } from "react-redux";

// import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

// import { useReactToPrint } from "react-to-print";
import MaterialDatabase from "./MaterialDatabase/Materialdb";
import MoldDatabase from "./MoldDatabase/MoldDB";
import MachineDatabase from "./MachineDatabase/Machinedb";

import { useHistory } from "react-router-dom";

// Using a Function Component
const Database = ({
  user,
  get_user_units,
  get_units,
  get_materials,
  get_machines,
  get_molds,
  ...props
}) => {
  const tabInstance = useRef(null);

  let { TabIdx } = props.location;

  let TabName = ["Material Database", "Mold Database", "Machine Database"];

  const [ActiveTab, setActiveTab] = useState(TabName[0]);

  const history = useHistory();

  const BackToDashboard = () => {
    history.push("/dashboard");
  };

  // Declare the names of the tab's
  let headertext = [
    { text: "Material Database" },
    { text: "Mold Database" },
    { text: "Machine Database" },
  ];

  // These are the event's which will get called when clicked on the respective step's tab and once they are called they render the component of that respective tab which we have imported above
  function content0() {
    return (
      <div>
        <MaterialDatabase />
      </div>
    );
  }

  function content1() {
    return (
      <div>
        <MoldDatabase />
      </div>
    );
  }

  function content2() {
    return (
      <div>
        <MachineDatabase />
      </div>
    );
  }

  const tabSelected = (args) => {
    if (args.selectedIndex === 0) {
      setActiveTab(TabName[0]);
    } else if (args.selectedIndex === 1) {
      setActiveTab(TabName[1]);
    } else if (args.selectedIndex === 2) {
      setActiveTab(TabName[2]);
    }
  };

  useEffect(() => {
    if (TabIdx) {
      tabInstance.current.select(TabIdx);
      setActiveTab(TabName[TabIdx]);
    }

    get_units();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      get_user_units(user.id);

      get_materials(user.id);
      get_machines(user.id);
      get_molds(user.id);
    }

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    sessionStorage.removeItem("SelectedMoldData");
  }, []);

  return (
    <>
      <Header2 Title="Databases" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex ">
            <div>
              <span
                onClick={BackToDashboard}
                className="BreadCrum" 
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "14px" }}> {ActiveTab} </span>
            </div>
          </div>
        </div>

        <div
          /*className="study-container"*/ id="Container"
          className="container-fluid"
        >
          <div className="d-flex justify-content-start">
            <div style={{ width: "100%" }} className="d-flex">
              {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}
              <TabComponent
                ref={tabInstance}
                heightAdjustMode="Auto"
                id="defaultTab"
                selected={tabSelected}
              >
                <TabItemsDirective>
                  <TabItemDirective header={headertext[0]} content={content0} />
                  <TabItemDirective header={headertext[1]} content={content1} />
                  <TabItemDirective header={headertext[2]} content={content2} />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  get_units,
  get_user_units,
  get_materials,
  get_machines,
  get_molds,
})(Database);
