import React, { useState, useRef, useEffect } from "react";

// CSS
import "../App.css";
import Speed from "./Speed";
import Pressure from "./Pressure";
import Temperature from "./Temperature";
import Time from "./Time";
import Distance from "./Distance";
import Weight from "./Weight";
import Area from "./Area";

const ManagementUnit = ({ user, UnitsData, StoredUnits, showAlert }) => {

  const [SelectedUnitsData, setSelectedUnitsData] = useState(
    StoredUnits.Unit_Data[0]
  );

  // Object to store the selected speed units
  const SpeedUnits = useRef(UnitsData.current.Unit_Data[0].Speed);

  // Object to store the selected Pressure units
  const PressureUnits = useRef(UnitsData.current.Unit_Data[0].Pressure);

  // Object to store the selected Temperature units
  const TemperatureUnits = useRef(UnitsData.current.Unit_Data[0].Temperature);

  // Object to store the selected Time units
  const TimeUnits = useRef(UnitsData.current.Unit_Data[0].Time);

  // Object to store the selected Distance units
  const DistanceUnits = useRef(UnitsData.current.Unit_Data[0].Distance);

  // Object to store the selected Weight units
  const WeightUnits = useRef(UnitsData.current.Unit_Data[0].Weight);

  // Object to store the selected Area units
  const AreaUnits = useRef(UnitsData.current.Unit_Data[0].Area);

  const [DefaultMetricChecked, setDefaultMetricChecked] = useState(true);
  const [DefaultEnglishChecked, setDefaultEnglishChecked] = useState(false);
  const [DefaultCustomChecked, setDefaultCustomChecked] = useState(false);

  const ToggleMetric = () => {
    showAlert.current = true;

    setDefaultMetricChecked(true);
    setDefaultEnglishChecked(false);
    setDefaultCustomChecked(false);

    // Setting Units to Metric
    SpeedUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Speed[0].unit_id || null,
      unit_name: StoredUnits.Unit_Data[0].Speed[0].unit_name || null,
      decimals: StoredUnits.Unit_Data[0].Speed[0].decimals || null,
    };

    PressureUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Pressure[0].unit_id || null,
      unit_name: StoredUnits.Unit_Data[0].Pressure[0].unit_name || null,
      decimals: StoredUnits.Unit_Data[0].Pressure[0].decimals || null,
    };

    TemperatureUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Temperature[0].unit_id || null,
      unit_name: StoredUnits.Unit_Data[0].Temperature[0].unit_name || null,
      decimals: StoredUnits.Unit_Data[0].Temperature[0].decimals || 0,
    };

    TimeUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Time[0].unit_id || null,
      unit_name: StoredUnits.Unit_Data[0].Time[0].unit_name || null,
      decimals: StoredUnits.Unit_Data[0].Time[0].decimals || 0,
    };

    DistanceUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Distance[0].unit_id || null,
      unit_name: StoredUnits.Unit_Data[0].Distance[0].unit_name || null,
      decimals: StoredUnits.Unit_Data[0].Distance[0].decimals || null,
    };

    WeightUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Weight[0].unit_id || null,
      unit_name: StoredUnits.Unit_Data[0].Weight[0].unit_name || null,
      decimals: StoredUnits.Unit_Data[0].Weight[0].decimals || null,
    };

    AreaUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Area[0].unit_id || null,
      unit_name: StoredUnits.Unit_Data[0].Area[0].unit_name || null,
      decimals: StoredUnits.Unit_Data[0].Area[0].decimals || null,
    };

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  const ToggleEnglish = () => {
    showAlert.current = true;

    setDefaultEnglishChecked(true);
    setDefaultMetricChecked(false);
    setDefaultCustomChecked(false);

    // Setting Units to English
    SpeedUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Speed[1].unit_id
        ? StoredUnits.Unit_Data[0].Speed[1].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Speed[1].unit_name
        ? StoredUnits.Unit_Data[0].Speed[1].unit_name
        : null,
      decimals: StoredUnits.Unit_Data[0].Speed[1].decimals
        ? StoredUnits.Unit_Data[0].Speed[1].decimals
        : null,
    };

    PressureUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Pressure[1].unit_id
        ? StoredUnits.Unit_Data[0].Pressure[1].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Pressure[1].unit_name
        ? StoredUnits.Unit_Data[0].Pressure[1].unit_name
        : null,
      decimals: StoredUnits.Unit_Data[0].Pressure[1].decimals
        ? StoredUnits.Unit_Data[0].Pressure[1].decimals
        : 0,
    };

    TemperatureUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Temperature[1].unit_id
        ? StoredUnits.Unit_Data[0].Temperature[1].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Temperature[1].unit_name
        ? StoredUnits.Unit_Data[0].Temperature[1].unit_name
        : null,
      decimals: StoredUnits.Unit_Data[0].Temperature[1].decimals
        ? StoredUnits.Unit_Data[0].Temperature[1].decimals
        : 0,
    };

    TimeUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Time[0].unit_id
        ? StoredUnits.Unit_Data[0].Time[0].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Time[0].unit_name
        ? StoredUnits.Unit_Data[0].Time[0].unit_name
        : null,
      decimals: StoredUnits.Unit_Data[0].Time[0].decimals
        ? StoredUnits.Unit_Data[0].Time[0].decimals
        : 0,
    };

    DistanceUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Distance[1].unit_id
        ? StoredUnits.Unit_Data[0].Distance[1].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Distance[1].unit_name
        ? StoredUnits.Unit_Data[0].Distance[1].unit_name
        : null,
      decimals: StoredUnits.Unit_Data[0].Distance[1].decimals
        ? StoredUnits.Unit_Data[0].Distance[1].decimals
        : null,
    };

    WeightUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Weight[1].unit_id
        ? StoredUnits.Unit_Data[0].Weight[1].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Weight[1].unit_name
        ? StoredUnits.Unit_Data[0].Weight[1].unit_name
        : null,
      decimals: StoredUnits.Unit_Data[0].Weight[1].decimals
        ? StoredUnits.Unit_Data[0].Weight[1].decimals
        : null,
    };

    AreaUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Area[1].unit_id
        ? StoredUnits.Unit_Data[0].Area[1].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Area[1].unit_name
        ? StoredUnits.Unit_Data[0].Area[1].unit_name
        : null,
      decimals: StoredUnits.Unit_Data[0].Area[1].decimals
        ? StoredUnits.Unit_Data[0].Area[1].decimals
        : null,
    };

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  const ToggleCustom = () => {
    showAlert.current = true;

    setDefaultCustomChecked(true);
    setDefaultMetricChecked(false);
    setDefaultEnglishChecked(false);
  };

  // **************************************** Speed Units ****************************************************

  // State variable to store metric and english selected decimal values
  const [SpeedMetricDecimals, setSpeedMetricDecimals] = useState(0.1);
  const [SpeedEnglishDecimals, setSpeedEnglishDecimals] = useState(0.12);

  // **************************************** Pressure Units **********************************************************

  // State variable to store metric and english selected decimal values
  const PressureMPaMetricDecimals = useRef(0.12);
  const PressurebarMetricDecimals = useRef(0.1);

  const PressurepsiEnglishDecimals = useRef(0);
  const PressurekpsiEnglishDecimals = useRef(0);

  // ********************************************** Temperature ***************************************

  // State variable to store metric and english selected decimal values
  const [TemperatureMetricDecimals, setTemperatureMetricDecimals] = useState(0);
  const [TemperatureEnglishDecimals, setTemperatureEnglishDecimals] =
    useState(0);

  // ********************************************** Time **************************************************

  // State variable to store metric and english selected decimal values
  const TimeSecMetricDecimals = useRef(0);
  const TimeMinMetricDecimals = useRef(0.1);
  const TimeHrsMetricDecimals = useRef(0.12);

  // ********************************************* Distance ****************************************************

  // State variable to store metric and english selected decimal values
  const [DistanceMetricDecimals, setDistanceMetricDecimals] = useState(0.12);
  const [DistanceEnglishDecimals, setDistanceEnglishDecimals] = useState(0.12);

  // ********************************************** Weight ****************************************************

  // State variable to store metric and english selected decimal values
  const [WeightMetricDecimals, setWeightMetricDecimals] = useState(0.12);
  const [WeightEnglishDecimals, setWeightEnglishDecimals] = useState(0.12);

  // ********************************************** Area ****************************************************

  // State variable to store metric and english selected decimal values
  const [AreaMetricDecimals, setAreaMetricDecimals] = useState(0.12);
  const [AreaEnglishDecimals, setAreaEnglishDecimals] = useState(0.12);

  useEffect(() => {
    let data = {
      user: user.id,
      Unit_Data: [
        {
          Speed: SpeedUnits.current,
          Pressure: PressureUnits.current,
          Temperature: TemperatureUnits.current,
          Time: TimeUnits.current,
          Area: AreaUnits.current,
          Weight: WeightUnits.current,
          Distance: DistanceUnits.current,
        },
      ],
    };

    UnitsData.current = data;

    showAlert.current = true;

    // console.log("Selected", UnitsData.current.Unit_Data);

    // eslint-disable-next-line
  }, [
    SelectedUnitsData,
    SpeedEnglishDecimals,
    SpeedMetricDecimals,
    SpeedUnits.current,
    PressureUnits.current,
    TemperatureUnits.current,
    TimeUnits.current,
    AreaUnits.current,
    WeightUnits.current,
    DistanceUnits.current,
  ]);

  return (
    <>
      <div className="card unitmanagement p-3  ml-2">
        <div className="table-responsive">
          <table className="table">
            {/* *********************************** Header - Unit Settings ***************************** */}
            <tr>
              <th>Unit Settings</th>
            </tr>
            <tr>
              <td align="center">
                <form>
                  <div className="form-group m-t-8 m-checkbox-inline mb-0 custom-radio-ml">
                    <div className="radio radio-primary">
                      <input
                        id="radioinline1"
                        type="radio"
                        onClick={ToggleMetric}
                        name="radio3"
                        value="option1"
                        defaultChecked={DefaultMetricChecked}
                      />
                      <label className="mb-0" htmlFor="radioinline1">
                        <span className="digits"> {"Metric Unit"}</span>
                      </label>
                    </div>
                    <div className="radio radio-primary">
                      {DefaultEnglishChecked ? (
                        <input
                          id="radioinline2"
                          type="radio"
                          onClick={ToggleEnglish}
                          name="radio3"
                          value="option1"
                          defaultChecked
                        />
                      ) : (
                        <input
                          id="radioinline2"
                          type="radio"
                          onClick={ToggleEnglish}
                          name="radio3"
                          value="option1"
                        />
                      )}
                      <label className="mb-0" htmlFor="radioinline2">
                        {Option}
                        <span className="digits"> {"English Unit"}</span>
                      </label>
                    </div>
                    <div className="radio radio-primary">
                      {DefaultCustomChecked ? (
                        <input
                          id="radioinline3"
                          type="radio"
                          onClick={ToggleCustom}
                          name="radio3"
                          value="option1"
                          defaultChecked
                        />
                      ) : (
                        <input
                          id="radioinline3"
                          type="radio"
                          onClick={ToggleCustom}
                          name="radio3"
                          value="option1"
                        />
                      )}
                      <label className="mb-0" htmlFor="radioinline3">
                        {Option}
                        <span className="digits"> {"Custom Unit"}</span>
                      </label>
                    </div>
                  </div>
                </form>
              </td>
            </tr>

            {/* ************************************** Speed Unit ***************************************** */}
            <tr>
              <th align="left">Speed</th>
            </tr>

            <tr>
              <td>
                <Speed
                  SpeedEnglishDecimals={SpeedEnglishDecimals}
                  setSpeedEnglishDecimals={setSpeedEnglishDecimals}
                  SpeedMetricDecimals={SpeedMetricDecimals}
                  setSpeedMetricDecimals={setSpeedMetricDecimals}
                  StoredUnits={StoredUnits}
                  DistanceUnits={DistanceUnits}
                  SpeedUnits={SpeedUnits}
                  PressureUnits={PressureUnits}
                  TemperatureUnits={TemperatureUnits}
                  TimeUnits={TimeUnits}
                  AreaUnits={AreaUnits}
                  WeightUnits={WeightUnits}
                  setSelectedUnitsData={setSelectedUnitsData}
                  showAlert={showAlert}
                />
              </td>
            </tr>

            {/* ************************************** Pressure Unit ***************************************** */}
            <tr>
              <th align="left">Pressure (All)</th>
            </tr>

            <tr>
              <td>
                <Pressure
                  PressureMPaMetricDecimals={PressureMPaMetricDecimals}
                  PressurebarMetricDecimals={PressurebarMetricDecimals}
                  PressurepsiEnglishDecimals={PressurepsiEnglishDecimals}
                  PressurekpsiEnglishDecimals={PressurekpsiEnglishDecimals}
                  StoredUnits={StoredUnits}
                  DistanceUnits={DistanceUnits}
                  SpeedUnits={SpeedUnits}
                  PressureUnits={PressureUnits}
                  TemperatureUnits={TemperatureUnits}
                  TimeUnits={TimeUnits}
                  AreaUnits={AreaUnits}
                  WeightUnits={WeightUnits}
                  setSelectedUnitsData={setSelectedUnitsData}
                  showAlert={showAlert}
                />
              </td>
            </tr>

            {/* ************************************** Temperature Unit ***************************************** */}
            <tr>
              <th align="left">Temperature(All)</th>
            </tr>

            <tr>
              <td>
                <Temperature
                  TemperatureEnglishDecimals={TemperatureEnglishDecimals}
                  setTemperatureEnglishDecimals={setTemperatureEnglishDecimals}
                  TemperatureMetricDecimals={TemperatureMetricDecimals}
                  setTemperatureMetricDecimals={setTemperatureMetricDecimals}
                  StoredUnits={StoredUnits}
                  DistanceUnits={DistanceUnits}
                  SpeedUnits={SpeedUnits}
                  PressureUnits={PressureUnits}
                  TemperatureUnits={TemperatureUnits}
                  TimeUnits={TimeUnits}
                  AreaUnits={AreaUnits}
                  WeightUnits={WeightUnits}
                  setSelectedUnitsData={setSelectedUnitsData}
                  showAlert={showAlert}
                />
              </td>
            </tr>

            {/* ************************************** Time Unit ***************************************** */}
            <tr>
              <th align="left">Times(All)</th>
            </tr>

            <tr>
              <td>
                <Time
                  TimeHrsMetricDecimals={TimeHrsMetricDecimals}
                  TimeMinMetricDecimals={TimeMinMetricDecimals}
                  TimeSecMetricDecimals={TimeSecMetricDecimals}
                  StoredUnits={StoredUnits}
                  DistanceUnits={DistanceUnits}
                  SpeedUnits={SpeedUnits}
                  PressureUnits={PressureUnits}
                  TemperatureUnits={TemperatureUnits}
                  TimeUnits={TimeUnits}
                  AreaUnits={AreaUnits}
                  WeightUnits={WeightUnits}
                  setSelectedUnitsData={setSelectedUnitsData}
                  showAlert={showAlert}
                />
              </td>
            </tr>

            {/* ************************************** Distance Unit ***************************************** */}
            <tr>
              <th align="left">Distance(All)</th>
            </tr>

            <tr>
              <td>
                <Distance
                  DistanceEnglishDecimals={DistanceEnglishDecimals}
                  DistanceMetricDecimals={DistanceMetricDecimals}
                  setDistanceEnglishDecimals={setDistanceEnglishDecimals}
                  setDistanceMetricDecimals={setDistanceMetricDecimals}
                  StoredUnits={StoredUnits}
                  DistanceUnits={DistanceUnits}
                  SpeedUnits={SpeedUnits}
                  PressureUnits={PressureUnits}
                  TemperatureUnits={TemperatureUnits}
                  TimeUnits={TimeUnits}
                  AreaUnits={AreaUnits}
                  WeightUnits={WeightUnits}
                  setSelectedUnitsData={setSelectedUnitsData}
                  showAlert={showAlert}
                />
              </td>
            </tr>

            {/* ************************************** Weight Unit ***************************************** */}
            <tr>
              <th align="left">Weight(All)</th>
            </tr>

            <tr>
              <td>
                <Weight
                  WeightEnglishDecimals={WeightEnglishDecimals}
                  WeightMetricDecimals={WeightMetricDecimals}
                  setWeightEnglishDecimals={setWeightEnglishDecimals}
                  setWeightMetricDecimals={setWeightMetricDecimals}
                  StoredUnits={StoredUnits}
                  DistanceUnits={DistanceUnits}
                  SpeedUnits={SpeedUnits}
                  PressureUnits={PressureUnits}
                  TemperatureUnits={TemperatureUnits}
                  TimeUnits={TimeUnits}
                  AreaUnits={AreaUnits}
                  WeightUnits={WeightUnits}
                  setSelectedUnitsData={setSelectedUnitsData}
                  showAlert={showAlert}
                />
              </td>
            </tr>

            {/* ************************************** Area Unit ***************************************** */}
            <tr>
              <th align="left">Area</th>
            </tr>

            <tr>
              <td>
                <Area
                  AreaMetricDecimals={AreaMetricDecimals}
                  AreaEnglishDecimals={AreaEnglishDecimals}
                  setAreaEnglishDecimals={setAreaEnglishDecimals}
                  setAreaMetricDecimals={setAreaMetricDecimals}
                  StoredUnits={StoredUnits}
                  DistanceUnits={DistanceUnits}
                  SpeedUnits={SpeedUnits}
                  PressureUnits={PressureUnits}
                  TemperatureUnits={TemperatureUnits}
                  TimeUnits={TimeUnits}
                  AreaUnits={AreaUnits}
                  WeightUnits={WeightUnits}
                  setSelectedUnitsData={setSelectedUnitsData}
                  showAlert={showAlert}
                />
              </td>
            </tr>
          </table>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default ManagementUnit;
