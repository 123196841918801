import React, { useEffect, useState } from "react";
import "../App.css";
import Header2 from "../common/header-component/header2";
import { useHistory } from "react-router-dom";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import DataService from "../../services/ApiService";

const DoenewSession = ({ user }) => {
  const { Mold_Id, Session_Id, Session_Name } = useParams();

  const session_decry = atob(Session_Id);

  const moldID = atob(Mold_Id);

  const [newseshdata, setNewseshdata] = useState({
    id: 1,
    mold: 1,
    Session_Name: " ",
    Date: " ",
  });

  const [MoldData, setMoldData] = useState();

  const [MoldName, setMoldName] = useState();

  const easyVariables = [
    "Back Pressure",
    "Holding Pressure",
    "Injection Pressure",
    "Injection Speed",
    "Pack Pressure",
  ];

  const mediumVariables = [
    "Cooling Time",
    "Cycle Time",
    "Screw Speed",
    "Holding Time",
    "Pack Time",
  ];

  const hardVariables = [
    "Melt Temperature",
    "Mold Temp A Side",
    "Mold Temp B Side",
    "Mold Temperature",
    "Tonnage",
  ];

  const fetchSessionData = () => {
    // console.log(session_decry)
    return DataService.FetchDoeSession(moldID).then((res) => {
      const fetchedData = res.data;
      // Convert session_decry to a number for a strict comparison
      const sessionData = fetchedData.find(
        (session) => session.id === parseInt(session_decry, 10)
      );
      setNewseshdata(sessionData);
    });
  };

  const getMoldData = () => {
    return DataService.GetMold(moldID)
      .then((res) => {
        setMoldData(res.data.Part_Data);
        const moldName = res.data.Mold_Id;
        // console.log("Mold_Id:", res.data.Mold_Id);
        setMoldName(moldName);
      })
      .catch((error) => {
        console.error("Error fetching mold data:", error);
      });
  };

  useEffect(() => {
    getMoldData().then(() => {
      fetchSessionData();
    });
  }, []); // Adding dependencies ensures re-fetch if IDs change

  // Empty dependency array to fetch once on mount

  // console.log("data",data);

  const initialVariableData = Array.from({ length: 5 }, () => ({
    session_id: session_decry,
    variable_name: "",
    unit: "",
    high: "",
    low: "",
    precision: "",
  }));

  let [variableData, setVariableData] = useState(initialVariableData);

  const formattedDate = new Date(newseshdata["Date"]).toLocaleDateString(
    "en-GB"
  );

  // Define partValue within the component
  let partValue = [];

  if (MoldData && MoldData.length > 0) {
    // Find the object with Cavity_Data: 'Part Description *'
    const partnumberObject = MoldData.find(
      (item) => item.Cavity_Data === "Part Number *"
    );

    const partRegex = /^Part\d+$/;

    // Find the key in partDescriptionObject that matches the pattern
    partValue = Object.keys(partnumberObject)
      .filter((key) => partRegex.test(key))
      .map((key) => partnumberObject[key]);

    // console.log("partValue", partValue);
  } else {
    // console.log("partData is undefined or empty.");
  }

  const initialResponseData = [
    {
      response_name: "",
      nom: "",
      plus_tol: "",
      minus_tol: "",
      samplesize: "",
    },
  ];

  let [responseData, setResponseData] = useState(initialResponseData);

  // Loop through partvaluu and add entries
  partValue.forEach((value) => {
    initialResponseData[0][value] = false; // Set each part value as false
  });

  const history = useHistory();
  // const pathD = () => {
  //   history.push({ pathname: `/dashboard/DOE/doe-new-session` });
  // };

  function VarDropDownTemplate(props, index) {
    return (
      <select
        className="w-100"
        defaultValue={variableData[index]?.variable_name || ""} // Tie value to the specific index
        onChange={(e) => handleVariableChange(e, index, "variable_name")} // Update the specific row
      >
        <option value="">{"--Pick from list or type in--"}</option>
        <option value="Back Pressure">{"Back Pressure"}</option>
        <option value="Cooling Time">{"Cooling Time"}</option>
        <option value="Cycle Time">{"Cycle Time"}</option>
        <option value="Holding Pressure">{"Holding Pressure"}</option>
        <option value="Holding Time">{"Holding Time"}</option>
        <option value="Injection Pressure">{"Injection Pressure"}</option>
        <option value="Injection Speed">{"Injection Speed"}</option>
        <option value="Mold Temp A Side">{"Mold Temp A Side"}</option>
        <option value="Mold Temp B Side">{"Mold Temp B Side"}</option>
        <option value="Melt Temperature">{"Melt Temperature"}</option>
        <option value="Mold Temperature">{"Mold Temperature"}</option>
        <option value="Pack Pressure">{"Pack Pressure"}</option>
        <option value="Pack Time">{"Pack Time"}</option>
        <option value="Screw Speed">{"Screw Speed"}</option>
        <option value="Tonnage">{"Tonnage"}</option>
      </select>
    );
  }

  function VarinputTemplate(props, index, field) {
    return (
      <input
        className="w-100 p-1"
        defaultValue={variableData[index]?.[field] || ""}
        onChange={(e) => handleVariableChange(e, index, field)}
        // onBlur={() => console.log(variableData)}
      ></input>
    );
  }
  function ResponseinputTemplate(props, index, field) {
    return (
      <input
        className="w-100 p-1"
        defaultValue={responseData[index]?.[field] || ""}
        onChange={(e) => handleResponseChange(e, index, field)}
      ></input>
    );
  }
  function ResponsecheckTemplate(props, index, field) {
    return (
      <input
        type="checkbox"
        className="text-center"
        defaultChecked={responseData[index]?.[field] || false}
        onChange={(e) => handleResponseChange(e, index, field, "checkbox")}
      />
    );
  }

  // Columns for Variables
  const VariableColumns = [
    {
      field: "variable_name",
      headerText: "Variable",
      template: (props) => VarDropDownTemplate(props, props.index), // Pass index from props
      width: 100,
      textAlign: "Center",
    },
    {
      field: "unit",
      headerText: "Unit",
      template: (props) => VarinputTemplate(props, props.index, "unit"),
      width: 100,
      textAlign: "Center",
    },
    {
      field: "high",
      headerText: "High",
      template: (props) => VarinputTemplate(props, props.index, "high"),
      width: 100,
      textAlign: "Center",
    },
    {
      field: "low",
      headerText: "Low",
      template: (props) => VarinputTemplate(props, props.index, "low"),
      width: 100,
      textAlign: "Center",
    },
    {
      field: "precision",
      headerText: "Precision",
      template: (props) => VarinputTemplate(props, props.index, "precision"),
      width: 100,
      textAlign: "Center",
    },
  ];

  // Columns for Response
  const ResponseColumns = [
    {
      field: "response_name",
      headerText: "Response Name",
      template: (props) =>
        ResponseinputTemplate(props, props.index, "response_name"),
      width: 100,
      textAlign: "Center",
    },
    {
      field: "nom",
      headerText: "Nominal",
      template: (props) => ResponseinputTemplate(props, props.index, "nom"),
      width: 100,
      textAlign: "Center",
    },
    {
      field: "plus_tol",
      headerText: "+Tol",
      template: (props) =>
        ResponseinputTemplate(props, props.index, "plus_tol"),
      width: 100,
      textAlign: "Center",
    },
    {
      field: "minus_tol",
      headerText: "-Tol",
      template: (props) =>
        ResponseinputTemplate(props, props.index, "minus_tol"),
      width: 100,
      textAlign: "Center",
    },
    {
      field: "samplesize",
      headerText: "Sample Size",
      template: (props) =>
        ResponseinputTemplate(props, props.index, "samplesize"),
      width: 100,
      textAlign: "Center",
    },

    // Dynamically add columns based on partvalue
    ...partValue.map((value) => ({
      field: value,
      headerText: value,
      template: (props) => ResponsecheckTemplate(props, props.index, value),
      width: 100,
      textAlign: "Center",
    })),
  ];

  const AddDimension = () => {
    setResponseData((prevData) => [...prevData, {}]); // Add a new dimension
  };

  const RemoveDimension = () => {
    if (responseData.length > 1) {
      setResponseData((prevData) => prevData.slice(0, -1)); // Remove the last dimension
    } else alert("At least one dimension must be defined.");
  };

  // const [tempVariableData, setTempVariableData] = useState(initialVariableData);

  const handleVariableChange = (e, index, field) => {
    const newVariableData = [...variableData]; // Copy the current state

    // Safeguard: Check if the index is within bounds
    if (newVariableData[index]) {
      const value = e.target.value;

      // Update the field directly without any specific validation for high, low, or precision
      newVariableData[index][field] = value;

      // setVariableData(newVariableData); // Update the state with modified data

      variableData = newVariableData;
    } else {
      // console.error(`Index ${index} is out of bounds for variableData`);
    }
  };

  const handleResponseChange = (e, index, field, type = "text") => {
    const newResponseData = [...responseData];
    newResponseData[index][field] =
      type === "checkbox" ? e.target.checked : e.target.value;
    // setResponseData(newResponseData);
    responseData = newResponseData;
  };

  const handleSave = () => {
    let isValid = true; // Flag to track if all validations pass

    // console.log("variabledata",variableData)
    // Loop through the variable data and perform validations
    for (let i = 0; i < variableData.length; i++) {
      const variable = variableData[i];

      // Check if any field (high, low, precision, unit) is filled but the variable name is missing
      if (
        variable.high ||
        variable.low ||
        variable.precision ||
        variable.unit
      ) {
        if (!variable.variable_name) {
          alert("Please select Variable Name");
          isValid = false;
          break; // Stop further validation if this fails
        }
      }

      // If variable name is present, check the required fields
      if (variable.variable_name) {
        if (!variable.high || !variable.low || !variable.precision) {
          let missingFields = [];

          // Collect missing fields
          if (!variable.high) missingFields.push("High");
          if (!variable.low) missingFields.push("Low");
          if (!variable.precision) missingFields.push("Precision");

          // Alert the missing fields
          alert(
            `Please enter value(s) for ${missingFields.join(", ")} in ${
              variable.variable_name
            }.`
          );
          isValid = false;
          break; // Stop further validation if this fails
        }

        // Check if high, low, and precision are numbers
        if (isNaN(variable.high) && variable.high === "") {
          alert(`High value must be a number for ${variable.variable_name}.`);
          isValid = false;
          break;
        }
        if (isNaN(variable.low) && variable.low === "") {
          alert(`Low value must be a number for ${variable.variable_name}.`);
          isValid = false;
          break;
        }
        if (isNaN(variable.precision) || variable.precision === "") {
          alert(
            `Precision value must be a number for ${variable.variable_name}.`
          );
          isValid = false;
          break;
        }

        // Check if high is greater than low
        if (Number(variable.high) <= Number(variable.low)) {
          alert(
            `High value must be greater than Low value for ${variable.variable_name}.`
          );
          isValid = false;
          break;
        }
      }
    }

    for (let i = 0; i < responseData.length; i++) {
      const response = responseData[i];
      let missingFields = [];
      let invalidFields = [];

      if (response.response_name) {
        // Check each field dynamically
        if (!response.nom) missingFields.push("Nominal");
        else if (isNaN(response.nom)) invalidFields.push("Nominal");

        if (!response.plus_tol) missingFields.push("+Tol");
        else if (isNaN(response.plus_tol)) invalidFields.push("+Tol");

        if (!response.minus_tol) missingFields.push("-Tol");
        else if (isNaN(response.minus_tol)) invalidFields.push("-Tol");

        if (!response.samplesize) missingFields.push("Sample Size");
        else if (isNaN(response.samplesize)) {
          invalidFields.push("Sample Size");
        } else {
          const sampleSizeNum = Number(response.samplesize);
          if (sampleSizeNum < 1 || sampleSizeNum > 7) {
            alert("Sample Size must be between 1 and 7");
            isValid = false;
          }
        }

        // If there are missing fields, alert the user
        if (missingFields.length > 0) {
          alert(
            `Please fill in the following field(s) for ${
              response.response_name || "Response " + (i + 1)
            }: ${missingFields.join(", ")}`
          );
          isValid = false;
          break; // Stop further validation if any field is missing
        }
        // If there are invalid fields, alert the user
        if (invalidFields.length > 0) {
          alert(
            `The following field(s) must be numeric for ${
              response.response_name || "Response " + (i + 1)
            }: ${invalidFields.join(", ")}`
          );
          isValid = false;
          break; // Stop further validation if any field is invalid
        }
      } else if (
        partValue.some((value) => response[value]) ||
        response.minus_tol ||
        response.nom ||
        response.plus_tol ||
        response.samplesize
      ) {
        alert("Please add response name");
        isValid = false;
      }
    }
    const filteredResponseData = responseData.filter(
      (response) => response.response_name
    );

    if (filteredResponseData.length < 1) {
      alert("Please add at least one response");
      isValid = false;
    }

    const isChecked = filteredResponseData.every((data) => {
      const checkedParts = partValue.filter((part) => data[part]);
      return checkedParts.length === 1;
    });

    if (!isChecked) {
      alert("Please select 1 cavity for each row before submitting.");
      isValid = false;
      return;
    }

    const filteredVariableData = variableData.filter(
      (variable) => variable.variable_name
    );

    const responsename = new Set();
    for (let i = 0; i < filteredResponseData.length; i++) {
      const responseName = filteredResponseData[i].response_name;

      // Check if the variable name already exists in the Set
      if (responsename.has(responseName)) {
        alert(
          `Response name "${responseName}" is repeated. Please ensure unique response names.`
        );
        isValid = false;
        return; // Stop execution if duplicate names are found
      } else {
        responsename.add(responseName); // Add the variable name to the Set
      }
    }

    const variableNames = new Set(); // Create a Set to hold unique variable names

    for (let i = 0; i < filteredVariableData.length; i++) {
      const variableName = filteredVariableData[i].variable_name;

      // Check if the variable name already exists in the Set
      if (variableNames.has(variableName)) {
        alert(
          `Variable name "${variableName}" is repeated. Please ensure unique variable names.`
        );
        isValid = false;
        return; // Stop execution if duplicate names are found
      } else {
        variableNames.add(variableName); // Add the variable name to the Set
      }
    }

    if (filteredVariableData.length < 2) {
      alert("There must be minimum 2 factors");
      isValid = false;
      return;
    }

    // If all validations passed, populate sessionData and log it
    if (isValid) {
      // const sessionData = {
      //   variables: filteredVariableData,
      //   responses: responseData,
      // };

      let tempdataResponse = {
        session_id: session_decry,
        response_details: responseData,
      };

      let tempdataVariable = {
        session_id: session_decry,
        variable_details: filteredVariableData,
      };
      // console.log(tempdataVariable)

      const filteredData = tempdataVariable.variable_details.filter(
        (item) => item.variable_name !== ""
      );

      const vardata = filteredData.map((item) => item.variable_name);

      // Function to reorder based on the rules
      function reorderVariables(vardata, easyVars, mediumVars, hardVars) {
        const categories = { easy: [], medium: [], hard: [] };

        vardata.forEach((variable) => {
          if (easyVars.includes(variable)) {
            categories.easy.push(variable);
          } else if (mediumVars.includes(variable)) {
            categories.medium.push(variable);
          } else if (hardVars.includes(variable)) {
            categories.hard.push(variable);
          }
        });

        // Determine the final order
        if (
          categories.easy.length &&
          categories.medium.length &&
          categories.hard.length
        ) {
          return [...categories.hard, ...categories.medium, ...categories.easy];
        } else if (categories.easy.length && categories.medium.length) {
          return [...categories.medium, ...categories.easy];
        } else {
          // If all belong to the same category, keep order same as vardata
          return vardata;
        }
      }

      const reorderedVardata = reorderVariables(
        vardata,
        easyVariables,
        mediumVariables,
        hardVariables
      );

      // console.log("vardata", vardata);
      // console.log("reordered:", reorderedVardata);

      const reversedData = reorderedVardata.reverse();

      let createsession_data = {
        session_id: session_decry,
        experiment_desc: newseshdata["ExperimentDesc"] || "-",
        variables: reversedData,
      };

      const postdata = {
        session_id: session_decry,
        variable_details: JSON.stringify(filteredVariableData), // Convert to JSON string
      };

      function postVariable() {
        return DataService.PostVariable(session_decry, postdata)
          .then((res) => {
            console.log("Response from PostVariable API", res);
          })
          .catch((error) => {
            console.error("Error in PostVariable:", error);
          });
      }

      function postResponse() {
        return DataService.PostResponse(session_decry, moldID, tempdataResponse)
          .then((res) => {
            console.log("Response from PostResponse API", res);
            // var Session_Id = btoa(session_decry);
          })
          .catch((error) => {
            console.error("Error in PostResponse:", error);
          });
      }

      function createSession() {
        return DataService.CreateSession(session_decry, createsession_data)
          .then((res) => {
            console.log("Session created successfully", res);
          })
          .catch((error) => {
            console.error("Error in CreateSession:", error);
          });
      }

      postVariable()
        .then(() => postResponse())
        .then(() => createSession())
        .then(() => {
          history.push({
            pathname: `/${Session_Id}/DOE/summary`,
            state: { mold: moldID, session: session_decry },
          });
        })
        .catch((error) => console.error("Error in data processing:", error));
    }
  };

  return (
    <>
      {" "}
      {MoldData && (
        <>
          <Header2 Title="Current Sessions" />

          <div className="container-fluid">
            <div className="d-flex pt-3 pb-3">
              <div className="d-flex col-md-12">
                <div className="col-md-10 d-flex">
                  <div>
                    <span
                      className="BreadCrum"
                      // onClick={() => history.push("/dashboard/mold")}
                      style={{ fontSize: "14px", color: "blue" }}
                    >
                      {" "}
                      Molds{" "}
                    </span>
                  </div>
                  <div>
                    <span className="BreadCrum" style={{ fontSize: "16px" }}>
                      {" "}
                      {">"}{" "}
                    </span>
                  </div>
                  <div>
                    <span className="BreadCrum" style={{ fontSize: "14px" }}>
                      {" "}
                      DOE New Session{" "}
                    </span>
                  </div>
                </div>

                <div className="col-md-2">
                  {/* <div className="mt-2 ml-2"> */}
                  <button
                    className="btn btn-secondary btn-air-secondary"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  <button className="btn btn-warning btn-air-warning ml-2">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id="Container" className="container-fluid">
            <div className="card p-3 ml-4">
              <div className="b-primary b-r-4 m-1">
                <div className="d-flex justify-content-center">
                  <div className="col-md-3 mt-1 mr-1 mb-1">
                    <label className="lbl_design mt-1">
                      Mold No : {MoldName}{" "}
                    </label>
                  </div>

                  <div className="col-md-3 mt-1 mr-1 mb-1">
                    <label className="lbl_design mt-1">
                      Session Name : {atob(Session_Name)}{" "}
                    </label>
                  </div>

                  <div className="col-md-3 mt-1 mr-1 mb-1">
                    <label
                      // className="lbl_design top mt-3"
                      className="lbl_design mt-1"
                    >
                      Experiment Description : {newseshdata["ExperimentDesc"]}
                    </label>
                  </div>

                  <div className="col-md-3 mt-1 mr-1 mb-1 text-right">
                    <label className="lbl_design mt-1">
                      Date : {formattedDate}{" "}
                    </label>
                  </div>
                </div>
              </div>

              <div className="b-primary b-r-4 m-1">
                <div className="mt-3 mr-3 mb-3 col-md-12">
                  <h6>Define Variable</h6>

                  <div className="table-responsive" id="DBTable">
                    <table className="table" cellPadding={"0"}>
                      <tr>
                        <td>
                          <GridComponent
                            id="Grid"
                            dataSource={variableData}
                            columns={VariableColumns}
                            height={210}
                          ></GridComponent>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="mt-3 mr-3 mb-3 col-md-12">
                  <h6>Define Response</h6>

                  <div className="table-responsive" id="DBTable">
                    <table className="table" cellPadding={"0"}>
                      <tr>
                        <td>
                          <GridComponent
                            id="Grid"
                            dataSource={responseData}
                            columns={ResponseColumns}
                            height={100}
                          ></GridComponent>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="d-flex">
                  <div>
                    <button
                      className="btn btn-info btn-air-info ml-3 mt-2 mb-2"
                      onClick={AddDimension}
                    >
                      {" "}
                      Add Dimensions{" "}
                    </button>
                  </div>

                  <div>
                    <button
                      className="btn btn-warning btn-air-warning ml-3 mt-2 mb-2"
                      onClick={RemoveDimension}
                    >
                      {" "}
                      Delete Dimensions{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}{" "}
    </>
  );
};

export default DoenewSession;
