import React, { useState, useEffect, useRef } from "react";

import {
  ChartComponent,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";

// import Pressure from "../modals/Pressure";
import PressureGrid from "../Grids/PressureGrid";
import { nanoid } from "nanoid";

// Event having multiple method's to deal with the back-end
import DataService from "../../services/ApiService";

// Hook to get the parameter's from the URL
import { useParams } from "react-router-dom";

import { Button } from "reactstrap";
import PressureAddRow from "../columns&rows/PressureAddRow";
import {
  HtmlEditor,
  Inject,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { useReactToPrint } from "react-to-print";

import PressurePrint from "../printables/PressurePrint";

const PressureDropStudy = ({ showAlert, PressureData, ToSavePressureData }) => {
  // const [modal, setModal] = useState();

  // const toggle = () => {
  //     setModal(!modal)
  // }

  let PressureSpreadsheet = useRef();

  // Total Number of row's to be displayed in the PressureSpreadsheet
  const [PressurerowCount, setPressureRowCount] = useState(10);

  const [rowToBeDeleted, setRowToBeDeleted] = useState();

  const [modal2, setModal2] = useState();

  const toggle2 = () => {
    setModal2(!modal2);
  };

  const [row, setRow] = useState(null);

  const [PressureGridRow, setPressureGridRow] = useState([]);

  const addRow = (e) => {
    e.preventDefault();

    setRow(e.target.value);
  };

  const [MaxPressAlert, setMaxPressAlert] = useState(true);

  const ToggleMaxAlert = () => {
    setMaxPressAlert(!MaxPressAlert);
  };

  const [PeakPressAlert, setPeakPressAlert] = useState(true);

  const TogglePeakAlert = () => {
    setPeakPressAlert(!PeakPressAlert);
  };

  // This is a simple array which holds the number of objects based on the row variable
  // const AddedRow = [];

  const increaseRow = () => {
    if (row) {
      // Updating the total rows variable
      setPressureRowCount(parseInt(PressurerowCount) + parseInt(row));

      showAlert.current = true;

      setAlert(true);

      setRow(null);

      toggle2();

      PressureSpreadsheet.current.getActiveSheet().rowCount =
        PressureSpreadsheet.current.getActiveSheet().rowCount + parseInt(row);
    } else {
      alert("Please enter number of rows.");
    }

    // PressureSpreadsheet.current.refresh()
  };

  const deleteRow2 = (id) => {
    PressureSpreadsheet.current.delete(
      rowToBeDeleted,
      rowToBeDeleted,
      "Row",
      0
    );

    setPressureRowCount(parseInt(PressurerowCount) - 1);

    const NewRows = [...PressureGridRow].filter((value) => {
      return value.id !== id;
    });

    // console.log(NewRows)

    setPressureGridRow(NewRows);

    showAlert.current = true;

    setAlert(true);
  };

  const PopulateSheet = (CalcData) => {
    // console.log(CalcData)

    for (let i = 0; i < CalcData.length; i++) {
      PressureSpreadsheet.current.updateCell(
        { value: CalcData[i]["Flow_Area"] },
        `A${i + 1}`
      );
      PressureSpreadsheet.current.updateCell(
        { value: CalcData[i]["Peak_Pressure"] },
        `B${i + 1}`
      );
      PressureSpreadsheet.current.updateCell(
        { value: CalcData[i]["Percent_Maximum"] },
        `C${i + 1}`
      );
      PressureSpreadsheet.current.updateCell(
        { value: CalcData[i]["Delta P"] },
        `D${i + 1}`
      );
      PressureSpreadsheet.current.updateCell(
        { value: CalcData[i]["%Delta P"] },
        `E${i + 1}`
      );
    }
  };

  const [Max_Press_Available, setMax_Press_Available] = useState(null);
  const [Units, setUnits] = useState();
  const [MaxPressData, setMaxPressData] = useState();
  const [ChartData, setChartData] = useState();

  const [Alert, setAlert] = useState(false);

  const setMaxPressAvailable = (e) => {
    // console.log(e.target.value)

    setMax_Press_Available(e.target.value ? e.target.value : 0);

    showAlert.current = true;
  };

  function calculateNiceNumbers(min, max, numTicks) {
    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
      niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
      niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
      niceStep = 5 * magnitudePow;
    } else {
      niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from(
      { length: numNiceTicks },
      (_, i) => niceMin + i * niceStep
    );

    return {
      min: niceMin,
      max: niceMax,
      step: niceStep,
      ticks: niceTicks,
    };
  }

  const [niceNumbers, setNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const CheckForPeakPressure = (PressureGridRow) => {
    return new Promise((resolve, reject) => {
      const PeakPressureArray = PressureGridRow.filter(
        (item) => item.Peak_Pressure !== 0
      )
        .map((item) => parseFloat(item.Peak_Pressure))
        .filter((value) => !isNaN(value));

      resolve(PeakPressureArray);
    });
  };

  const scaleGraph = () => {
    // console.log(PressureGridRow)

    if (PressureGridRow.length > 0) {
      let pressureArray = [];

      // This is the event to sort the data based on Injection Speed
      const Compare = (a, b) => {
        return a.Peak_Pressure - b.Peak_Pressure;
      };

      for (let i = 0; i < PressureGridRow.length; i++) {
        if (PressureGridRow[i].Peak_Pressure) {
          pressureArray.push(PressureGridRow[i]);
        } else {
        }
      }

      pressureArray.sort(Compare);

      let YMargin =
        ((parseFloat(Max_Press_Available) -
          parseFloat(pressureArray[0]?.Peak_Pressure)) /
          100) *
        5;

      setNiceNumbers(
        calculateNiceNumbers(
          parseFloat(pressureArray[0]?.Peak_Pressure) - YMargin,
          parseFloat(Max_Press_Available) + YMargin,
          5
        )
      );

      // console.log(niceNumbers, YMargin, pressureArray)
    }
  };

  const setGraph = () => {
    if (Max_Press_Available) {
      CheckForPeakPressure(PressureGridRow).then((PeakPressureArray) => {
        // console.log(
        //   PeakPressureArray,
        //     parseFloat(Max_Press_Available)
        // );

        if (Math.max(...PeakPressureArray) < parseFloat(Max_Press_Available)) {
          let maxArray = [];

          for (let i = 0; i < PressureGridRow.length; i++) {
            maxArray.push({
              id: nanoid(),
              Flow_Area: PressureGridRow[i].Flow_Area,
              Max_Press_Available: Max_Press_Available,
            });
          }

          setMaxPressData(maxArray);

          setChartData(PressureGridRow);

          scaleGraph();
        } else if (
          Math.max(...PeakPressureArray) === parseFloat(Max_Press_Available)
        ) {
          let maxArray = [];

          for (let i = 0; i < PressureGridRow.length; i++) {
            maxArray.push({
              id: nanoid(),
              Flow_Area: PressureGridRow[i].Flow_Area,
              Max_Press_Available: Max_Press_Available,
            });
          }

          setMaxPressData(maxArray);

          setChartData(PressureGridRow);

          scaleGraph();
        } else {
          TogglePeakAlert();

          setChartData([]);

          setMaxPressData([]);
        }
      });
    } else {
      ToggleMaxAlert();
    }
  };

  const [ToPlotChart, setToPlotChart] = useState(true);

  useEffect(() => {
    if (Max_Press_Available && ToPlotChart) setGraph();

    // eslint-disable-next-line
  }, [Max_Press_Available]);

  // Session Id getting from the URL
  const [SessionId, setSessionId] = useState();

  // To get the Session Id from url using useParams hook
  var { sessionId, moldName, sessionName } = useParams();

  // Variable to store the a Mold/Session Name
  const [Mold_Name, setMold_Name] = useState();
  const [Session_Name, setSession_Name] = useState();

  // A state variable to store and set the value of textarea
  const [Comment, setComment] = useState("");

  // Event the current session's viscosity data
  const handleGet = (SessionId) => {
    if (SessionId) {
      DataService.FetchPressure(SessionId)
        .then((res) => {
          if (res.data) {
            setPressureGridRow(res.data.Grid_Data);

            PopulateSheet(res.data.Grid_Data);

            setMax_Press_Available(res.data.Max_Pressure_Available);

            setPressureRowCount(
              res.data.Grid_Data.length === 0 ? 10 : res.data.Grid_Data.length
            );

            // console.log(res.data.Grid_Data.length);

            setComment(res.data.Comment);

            setUnits(res.data.Units);

            ToSavePressureData.current = false;
          } else {
            setPressureGridRow([]);
          }
        })
        .catch((err) => {});
    } else {
      setPressureGridRow([]);
    }
  };

  useEffect(() => {
    const data = {
      session: SessionId,
      Units: Units ? Units : "psi",
      Max_Pressure_Available: Max_Press_Available ? Max_Press_Available : 0,
      Grid_Data: PressureGridRow,
      Comment: Comment ? Comment : "N/A",
      PrintData: {
        Units: Units,
        Max_Press_Available: Max_Press_Available,
        PressureGridRow: PressureGridRow,
        Mold_Name: Mold_Name,
        Session_Name: Session_Name,
        niceNumbers: niceNumbers,
        Comment: Comment,
        ChartData: ChartData,
        MaxPressData: MaxPressData,
      },
    };

    PressureData.current = data;

    // console.log(data)

    // eslint-disable-next-line
  }, [PressureGridRow, Max_Press_Available, Units, Comment]);

  // Event that will be called as soon as the Pressure Drop Page load's so that if there is data available will get fetched
  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));

    setMold_Name(atob(moldName));
    setSession_Name(atob(sessionName));

    // After that it call's this event to fetch the data
    handleGet(SessionId);

    // eslint-disable-next-line
  }, [sessionId, SessionId, moldName, sessionName]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  };

  // Event to set the data entered in the textarea
  const getComment = (e) => {
    if (e.target) {
      setComment(e.target.value);
    } else {
      setComment(e.value); // Get the RTE value
      showAlert.current = true;
      setAlert(true);
    }
  };

  const [showPrint, setShowPrint] = useState(false);

  const componentRef = useRef();

  const printPage = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setShowPrint(true),
    onAfterPrint: () => setShowPrint(false),
  });

  const handlePrint = () => {
    setShowPrint(true);

    setTimeout(() => {
      printPage();
    }, 100);
  };

  return (
    <>
      <Modal isOpen={!MaxPressAlert} centered={true}>
        <ModalHeader toggle={ToggleMaxAlert}> Nautilus </ModalHeader>
        <ModalBody>
          <span> Max Press Available cannot be blank. </span>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={ToggleMaxAlert}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={!PeakPressAlert} centered={true}>
        <ModalHeader toggle={TogglePeakAlert}> Nautilus </ModalHeader>
        <ModalBody>
          <span>
            {" "}
            Peak Pressure cannot be greater than Max Pressure Available.{" "}
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={TogglePeakAlert}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <div className="Pressure p-2">
        <section className="card sixstepCard p-3 ml-2">
          <div className="b-primary b-r-4 mb-2">
            <form autoComplete="off">
              <div /*className="d-flex"*/>
                <div className="d-flex">
                  <div className="mt-1 mr-1 mb-1 ml-2">
                    <label
                      htmlFor="Max_Pressure_Available"
                      className="lbl_design"
                      style={{ fontSize: 12 }}
                    >
                      {" "}
                      Max Pressure Available:{" "}
                    </label>
                  </div>

                  <div onClick={() => setToPlotChart(false)}>
                    <input
                      style={{ height: 22 }}
                      className="viscosity-inputs mt-2"
                      onPaste={(e) => {
                        e.preventDefault();

                        // Get the pasted content from the clipboard
                        const pastedText = (
                          e.clipboardData || window.clipboardData
                        ).getData("text");

                        // Check if the pasted content is numeric
                        if (/^\d+$/.test(pastedText)) {
                          // Insert the numeric value into the input field
                          document.execCommand("insertText", false, pastedText);
                        }
                      }}
                      onKeyPress={(event) => {
                        if (!/[-0.0-9.0]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      name="Max_Press_Available"
                      type="text"
                      defaultValue={
                        Max_Press_Available === 0 ? "" : Max_Press_Available
                      }
                      onChange={setMaxPressAvailable}
                    />

                    <select
                      style={{ height: 22 }}
                      className="ml-2"
                      onChange={(e) => setUnits(e.target.value)}
                    >
                      <option>{"psi"}</option>
                      <option>{"mPa"}</option>
                      <option>{"bar"}</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="b-primary b-r-4 pt-2 pb-2">

            <div className="mb-2 d-flex justify-content-between">
              
              <div className="mb-1">

                <button
                  className="btn btn-info btn-air-info ml-3"
                  type="button"
                  onClick={toggle2}
                >
                  {" "}
                  Add Row{" "}
                </button>

                <button
                  className="btn btn-warning btn-air-warning ml-2"
                  type="button"
                  onClick={deleteRow2}
                >
                  {" "}
                  Delete Row{" "}
                </button>

                <button
                  className="btn btn-primary btn-air-primary ml-2"
                  onClick={setGraph}
                >
                  {" "}
                  Show Graph{" "}
                </button>

                {/* <button
                  className="btn btn-secondary btn-air-secondary ml-2"
                  type="button"
                  onClick={handlePrint}
                >
                  {" "}
                  Print{" "}
                </button> */}

                <PressureAddRow
                  toggle2={toggle2}
                  modal2={modal2}
                  addRow={addRow}
                  increaseRow={increaseRow}
                />

                {/* <div> */}

                <Modal isOpen={show} centered>
                  <ModalHeader toggle={handleClose}>Add Comment</ModalHeader>
                  <ModalBody>
                    <RichTextEditorComponent
                      change={getComment}
                      value={Comment}
                      saveInterval="1"
                      toolbarSettings={toolbarSettings}
                      height={250}
                    >
                      <Inject services={[Toolbar, HtmlEditor]} />
                    </RichTextEditorComponent>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="dark" onClick={handleClose}>
                      {" "}
                      Save & Close{" "}
                    </Button>
                  </ModalFooter>
                </Modal>
                {/* </div>   */}
              </div>
            </div>

            <div className="mt-2">
              <div className="d-flex mt-4">
                <div
                  onClick={() => setToPlotChart(false)}
                  className="mb-1 col-md-6"
                  width={"35%"}
                >
                  <PressureGrid
                    PressureGridRow={PressureGridRow}
                    setPressureGridRow={setPressureGridRow}
                    Max_Press_Available={Max_Press_Available}
                    Alert={Alert}
                    PressureSpreadsheet={PressureSpreadsheet}
                    PressurerowCount={PressurerowCount}
                    setRowToBeDeleted={setRowToBeDeleted}
                    PopulateSheet={PopulateSheet}
                    showAlert={showAlert}
                  />
                </div>

                <div className="ml-1 col-md-6" width={"65%"}>
                  <ChartComponent
                    className="chartPressure"
                    tooltip={{ enable: true }}
                    border={{ width: 1, color: "darkblue" }}
                    width="100%"
                    height="400"
                    primaryXAxis={{
                      valueType: "Category",
                      title: "Flow Area",
                      lineStyle: { color: "black" },
                    }}
                    title="Pressure Drop Study"
                    primaryYAxis={{
                      title: "Peak Pressure",
                      minimum: niceNumbers.min,
                      maximum: niceNumbers.max,
                      interval: niceNumbers.step,
                      lineStyle: { color: "black" },
                    }}
                  >
                    <Inject
                      services={[LineSeries, Category, DataLabel, Tooltip]}
                    />

                    <SeriesCollectionDirective>
                      <SeriesDirective
                        type="Line"
                        dataSource={MaxPressData}
                        xName="Flow_Area"
                        yName="Max_Press_Available"
                        fill="rgb(255,0,0)"
                        width={2.5}
                      ></SeriesDirective>

                      <SeriesDirective
                        type="Line"
                        dataSource={ChartData}
                        xName="Flow_Area"
                        yName="Peak_Pressure"
                        marker={{ visible: true }}
                        width={2.5}
                      ></SeriesDirective>
                    </SeriesCollectionDirective>
                  </ChartComponent>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ******************** Printable Part ******************** */}
      <section
        className={showPrint ? "Printable_Part" : "Showable_Part"}
        ref={componentRef}
      >
        <PressurePrint
          Units={Units}
          Max_Press_Available={Max_Press_Available}
          PressureGridRow={PressureGridRow}
          Mold_Name={Mold_Name}
          Session_Name={Session_Name}
          niceNumbers={niceNumbers}
          Comment={Comment}
          ChartData={ChartData}
          MaxPressData={MaxPressData}
        />
      </section>
    </>
  );
};

export default PressureDropStudy;
