import React from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  HistogramSeries,
  StripLine,
  LineSeries,
  SplineSeries,
} from "@syncfusion/ej2-react-charts";

const Histogram = ({
  SelectedColData,
  Bin_Interval,
  yMax,
  xstart,
  xend,
  ChartData,
  maxBinFrequency,
  data,
}) => {
  const onPointRender = (args) => {
    const value = args.point.x;

    const binWidth = Bin_Interval;

    const binStart = value - binWidth / 2;

    const binEnd = value + binWidth / 2;

    // console.log(args.series.type)

    if (args.series.type === "Histogram") {
      if (
        (SelectedColData.upper_limit >= binStart &&
          SelectedColData.upper_limit <= binEnd) ||
        (SelectedColData.lower_limit >= binStart &&
          SelectedColData.lower_limit <= binEnd)
      ) {
        // USL or LSL lies within this bin, so color the entire bin as yellow
        args.fill = "yellow";
      } else if (
        SelectedColData.upper_limit === binStart ||
        SelectedColData.upper_limit === binEnd ||
        SelectedColData.lower_limit === binStart ||
        SelectedColData.lower_limit === binEnd
      ) {
        // Handle the case where USL or LSL is at the edge of a bin
        args.fill = "yellow";
      } else if (
        value > SelectedColData.upper_limit ||
        value < SelectedColData.lower_limit
      ) {
        //Handle the case when bin lies outside the limits
        args.fill = "red";
      } else {
        //Handle the case when bin within outside the limits
        args.fill = "#00FF7F";
      }
    }
  };

  //Line reference
  const legendSettings = { visible: true };

  const tooltipsettings = { enable: true };

  //Line label placement=> Naming the line
  const template = chartTemplate;

  const CurveData_potential_sd = [];
  const CurveData_overall_sd = [];

  function chartTemplate(args) {
    // console.log(args.series.name, args.series.name, args.point.index, args.series.points.length)

    if (
      args.series.name === "Upper Limit" &&
      args.point.index !== args.series.points.length - 1
    ) {
      return (
        <div id="templateWrap">
          <div>{"USL"}</div>
        </div>
      );
    } else if (
      args.series.name === "Lower Limit" &&
      args.point.index !== args.series.points.length - 1
    ) {
      return (
        <div id="templateWrap">
          <div>{"LSL"}</div>
        </div>
      );
    } else if (
      args.series.name === "Target" &&
      args.point.index !== args.series.points.length - 1
    ) {
      return (
        <div id="templateWrap">
          <div>{"Target"}</div>
        </div>
      );
    } else {
      return null; // Return null for other series
    }
  }

  const marker = {
    dataLabel: {
      visible: true,
      position: "Top",
      template: template,
      font: {
        fontWeight: "600",
        color: "black",
      },
    },
  };

  const curveLoad_x = (mean, sd) => {
    const divisor = 5;

    const interval = (3 * sd) / divisor;

    const no_of_points = 11;

    const xvalues = [];

    const x1 = mean - 3 * sd;

    for (let i = 0; i < no_of_points; i++) {
      const x = x1 + i * interval;
      xvalues.push(x);
    }

    return xvalues; // Return the calculated PDF values as an array
  };

  // Load data in CurveData
  const curveLoad_y = (yValues, mean, sd) => {
    const divisor = 5;

    const interval = (3 * sd) / divisor;

    const xvalues = [];

    const x1 = mean - 3 * sd;
    //console.log("given xvalues",yValues)

    for (let i = 0; i < 12; i++) {
      const x = x1 + i * interval;

      xvalues.push(x);
      //console.log("indi values",x)
    }

    const fact = sd * Math.sqrt(2.0 * Math.PI);

    const curveData_y = yValues.map((y) => {
      const expo = ((y - mean) * (y - mean)) / (2.0 * sd * sd);
      return Math.exp(-expo) / fact;
    });

    return curveData_y; // Return the calculated PDF values as an array
  };

  const targetValue = maxBinFrequency * 0.8;

  const scaled_curveLoad_y = (values, targetValue, targetIndex) => {
    const scaledValues = [];

    const scalingfactor = targetValue / values[targetIndex];

    //console.log("targetindex, targetvalue",targetIndex,targetValue)

    // Scale values from the beginning to the middle
    for (let i = 0; i < targetIndex; i++) {
      const scaledValue = values[i] * scalingfactor;
      scaledValues.push(scaledValue);
    }

    scaledValues.push(targetValue);

    // Scale values from the middle to the end
    for (let i = targetIndex + 1; i < values.length; i++) {
      const scaledValue = values[i] * scalingfactor;
      scaledValues.push(scaledValue);
    }

    return scaledValues;
  };

  //Potential CurveLoad Execution
  const normal_x_Values = curveLoad_x(data.mean, data.sd);
  //console.log("curveLoad_x", normal_x_Values);

  const normal_y_Values = curveLoad_y(normal_x_Values, data.mean, data.sd);
  //console.log("curveLoad_y before scalling", normal_y_Values);

  const centerElement = (normal_y_Values.length + 1) / 2;
  //console.log("centermost element",centerElement,normal_y_Values[centerElement])

  const yfinal = scaled_curveLoad_y(
    normal_y_Values,
    targetValue,
    centerElement
  );

  //Overall CurveLoad Execution

  const normal_x_overall_Values = curveLoad_x(data.mean, data.overall_sd);

  const normal_y_overall_Values = curveLoad_y(
    normal_x_Values,
    data.mean,
    data.overall_sd
  );

  const centerElement_osd = (normal_y_overall_Values.length + 1) / 2;

  const yfinal_overall = scaled_curveLoad_y(
    normal_y_overall_Values,
    targetValue,
    centerElement_osd
  );

  //Final plotting
  for (let i = 0; i < normal_x_Values.length; i++) {
    CurveData_potential_sd.push({ x: normal_x_Values[i], y: yfinal[i] });
  }

  //console.log("cdC",CurveData)

  for (let i = 0; i < normal_x_overall_Values.length; i++) {
    CurveData_overall_sd.push({
      x: normal_x_overall_Values[i],
      y: yfinal_overall[i],
    });
  }

  const buffer =
    (SelectedColData.upper_limit - SelectedColData.lower_limit) / 10;
  //console.log(buffer)

  const lb = Math.min(xstart, SelectedColData.lower_limit);
  const xmin = lb - buffer;
  const ub = Math.max(xend, SelectedColData.upper_limit);
  const xmax = ub + buffer;

  const primaryxAxis = {
    lineStyle: { color: "black" },
    majorGridLines: { width: 0 },
    majorTickLines: { width: 1 },
    minimum: xmin,
    maximum: xmax,
  };

  const primaryyAxis = {
    lineStyle: { color: "black" },
    majorTickLines: { width: 1 },
    majorGridLines: { width: 0 },
    maximum: yMax,
  };

  return (
    <div>
      <ChartComponent
        id="charts"
        className="capabilitychart b-primary"
        style={{ textAlign: "center" }}
        border={{ width: 1, color: "black" }}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        legendSettings={legendSettings}
        width="100%"
        height="460"
        pointRender={onPointRender}
        //load={load}
        tooltip={tooltipsettings}
      >
        <Inject
          services={[
            HistogramSeries,
            Legend,
            Tooltip,
            Category,
            DataLabel,
            StripLine,
            LineSeries,
            SplineSeries,
          ]}
        />

        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={ChartData}
            yName="y"
            // name="Data"
            type="Histogram"
            showNormalDistribution={false}
            columnWidth={0.99}
            border={{ width: 1, color: "black" }}
            binInterval={Bin_Interval}
          ></SeriesDirective>

          <SeriesDirective
            dataSource={CurveData_potential_sd}
            xName="x"
            yName="y"
            type="Spline"
            name="Normal Distribution-Potential"
            fill="Purple"
            width={2}
            marker={marker}
          ></SeriesDirective>

          <SeriesDirective
            dataSource={CurveData_overall_sd}
            xName="x"
            yName="y"
            type="Spline"
            name="Normal Distribution-Overall"
            fill="Darkblue"
            width={2}
            marker={marker}
          ></SeriesDirective>

          <SeriesDirective
            dataSource={[
              { x: SelectedColData.target, y: yMax - 0.01 * yMax },
              { x: SelectedColData.target, y: 0 },
            ]}
            type="Line"
            xName="x"
            yName="y"
            fill="blue"
            width={2}
            name="Target"
            marker={marker}
          />

          <SeriesDirective
            dataSource={[
              {
                x: SelectedColData.upper_limit,
                y: yMax - 0.01 * yMax,
              },
              { x: SelectedColData.upper_limit, y: 0 },
            ]}
            type="Line"
            xName="x"
            yName="y"
            fill="red"
            width={2}
            name="Upper Limit"
            marker={marker}
          />

          <SeriesDirective
            dataSource={[
              {
                x: SelectedColData.lower_limit,
                y: yMax - 0.01 * yMax,
              },
              { x: SelectedColData.lower_limit, y: 0 },
            ]}
            type="Line"
            xName="x"
            yName="y"
            fill="red"
            width={2}
            name="Lower Limit"
            marker={marker}
          />
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );
};

export default Histogram;
