import React, { useState } from "react";
import {
  GridComponent,
  Inject,
  Page,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { column_data, row_data } from "../MainDashboard/MoldDemoData";
import { nanoid } from "nanoid";
import DataService from "../../services/ApiService";
import CreateMoldForm from "./CreateMoldForm";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const SelectMold = ({
  user,
  closeDialog,
  SelectedMoldData,
  setSelectedMoldData,
  FetchSessions
}) => {

  const [ModalStates, setModalStates] = useState({
    CreateMold: {
      visibility: false
    },
    MoldIdUnique: {
      visibility: false,
      message: "Mold No should be unique. This Mold No already exists.",
    },
  })

  const TogglCreatetMoldState = () => {
    setModalStates((prevState) => ({
      ...prevState,
      CreateMold: {
        visibility: !ModalStates.CreateMold.visibility,
      }
    }))
  }

  var grid;

  const toolbar = ["Search"];

  let StoredMoldData = JSON.parse(sessionStorage.getItem("MoldData")) || [];

  const selectionSettings = { type: "single", checkboxMode: "ResetOnRowClick" };

  const MoldColumns = [
    {
      headerText: "",
      width: 10,
      template: MoldSelectionTemplate.bind(this),
    },
    {
      field: "Mold_Id",
      headerText: "Mold Name",
      width: 50,
      textAlign: "center",
    },
  ];

  function MoldSelectionTemplate(props) {
    // console.log(props)
    return (
      <input
        type="radio"
        name="SpeedUnit"
        onClick={() => {
          rowSelected(props);
        }}
        defaultChecked={
          SelectedMoldData[0]?.Mold_Id === props.Mold_Id ? true : false
        }
      />
    );
  }

  const rowSelected = (args) => {

    // console.log(args)

    sessionStorage.setItem("SelectedMoldData", JSON.stringify([{
      id: args.id,
      Mold_Id: args.Mold_Id,
    }]))

    // Set selected mold data
    setSelectedMoldData([
      {
        id: args.id,
        Mold_Id: args.Mold_Id,
      },
    ]);

  };

  const SelectMold = () => {

    FetchSessions()

    closeDialog()

  }

  // Create Mold Functions
  // These are the states which deal with Part data details for storing and editing
  const [PartData, setPartData] = useState(row_data);
  const [editFormData, setEditFormData] = useState();
  const [isPartId, setIsPartId] = useState(null);
  const [partColumn, setpartColumn] = useState(column_data);
  const [PartNumber, setPartNumber] = useState(1);

  const [Platen, setPlaten] = useState(false);
  const [FamilyMold, setFamilyMold] = useState(false);

  // These are the state's which store the Mold's created by the user.
  const [MoldData, setMoldData] = useState(StoredMoldData);

  // An Local Object to store the Mold Data which is stored in the Above Mold Array.
  const [addMoldData, setAddMoldData] = useState({
    Mold_Id: "",
    Platen_Orientation: "",
    Number_Of_Bases: "",
    Is_This_A_New_Mold: "",
    Number_Of_Parts: "",
  });

  let UnitsData = {
    Mold_No: {
      value: addMoldData.Mold_Id,
      unit_id: "",
    },
    Material_Id: {
      value: "",
      unit_id: "",
    },
    Platen_Orientation: {
      value: addMoldData.Platen_Orientation
        ? addMoldData.Platen_Orientation
        : "Horizontal",
      unit_id: "",
    },
    Number_of_Bases: {
      value: addMoldData.Number_Of_Bases ? addMoldData.Number_Of_Bases : "1",
      unit_id: "",
    },
    Is_This_A_New_Mold: {
      value: addMoldData.Is_This_A_New_Mold
        ? addMoldData.Is_This_A_New_Mold
        : "No",
      unit_id: "",
    },
    Number_Of_Parts: {
      value: addMoldData.Number_Of_Parts ? addMoldData.Number_Of_Parts : "1",
      unit_id: "",
    },
    Hot_Runner_Volume: {
      value: "",
      unit_id: "",
    },
    Cycle_Time: {
      value: "",
      unit_id: "",
    },
    Mold_Stack_Height: {
      value: "",
      unit_id: "",
    },
    Mold_Vertical_Height: {
      value: "",
      unit_id: "",
    },
    Req_Mold_Open_Stroke: {
      value: "",
      unit_id: "",
    },
    Mold_Width: {
      value: "",
      unit_id: "",
    },
    Number_of_Core_Pulls: {
      value: "",
      unit_id: "",
    },
    Part_Description: {
      value: "",
      unit_id: "",
    },
    Part_Number: {
      value: "",
      unit_id: "",
    },
    No_of_Cavities: {
      value: "",
      unit_id: "",
    },
    Starting_Cavity_Number: {
      value: "",
      unit_id: "",
    },
    Weight_of_one_Part: {
      value: "",
      unit_id: "",
    },
    Number_Of_Runners: {
      value: "",
      unit_id: "",
    },
    Runner_Weight: {
      value: "",
      unit_id: "",
    },
    Part_Projected_Area: {
      value: "",
      unit_id: "",
    },
    Runner_Projected_Area: {
      value: "",
      unit_id: "",
    },
  };

  const ToggleModalStates = (ModalKey) => {
    setModalStates({
      ...ModalStates,
      [ModalKey]: {
        ...ModalStates[ModalKey],
        visibility: !ModalStates[ModalKey].visibility,
      },
    });
  };

  // Add Column's to the part
  const setPart = () => {

    let col = [
      {
        id: 0,
        Part: "",
        edit: false,
        delete: false,
      },
    ];

    for (let i = 0; i < parseInt(PartNumber); i++) {
      col.push({
        id: nanoid(),
        Part_No: `Part${i + 1}`,
        delete: true,
      });
    }

    setpartColumn([...col])

  };

  // The event to store the Mold Data into the local Object.
  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addMoldData };
    newFormData[fieldName] = fieldValue;

    setAddMoldData(newFormData);
  };

  const CheckForDuplicateMoldId = () => {
    return new Promise((resolve, reject) => {
      let MoldIdExists;

      MoldIdExists = StoredMoldData.some(
        (mold) =>
          mold.Mold_Id.toLowerCase() === addMoldData["Mold_Id"].toLowerCase()
      );

      if (!MoldIdExists) {
        resolve();
      } else {
        ToggleModalStates("MoldIdUnique");
      }
    });
  };

  // This Event store's the Local Mold Object in the main Mold Data array.
  const handleAddFormSubmit = (event) => {

    event.preventDefault();

    // console.log(PartData)

    if (!addMoldData.Mold_Id) {
      toast("Please Enter Mold Data", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!PartNumber) {
      toast("Please Enter No. of Parts", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!PartData[0].Part1) {
      alert("All the fields are mandatory in part table.");
    } else if (!PartData[1].Part1) {
      alert("All the fields are mandatory in part table.");
    } else if (!PartData[2].Part1) {
      alert("All the fields are mandatory in part table.");
    } else if (!PartData[3].Part1) {
      alert("All the fields are mandatory in part table.");
    } else {
      const newMold = {
        Mold_Id: addMoldData.Mold_Id,
        Platen_Orientation: addMoldData.Platen_Orientation
          ? addMoldData.Platen_Orientation
          : "Horizontal",
        Number_Of_Bases: addMoldData.Number_Of_Bases
          ? addMoldData.Number_Of_Bases
          : 1,
        Is_This_A_New_Mold: addMoldData.Is_This_A_New_Mold
          ? addMoldData.Is_This_A_New_Mold
          : "No",
        Number_Of_Parts: addMoldData.Number_Of_Parts
          ? addMoldData.Number_Of_Parts
          : 1,
        Mold_Data: {
          Material_Id: "",
          Hot_Runner_Volume: "",
          Cycle_Time: "",
          Mold_Stack_Height: "",
          Mold_Vertical_Height: "",
          Req_Mold_Open_Stroke: "",
          Mold_Width: "",
          Number_of_Core_Pulls: "",
        },
        Part_Details: partColumn ? partColumn : "",
        Part_Data: PartData ? PartData : "",
        Units: UnitsData,
        user: user.id,
      };

      // console.log(newMold);

      // There is an Api call inside DataService method name as SaveMold using which we are saving mold into the DB.
      CheckForDuplicateMoldId(newMold).then(() => {
        DataService.SaveMold(newMold)
          .then((res) => {
            if (res.data.message) {
              // console.log(res.data.message)

              toast("Please enter valid data", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast("Mold Has Been Created", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              setMoldData([...MoldData, res.data]);

              setSelectedMoldData(
                [
                  {
                    id: res.data.id,
                    Mold_Id: res.data.Mold_Id,
                  },
                ]
              )

              closeDialog()

              addMoldData.Mold_Id = "";

              setpartColumn(column_data);

              setPartData(row_data);

              setPartNumber(1);

              if (Platen === true) {
                setPlaten(false);
              }

              if (FamilyMold === true) {
                setFamilyMold(false);
              }

              TogglCreatetMoldState();
            }
          })
          .catch((err) => { });
      });
    }
  }

  // Now these are the event's which deal with the part detail's
  // There is an Local Object 'editFormData' to store the Part Data which is stored in the PartData Array.
  const handleEditPartChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditPartSubmit = (event) => {
    // console.log(PartData)

    event.preventDefault();

    const editedValue = { id: isPartId };

    const newObject = Object.assign(editedValue, editFormData);

    const newValues = [...PartData];

    const index = PartData.findIndex((value) => value.id === isPartId);

    newValues[index] = newObject;

    setPartData(newValues);

    setIsPartId(null);
  };

  const setPartId = (event, value) => {
    event.preventDefault();

    setIsPartId(value.id);

    const formValues = Object.assign({}, value);

    setEditFormData(formValues);
  };

  return (
    <>

      <Modal
        isOpen={ModalStates.MoldIdUnique.visibility}
        centered={true}
        style={{ width: "600px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.MoldIdUnique.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("MoldIdUnique")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <CreateMoldForm ModalStates={ModalStates}
        TogglCreatetMoldState={TogglCreatetMoldState}
        handleEditPartSubmit={handleEditPartSubmit}
        handleEditPartChange={handleEditPartChange}
        handleAddFormChange={handleAddFormChange}
        handleAddFormSubmit={handleAddFormSubmit}
        PartData={PartData}
        setPartId={setPartId}
        isPartId={isPartId}
        setPartNumber={setPartNumber}
        PartNumber={PartNumber}
        setPart={setPart}
        partColumn={partColumn}
        setpartColumn={setpartColumn}
        setPartData={setPartData}
        addMoldData={addMoldData}
        Platen={Platen}
        setPlaten={setPlaten}
        FamilyMold={FamilyMold}
        setFamilyMold={setFamilyMold} />

      <div id="dialogBoxContainer">
        <div
          id="dialogBox"
          className="hidden"
          style={{ width: "35vw", border: "2px solid black" }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <h5> Select Mold </h5>
            </div>

            <div>
              <div className="text-left">
                <button
                  className="btn btn-warning btn-air-warning mr-2"
                  onClick={closeDialog}
                >
                  {"X"}
                </button>
              </div>
            </div>
          </div>

          <hr></hr>
          <div className="dialog-content">
            <div className="d-flex justify-content-center align-items-center">

              <div className="m-2" style={{ border: "1px solid black" }}>
                <GridComponent
                  ref={(g) => (grid = g)}
                  id="grid2"
                  toolbar={toolbar}
                  dataSource={MoldData}
                  allowPaging={true}
                  pageSettings={{ pageSize: 10 }}
                  columns={MoldColumns}
                  selectionSettings={selectionSettings}
                  height={200}
                >
                  <Inject services={[Toolbar, Page]} />
                </GridComponent>
              </div>

              <span style={{ padding: "0 10px", fontWeight: "bold" }}>OR</span>

              <div className="p-2">
                <button className="btn btn-primary btn-air-warning" onClick={TogglCreatetMoldState}>
                  Create New Mold
                </button>
              </div>
            </div>

            {/* Centering the button */}
            <div className="d-flex justify-content-left">

              <div>
                <div className="text-left">
                  <button
                    className="btn btn-warning btn-air-warning m-2"
                    onClick={SelectMold}
                  >
                    {"Select"}
                  </button>
                </div>
              </div>
              <div>
                <div className="text-left">
                  <button
                    className="btn btn-warning btn-air-warning m-2"
                    onClick={closeDialog}
                  >
                    {"Close"}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(SelectMold);
