import React, { useState } from "react";
import Header2 from "../../common/header-component/header2";
// CSS
import "../../App.css";

// To generate random Id
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { connect } from "react-redux";
import DataService from "../../../services/ApiService";

import { useParams, useHistory } from "react-router-dom";

import ConvertCalcfunctions from "./ConvertInputFields";

import { CalculateAverage } from "../ConversionFunctions";
import LeftMaterialForm from "./LeftMaterialForm";
import RightMaterialForm from "./RightMaterialForm";
import CustomFieldsTable from "./CustomFieldsTable";
import BreadCrumb from "../CommonSections/BreadCrumb";
import AlertModals from "../CommonSections/AlertModals";

import ButtonSection from "./ButtonSection";
import AddandEditCustomHeader from "./AddandEditCustomHeader";

const MaterialEdit = ({ user }) => {
  const history = useHistory();

  var { RowId } = useParams();

  const [EditModal, setEditModal] = useState(false);

  const [SelectedRowId, setSelectedRowId] = useState(null);

  const [header, setHeader] = useState({
    header: "",
    key: null,
  });

  const [column, setColumn] = useState([]);

  const [DuplicateValue, setDuplicatetValue] = useState(false);

  const [AllSelectedMaterialData, setAllSelectedMaterialData] = useState([]);

  const [EmptyAlert, setEmptyAlert] = useState(false);

  const [isColumnId, setIsColumnId] = useState(null);

  const [FieldsExists, setFieldsExists] = useState(false);

  const [FieldsDeleted, setFieldsDeleted] = useState(false);

  const [isDeleteId, setIsDeleteId] = useState(null);

  const [SamePage, setSamePage] = useState(false);

  const [UnitSettings, setUnitSettings] = useState({
    MeltTempUnit: {},
    MoldTempUnit: {},
    DryingTempUnit: {},
    TimeUnit: {},
  });

  const [BaseUnitsArray, setBaseUnitsArray] = useState([]);

  const [UserSelectedUnits, setUserSelectedUnits] = useState([]);

  const [SelectedPart, setSelectedPart] = useState("Melt");

  const [SelectedUnitsArray, setSelectedUnitsArray] = useState([]);

  // State and Event for the Edit and Add Modal
  const [modal, setModal] = useState();

  const toggleEditHeader = () => {
    setModal(!modal);
  };

  const [ModalStates, setModalStates] = useState({
    MaterialIdConfirm: {
      visibility: false,
      message: "Material Id is mandatory.",
    },
    DeleteConfirm: {
      visibility: false,
      message:
        "The field may have data associated with it. Do you want to continue deleting the same ?",
    },
    MaterialIdUnique: {
      visibility: false,
      message: "Material Id should be unique. This Material Id already exists.",
    },
    ConvertConfirm: {
      visibility: false,
      message:
        "You are changing the units, do you want to change the value accordingly ?",
    },
  });

  const ToggleModalStates = (ModalKey) => {
    setModalStates({
      ...ModalStates,
      [ModalKey]: {
        ...ModalStates[ModalKey],
        visibility: !ModalStates[ModalKey].visibility,
      },
    });
  };

  const [SelectedMaterialData, setSelectedMaterialData] = useState({
    Material_Id: "",
    Base_Resin: "",
    Manufacturer: "",
    Specific_Gravity: "",
    Min_Melt_Temperature: "",
    Max_Melt_Temperature: "",
    Avg_Melt_Temperature: "",
    Min_Mold_Temperature: "",
    Max_Mold_Temperature: "",
    Avg_Mold_Temperature: "",
    Drying_Temperature: "",
    Drying_Time_Min: "",
    Drying_Time_Max: "",
    Max_Residence_Time: "",
  });

  const [SelectedMaterialsUnitData, setSelectedMaterialsUnitData] = useState({
    Material_Id: "",
    Base_Resin: "",
    Manufacturer: "",
    Specific_Gravity: "",
    Min_Melt_Temperature: "",
    Max_Melt_Temperature: "",
    Avg_Melt_Temperature: "",
    Min_Mold_Temperature: "",
    Max_Mold_Temperature: "",
    Avg_Mold_Temperature: "",
    Drying_Temperature: "",
    Drying_Time_Min: "",
    Drying_Time_Max: "",
    Max_Residence_Time: "",
  });

  let BaseUnits = JSON.parse(sessionStorage.getItem("StoredUnits")) || [];
  let SelectedUnits = JSON.parse(sessionStorage.getItem("SelectedUnits")) || [];
  let StoredMaterialData =
    JSON.parse(sessionStorage.getItem("MaterialData")) || [];

  const [StoredUnits, setStoredUnits] = useState([]);

  const [MaterialId, setMaterialId] = useState([]);

  const [SelectedMaterialId, setSelectedMaterialId] = useState("");

  const handleChange = (event) => {
    const { name, value, dataset } = event.target;

    setSelectedMaterialData({
      ...SelectedMaterialData,
      [name]: value,
    });

    let data = {
      value: value,
      unit_id: dataset.unit ? dataset.unit : null,
    };

    setSelectedMaterialsUnitData(
      dataset.category === "Melt"
        ? {
          ...SelectedMaterialsUnitData,
          [name]: data,
          Max_Melt_Temperature: {
            ...SelectedMaterialsUnitData.Max_Melt_Temperature,
            unit_id: dataset.unit,
          },
          Avg_Melt_Temperature: {
            ...SelectedMaterialsUnitData.Avg_Melt_Temperature,
            unit_id: dataset.unit,
          },
        }
        : dataset.category === "Mold"
          ? {
            ...SelectedMaterialsUnitData,
            [name]: data,
            Max_Mold_Temperature: {
              ...SelectedMaterialsUnitData.Max_Mold_Temperature,
              unit_id: dataset.unit,
            },
            Avg_Mold_Temperature: {
              ...SelectedMaterialsUnitData.Avg_Mold_Temperature,
              unit_id: dataset.unit,
            },
          }
          : {
            ...SelectedMaterialsUnitData,
            [name]: data,
          }
    );
  };

  const handleDropDownChange = (event) => {
    const { name, value, dataset } = event.target;

    setSelectedPart(dataset.category);

    setUnitSettings({
      ...UnitSettings,
      [name]: {
        unit_name: StoredUnits.find((unit) => unit.unit_id === parseInt(value))
          .unit_name,
        unit_id: StoredUnits.find((unit) => unit.unit_id === parseInt(value))
          .unit_id,
        decimals: SelectedUnitsArray.find(
          (unit) => unit.unit_id === parseInt(value)
        )
          ? SelectedUnitsArray.find((unit) => unit.unit_id === parseInt(value))
            .decimals
          : StoredUnits.find((unit) => unit.unit_id === parseInt(value))
            .decimals,
      },
    });

    let data = {
      value: SelectedMaterialData[dataset.fieldname],
      unit_id: value ? value : null,
    };

    ToggleModalStates("ConvertConfirm");

    setSelectedMaterialsUnitData(
      dataset.category === "Melt"
        ? {
          ...SelectedMaterialsUnitData,
          [dataset.fieldname]: data,
          Max_Melt_Temperature: {
            ...SelectedMaterialsUnitData.Max_Melt_Temperature,
            unit_id: value,
          },
          Avg_Melt_Temperature: {
            ...SelectedMaterialsUnitData.Avg_Melt_Temperature,
            unit_id: value,
          },
        }
        : dataset.category === "Mold"
          ? {
            ...SelectedMaterialsUnitData,
            [dataset.fieldname]: data,
            Max_Mold_Temperature: {
              ...SelectedMaterialsUnitData.Max_Mold_Temperature,
              unit_id: value,
            },
            Avg_Mold_Temperature: {
              ...SelectedMaterialsUnitData.Avg_Mold_Temperature,
              unit_id: value,
            },
          }
          : {
            ...SelectedMaterialsUnitData,
            [dataset.fieldname]: data,
          }
    );
  };

  const UpdateInputConversions = () => {
    if (SelectedPart === "Drying") {
      let unit = UnitSettings[`${SelectedPart}TempUnit`].unit_id;

      let UpdatedValues = ConvertCalcfunctions.ConvertInputFields({
        UnitSettings,
        materialData: SelectedMaterialData,
        SelectedPart,
      });

      setSelectedMaterialData({
        ...SelectedMaterialData,
        ...UpdatedValues,
      });

      setSelectedMaterialsUnitData({
        ...SelectedMaterialsUnitData,
        ["Drying_Temperature"]: {
          value: UpdatedValues["Drying_Temperature"],
          unit_id: unit,
        },
      });

      ToggleModalStates("ConvertConfirm");
    } else {
      let DataKeys = {
        Min: `Min_${SelectedPart}_Temperature`,
        Max: `Max_${SelectedPart}_Temperature`,
        Avg: `Avg_${SelectedPart}_Temperature`,
      };

      let UpdatedValues = ConvertCalcfunctions.ConvertInputFields({
        UnitSettings,
        materialData: SelectedMaterialData,
        SelectedPart,
      });

      function countDecimalPlaces(number) {
        const numberStr = number.toString();
        if (numberStr.includes(".")) {
          return numberStr.split(".")[1].length;
        } else {
          return 0;
        }
      }

      let Decimals = countDecimalPlaces(
        UnitSettings[`${SelectedPart}TempUnit`].decimals
      );

      let unit = UnitSettings[`${SelectedPart}TempUnit`].unit_id;

      setSelectedMaterialData({
        ...SelectedMaterialData,
        ...UpdatedValues,
        [DataKeys.Avg]: CalculateAverage(
          UpdatedValues[DataKeys.Min],
          UpdatedValues[DataKeys.Max],
          Decimals
        ),
      });

      setSelectedMaterialsUnitData({
        ...SelectedMaterialsUnitData,
        [DataKeys.Min]: {
          value: UpdatedValues[DataKeys.Min],
          unit_id: unit,
        },
        [DataKeys.Max]: {
          value: UpdatedValues[DataKeys.Max],
          unit_id: unit,
        },
        [DataKeys.Avg]: {
          value: CalculateAverage(
            UpdatedValues[DataKeys.Min],
            UpdatedValues[DataKeys.Max],
            Decimals
          ),
          unit_id: unit,
        },
      });

      ToggleModalStates("ConvertConfirm");
    }
  };

  const UpdateInputCalculations = (category) => {
    ConvertCalcfunctions.UpdateCalculations({
      UnitSettings,
      category,
      SelectedMaterialData,
      setSelectedMaterialData,
      setSelectedMaterialsUnitData,
      SelectedMaterialsUnitData,
    });
  };

  useEffect(() => {
    // console.log(SelectedMaterialsUnitData);
  }, [SelectedMaterialData]);

  const SaveData = () => {
    let UpdateData = {
      user: user.id,
      Material_Data: SelectedMaterialData,
      Units: SelectedMaterialsUnitData,
    };

    // console.log(UpdateData);

    DataService.UpdateMaterialData(SelectedRowId, UpdateData).then((res) => {
      // console.log(res)

      if (SamePage) {
        history.push({
          pathname: `/database/Options/${RowId}/MaterialView`,
        });
      } else {
        history.push({
          pathname: "/database/Database",
        });
      }
    });

    let ColumnData;

    if (!FieldsExists) {
      ColumnData = {
        user: user.id,
        field_to_be_updated: "Material",
        Material_Custom_Fields: column,
        Mold_Custom_Fields: [],
        Machine_Custom_Fields: [],
      };

      DataService.SaveFieldsData(ColumnData).then((res) => { });
    } else {
      // console.log(FieldsExists);

      ColumnData = {
        user: user.id,
        field_to_be_updated: "Material",
        Material_Custom_Fields: column,
        Mold_Custom_Fields: [],
        Machine_Custom_Fields: [],
      };

      // console.log(ColumnData);

      DataService.UpdateFieldsData(user.id, ColumnData).then((res) => { });
    }

    if (FieldsDeleted) {
      let SelectedMaterialData = {
        Material_Data: AllSelectedMaterialData,
      };

      // console.log(SelectedMaterialData);

      DataService.UpdateAllMaterialData(user.id, SelectedMaterialData).then(
        (res) => { }
      );
    }
  };

  const handleSubmit = () => {
    return new Promise((resolve, reject) => {
      let allFieldsFilled = true;

      // console.log(SelectedMaterialData)

      // Check each field of each material object in the array
      if (!SelectedMaterialData["Material_Id"]) {
        allFieldsFilled = false;
      }

      // console.log(SelectedMaterialData)

      if (allFieldsFilled) {
        resolve();
      } else {
        ToggleModalStates("MaterialIdConfirm");
      }
    });
  };

  const CheckForDuplicateMaterialId = () => {
    return new Promise((resolve, reject) => {
      let MaterialIdExists;

      if (isNaN(SelectedMaterialData["Material_Id"])) {

        MaterialIdExists = StoredMaterialData.some(
          (material) =>
            material.Material_Id.toLowerCase() ===
            SelectedMaterialData["Material_Id"].toLowerCase()
        );

      }
      else {

        MaterialIdExists = StoredMaterialData.some(
          (material) =>
            material.Material_Id ===
            SelectedMaterialData["Material_Id"]
        );

      }

      if (!MaterialIdExists) {
        resolve();
      } else {
        ToggleModalStates("MaterialIdUnique");
      }

      // console.log(MaterialIdExists)
    });
  };

  const SubmitData = () => {
    handleSubmit().then(() => {
      if (SelectedMaterialId !== SelectedMaterialData["Material_Id"]) {
        CheckForDuplicateMaterialId().then(() => {
          SaveData();
        });
      } else {
        SaveData();
      }
    });
  };

  const ToggleEditModal = () => {
    setEditModal(!EditModal);

    setHeader({
      header: "",
      key: null,
    });

    setEmptyAlert(false);

    setDuplicatetValue(false);
  };

  const addHeader = (e) => {
    // e.preventDefault();

    setHeader({
      header: e.target.value,
      key: column.length > 0 ? column[column.length - 1].field.slice(5) : 0,
    });

    setEmptyAlert(false);

    setDuplicatetValue(false);
  };

  const CheckForHeaderName = () => {
    return new Promise((resolve, reject) => {
      let HeaderExists;

      // console.log(column);

      if (column.length > 0) {
        HeaderExists = column.some(
          (headers) =>
            headers.headerText?.toLowerCase() === header.header.toLowerCase()
        );
      }

      resolve(HeaderExists);
    });
  };

  const addColumn = () => {
    if (!header) {
      setHeader({
        header: "",
        key: null,
      });

      setEmptyAlert(!EmptyAlert);
    } else {
      CheckForHeaderName().then((HeaderExists) => {
        if (!HeaderExists) {
          const newColumn = {
            id: nanoid(),
            field: `value${parseInt(header.key) + 1}`,
            headerText: header.header,
          };

          // console.log(newColumn);

          setColumn([...column, newColumn]);

          setHeader({
            header: "",
            key: null,
          });

          setEmptyAlert(false);

          setDuplicatetValue(false);

          // console.log(column);
        } else {
          setDuplicatetValue(!DuplicateValue);

          setHeader({
            header: "",
            key: null,
          });
        }
      });
    }
  };

  const editColumn = (data, key) => {
    setIsColumnId({
      data: data,
      key: key,
    });
  };

  const DeleteColumnId = (HeaderData) => {
    setIsDeleteId(HeaderData);
  };

  const editColumnHeader = () => {
    if (header) {
      // console.log(header);

      setColumn(
        column.map((element) => {
          if (element.id === isColumnId.data.id) {
            return { ...element, headerText: header.header };
          }
          return element;
        })
      );

      setHeader({
        header: "",
        key: null,
      });

      // setIsColumnId(null);
    } else {
    }
  };

  const deleteColumn = () => {
    setColumn((prevArray) => {
      const newArray = prevArray.filter((HeaderData) => {
        return HeaderData.id !== isDeleteId.id;
      });

      return newArray;
    });

    const newArray = AllSelectedMaterialData.some((obj) =>
      obj.hasOwnProperty(isDeleteId.field)
    );

    if (newArray) {
      for (let i = 0; i < AllSelectedMaterialData.length; i++) {
        delete AllSelectedMaterialData[i][isDeleteId.field];

        AllSelectedMaterialData[i]["id"] = MaterialId[i];
      }

      setIsDeleteId(null);

      setFieldsDeleted(true);

      // console.log(AllSelectedMaterialData);
    }

    ToggleModalStates("DeleteConfirm");
  };

  const GetData = (id) => {
    DataService.GetMaterialsData(id).then((res) => {
      if (res.data.length > 0) {
        if (SelectedRowId) {
          let TempArray = [];

          for (let i = 0; i < res.data.length; i++) {
            AllSelectedMaterialData.push(
              res.data[i].Material_Data[0] || res.data[i].Material_Data
            );

            // console.log(res.data[i].Material_Data);

            TempArray.push(res.data[i].Material_Id);
          }

          setMaterialId(TempArray);

          const MaterialData = res.data.filter(
            (item) => item.Material_Id === parseInt(SelectedRowId)
          );

          setSelectedMaterialsUnitData(MaterialData[0].Units);

          setSelectedMaterialData(
            MaterialData[0].Material_Data[0] || MaterialData[0].Material_Data
          );

          setSelectedMaterialId(
            MaterialData[0].Material_Data[0]?.Material_Id ||
            MaterialData[0].Material_Data.Material_Id
          );
        }
      } else {
        setAllSelectedMaterialData([]);
      }
    });

    DataService.GetFieldsData(id).then((res) => {
      if (res.data.length > 0) {
        if (res.data[0].Material_Custom_Fields) {
          setColumn(res.data[0].Material_Custom_Fields);

          setFieldsExists(true);
        }
      }
    });
  };

  useEffect(() => {
    if (user) {
      GetData(user.id);
    }
  }, [user, SelectedRowId]);

  useEffect(() => {
    let allUnits = [],
      selectedUnits = [];

    // Iterate through each category in the data
    for (const category in BaseUnits.Unit_Data[0]) {
      // Add each unit object to the allUnits array
      allUnits = allUnits.concat(BaseUnits.Unit_Data[0][category]);
    }

    // Iterate through each category in the data
    for (const category in SelectedUnits.Unit_Data[0]) {
      // Add each unit object to the allUnits array
      selectedUnits = selectedUnits.concat(
        SelectedUnits.Unit_Data[0][category]
      );
    }

    setStoredUnits(allUnits);

    setSelectedUnitsArray(selectedUnits);

    setBaseUnitsArray(BaseUnits.Unit_Data[0]);

    setUserSelectedUnits(SelectedUnits.Unit_Data[0]);

    // console.log(res.data[0].Unit_Data[0]);

    setSelectedRowId(atob(RowId));
    // eslint-disable-next-line
  }, [RowId]);

  useEffect(() => {
    // console.log(StoredUnits);
    // console.log(SelectedUnitsArray);
  }, [StoredUnits]);

  useEffect(() => {
    setUnitSettings({
      ...UnitSettings,
      MeltTempUnit: {
        ...UnitSettings.MeltTempUnit,
        unit_id:
          UserSelectedUnits.Temperature && StoredUnits
            ? StoredUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
            ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Temperature && StoredUnits
            ? StoredUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
            ).unit_name
            : "",
        decimals: UserSelectedUnits.Temperature
          ? UserSelectedUnits.Temperature.decimals
          : 0,
      },

      MoldTempUnit: {
        ...UnitSettings.MoldTempUnit,
        unit_id:
          UserSelectedUnits.Temperature && StoredUnits
            ? StoredUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
            ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Temperature && StoredUnits
            ? StoredUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
            ).unit_name
            : "",
        decimals: UserSelectedUnits.Temperature
          ? UserSelectedUnits.Temperature.decimals
          : 0,
      },

      DryingTempUnit: {
        ...UnitSettings.MoldTempUnit,
        unit_id:
          UserSelectedUnits.Temperature && StoredUnits
            ? StoredUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
            ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Temperature && StoredUnits
            ? StoredUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
            ).unit_name
            : "",
        decimals: UserSelectedUnits.Temperature
          ? UserSelectedUnits.Temperature.decimals
          : 0,
      },
      TimeUnit:
        UserSelectedUnits.Time && StoredUnits
          ? StoredUnits.find(
            (unit) => unit.unit_id === UserSelectedUnits.Time.unit_id
          )
          : "",
    });
    // console.log(UserSelectedUnits)
    // eslint-disable-next-line
  }, [UserSelectedUnits]);

  return (
    <>
      <AlertModals
        ModalStates={ModalStates}
        ToggleModalStates={ToggleModalStates}
        UpdateInputConversions={UpdateInputConversions}
        deleteColumn={deleteColumn}
      />

      <Header2 Title="Database" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <BreadCrumb DB_Name={"Material"} Current_Page={"Edit"} />
        </div>

        <div className="container-fluid">
          <div className="card p-3  ml-2">
            <ButtonSection
              SubmitData={SubmitData}
              history={history}
              SamePage={SamePage}
              setSamePage={setSamePage}
              Page={"Edit"}
            />

            <div className="d-flex col-md-12">
              <div className="col-md-4">
                <div className="form-inline">
                  <LeftMaterialForm
                    SelectedMaterialsUnitData={SelectedMaterialsUnitData}
                    handleChange={handleChange}
                    handleDropDownChange={handleDropDownChange}
                    UpdateInputCalculations={UpdateInputCalculations}
                    StoredUnits={StoredUnits}
                    BaseUnitsArray={BaseUnitsArray}
                  />
                </div>
              </div>

              <div className="ml-1  col-md-4">
                <div className="form-inline">
                  <RightMaterialForm
                    SelectedMaterialsUnitData={SelectedMaterialsUnitData}
                    handleChange={handleChange}
                    handleDropDownChange={handleDropDownChange}
                    UpdateInputCalculations={UpdateInputCalculations}
                    StoredUnits={StoredUnits}
                    BaseUnitsArray={BaseUnitsArray}
                  />
                </div>
              </div>

              <div className="ml-3 col-md-4">
                <CustomFieldsTable
                  column={column}
                  handleChange={handleChange}
                  SelectedMaterialData={SelectedMaterialData}
                  ToggleEditModal={ToggleEditModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <AddandEditCustomHeader
          EditModal={EditModal}
          EmptyAlert={EmptyAlert}
          DuplicateValue={DuplicateValue}
          addHeader={addHeader}
          header={header}
          addColumn={addColumn}
          column={column}
          editColumnHeader={editColumnHeader}
          editColumn={editColumn}
          DeleteColumnId={DeleteColumnId}
          toggleEditHeader={toggleEditHeader}
          ToggleModalStates={ToggleModalStates}
          ToggleEditModal={ToggleEditModal}
          modal={modal}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MaterialEdit);
