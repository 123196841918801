import React, { useState, useRef, useEffect } from "react";
// import { nanoid } from 'nanoid'
import { Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useParams } from "react-router-dom";
// Hook to get the parameter's from the URL
// Syncfusion chart control
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import AddRow from "./AddRow";
import { getCell } from "@syncfusion/ej2-react-spreadsheet";
import PostMoldGrid from "./PostMoldGrid";
// import AddColumn from './AddColumn';
import EditHeader from "./EditHeader";
import Loader from "../../../assets/Loader";
import DataService from "../../../services/ApiService";
import { toast } from "react-toastify";

const PostMold = ({ showAlert, ToSavePostMoldData, PostMoldData }) => {
  let PostMoldSpreadsheet = useRef();

  // // To store the session Id getting from URL
  const [SessionId, setSessionId] = useState();

  // // Getting session Id from URL
  var { sessionId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  // State and Event for the Edit and Add Modal
  const [modal, setModal] = useState();

  const toggle = () => {
    setModal(!modal);

    PostMoldSpreadsheet.current.refresh();
  };

  // To store column header data
  const [column, setColumn] = useState([
    {
      id: 0,
      header: "Time",
    },
    {
      id: 1,
      header: "Time",
    },
    {
      id: 2,
      header: "Cavity 1",
    },
    {
      id: 3,
      header: "Cavity 2",
    },
    {
      id: 4,
      header: "Cavity 3",
    },
    {
      id: 5,
      header: "Cavity 4",
    },
    {
      id: 6,
      header: "Cavity 5",
    },
    {
      id: 7,
      header: "Cavity 6",
    },
    {
      id: 8,
      header: "Cavity 7",
    },
    {
      id: 9,
      header: "Cavity 8",
    },
    {
      id: 10,
      header: "Cavity 9",
    },
    {
      id: 11,
      header: "Cavity 10",
    },
  ]);

  const [header, setHeader] = useState();

  const [PostMoldGridData, setPostMoldGridData] = useState([]);

  const [toggleEdit, setToggleEdit] = useState(true);

  const [isColumnId, setIsColumnId] = useState(null);

  const [ColToBeDeleted, setColToBeDeleted] = useState();

  const [grid2, setGrid2] = useState(null);

  const [GridHeader, setGridHeader] = useState("");

  let [colCount, setColCount] = useState(column.length);

  const [ColHeader, seColHeader] = useState("");

  const [args, setArgs] = useState("");

  const PopulateMoldSheet = (data) => {
    // console.log(data)

    const alphabetArray = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    for (let j = 1; j <= column.length; j++) {
      for (let i = 0; i < data.length; i++) {
        PostMoldSpreadsheet.current.updateCell(
          { value: data[i][`value${j - 1}`] },
          `${alphabetArray[j - 1]}${i + 1}`
        );

        // console.log(data[i][`value${j - 1}`], `${alphabetArray[j - 1]}${i}`)
      }
    }

    // console.log(data)
  };

  const RenderHeaders = () => {
    // Condition to check whether the rendered element is header cell.
    for (let i = 0; i < column.length; i++) {
      if (
        args.colIndex === i &&
        args.element.classList.contains("e-header-cell")
      ) {
        const text = column[i].header;
        args.element.innerText = text;
      }
    }
  };

  const addHeader = (e) => {
    e.preventDefault();

    setHeader(e.target.value);
  };

  // const addColumn = () => {

  //     if (!header) {

  //     }
  //     else {

  //         const newColumn = { id: nanoid(), "header": header }

  //         setColumn([...column, newColumn]);

  //         setColCount(colCount + 1)

  //         PopulateMoldSheet(PostMoldGridData)

  //         setHeader("");

  //     }

  // };

  const editColumnHeader = () => {
    if (header && !toggleEdit) {
      setColumn(
        column.map((element) => {
          if (element.id === isColumnId) {
            return { ...element, header: header };
          }
          return element;
        })
      );

      RenderHeaders();

      setHeader("");
    } else {
    }
  };

  const editColumn = (id) => {
    setIsColumnId(id);
    setToggleEdit(false);
  };

  const editCancel = () => {
    setIsColumnId(null);
    setToggleEdit(true);
  };

  // State to Toggle the Delete Confirmation Modal.
  const [DeleteAlertToggle, setDeleteAlertToggle] = useState(false);
  const [DeleteConfirmToggle, setDeleteConfirmToggle] = useState(false);

  const DeleteModalAlertToggle = () => {
    setDeleteAlertToggle(!DeleteAlertToggle);
  };

  const DeleteModalConfirmToggle = () => {
    if (!ColToBeDeleted || column.length <= 2) {
      setDeleteAlertToggle(!DeleteAlertToggle);
    } else {
      setDeleteConfirmToggle(!DeleteConfirmToggle);
      seColHeader(column[ColToBeDeleted].header);
    }
  };

  const getDatafromsheet = () => {
    let dataArray = [],
      dataObj = {};

    for (let i = 0; i < PostMoldGridData.length; i++) {
      for (let j = 0; j < column.length; j++) {
        dataObj[`value${j}`] = !getCell(
          i,
          j,
          PostMoldSpreadsheet.current.getActiveSheet()
        ).value
          ? ""
          : getCell(i, j, PostMoldSpreadsheet.current.getActiveSheet()).value;
      }

      dataArray.push(dataObj);

      dataObj = {};
    }

    setPostMoldGridData(dataArray);

    // console.log(dataArray)
  };

  const deleteColumn = () => {
    PostMoldSpreadsheet.current.delete(
      ColToBeDeleted,
      ColToBeDeleted,
      "Column"
    );

    setColumn((prevArray) => {
      const newArray = [...prevArray];

      newArray.splice(ColToBeDeleted, 1);

      return newArray;
    });

    for (let j = 1; j < column.length; j++) {
      for (let i = 0; i < PostMoldGridData.length; i++) {
        delete PostMoldGridData[i][`value${ColToBeDeleted}`];
        // console.log(PostMoldGridData[i][`value${ColToBeDeleted}`])
      }
    }

    getDatafromsheet();

    PostMoldSpreadsheet.current.refresh();

    setDeleteConfirmToggle(!DeleteConfirmToggle);
  };

  const [rowCount, setRowCount] = useState(50);

  const [rowToBeDeleted, setRowToBeDeleted] = useState();

  // Boolean variable to switch between the save and update button
  const [showSave, setShowSave] = useState(true);

  // As the user enter's the number of row's it get's set in this variable.
  const [row, setRow] = useState(2);

  // Set's the visibility of the modal which we use to get the number of row's to be generated which is imported in Grid.
  const [ScrewAddRowModal, setScrewAddRowModal] = useState();

  const ToggleAddRowModal = () => {
    setScrewAddRowModal(!ScrewAddRowModal);
  };

  const addRow = (e) => {
    e.preventDefault();

    // Storing the number entered
    setRow(e.target.value);
    // console.log(e.target.value);
  };

  // This is the event which gets called as the user click's ok in the add row modal.
  // what it does is it run's a loop as many times the row variable is and along with that it pushes an object containing all the key's based on the grid with an id generated using nanoid library and then set's the row1 in the main array i.e ScrewRotationGridData.
  const increaseRow = () => {
    // Updating the total rows variable
    setRowCount(parseInt(rowCount) + parseInt(row));

    setRow(null);
  };

  // This is the event which deletes the row as clicked on the delete icon, id of the row gets passed and using filter method that row is filtered out.
  // const deleteRow2 = (id) => {

  //     PostMoldSpreadsheet.current.delete(rowToBeDeleted, rowToBeDeleted, 'Row', 0);

  //     setRowCount(parseInt(rowCount) - 1)

  //     PostMoldGridData.splice(rowToBeDeleted, 1)

  //     // console.log(PostMoldGridData)

  // };

  const saveData = () => {
    const data = {
      session: SessionId,
      Column_Data: PostMoldGridData,
    };

    // console.log(data)

    DataService.SavePostMoldData(data)
      .then((res) => {
        // console.log(res)

        setShowSave(false);

        toast("Data has been saved", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {});
  };

  // Event to Call the PUT request API and update the data
  const UpdateData = () => {
    const data = {
      session: SessionId,
      Column_Data: PostMoldGridData,
    };

    // console.log(data)

    DataService.UpdatePostMoldData(SessionId, data)
      .then((res) => {
        // console.log(res)
        setShowSave(false);

        toast("Data has been saved", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const data = {
      session: SessionId,
      Column_Data: PostMoldGridData,
    };

    // console.log(data)

    showAlert.current = true;

    PostMoldData.current = data;

    // eslint-disable-next-line
  }, [PostMoldGridData]);

  const GetData = (SessionId) => {
    DataService.GetPostMoldData(SessionId)
      .then((res) => {
        // console.log(res)

        if (res.data[0]) {
          ToSavePostMoldData.current = false;

          setPostMoldGridData(res.data[0].Column_Data);

          PopulateMoldSheet(res.data[0].Column_Data);

          // console.log(res.data[0].Column_Data.length)

          setShowSave(false);
        } else {
        }
      })
      .catch((err) => {});
  };

  function calculateNiceNumbers(min, max, numTicks) {
    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
      niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
      niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
      niceStep = 5 * magnitudePow;
    } else {
      niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from(
      { length: numNiceTicks },
      (_, i) => niceMin + i * niceStep
    );

    return {
      min: niceMin,
      max: niceMax,
      step: niceStep,
      ticks: niceTicks,
    };
  }

  const [TimeniceNumbers, setTimeNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const [WeightniceNumbers, setWeightNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const [chartData, setChartData] = useState([]);

  // Event to set the Mix, Max and Interval of graph i.e scalling the graph
  const scaleGraph = () => {
    let YArray = [],
      timeArray = [];

    // This is the event to sort the data based on Time
    const compareValue = (a, b) => {
      return a.minutes - b.minutes;
    };

    // This is the event to sort the data based on Weight
    const compareValue2 = (a, b) => {
      return a[`${grid2}`] - b[`${grid2}`];
    };

    for (let i = 0; i < PostMoldGridData.length; i++) {
      if (PostMoldGridData[i].value0) {
        YArray.push(PostMoldGridData[i]);
        timeArray.push(PostMoldGridData[i]);
      } else {
      }
    }

    timeArray.sort(compareValue);
    YArray.sort(compareValue2);

    setChartData(timeArray);

    setTimeNiceNumbers(
      calculateNiceNumbers(
        timeArray[2].minutes,
        timeArray[timeArray.length - 1].minutes,
        5
      )
    );

    if (grid2) {
      setWeightNiceNumbers(
        calculateNiceNumbers(
          YArray[2][`${grid2}`],
          YArray[YArray.length - 1][`${grid2}`],
          5
        )
      );
    }
  };

  const setGrid = (e) => {
    if (e.target.value) {
      var val = document.getElementById("Cold-Column");

      var Header = val.options[val.selectedIndex].text;

      setGrid2(`value${e.target.value}`);

      if (Header != "Select Cavity") setGridHeader(Header);

      setChartData([]);
    }
  };

  useEffect(() => {
    // Simulate loading for a few seconds (you can replace this with your actual loading logic)
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  // // Event that will be called as soon as the Page load's so that if there is data available will get fetched
  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));

    if (SessionId) {
      GetData(SessionId);
    } else {
    }

    // console.log(SessionId)

    // eslint-disable-next-line
  }, [sessionId, SessionId]);

  return (
    <div>
      {isLoading ? <Loader /> : <></>}

      <div>
        <Modal isOpen={DeleteAlertToggle} centered={true}>
          <ModalHeader toggle={DeleteModalAlertToggle}>
            Delete Alert.
          </ModalHeader>

          <ModalBody>
            {column.length > 2
              ? "Select a column to delete"
              : "Atleast one column is mandatory."}
          </ModalBody>

          <ModalFooter>
            <Button color="dark" onClick={DeleteModalAlertToggle}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={DeleteConfirmToggle} centered={true}>
          <ModalHeader toggle={DeleteModalConfirmToggle}>
            Delete Confirmation.
          </ModalHeader>

          <ModalBody>
            {ColHeader
              ? `Are you sure you want to delete ${ColHeader} column ?`
              : ""}
          </ModalBody>

          <ModalFooter>
            <Button color="dark" onClick={deleteColumn}>
              {" "}
              Delete{" "}
            </Button>
            <Button color="dark" onClick={DeleteModalConfirmToggle}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div className="ml-3 card" style={{ border: "1px solid #573DAC" }}>
        <div className="m-2 d-flex justify-content-between">
          <div className="mb-1 d-flex justify-content-between">
            <div className="d-flex">
              {/* <div className="grid_container_btn">
                                    <AddColumn toggle2={toggle2} modal2={modal2} addHeader={addHeader} addColumn={addColumn} />
                                </div> */}
              <div className="grid_container_btn">
                <EditHeader
                  modal={modal}
                  toggle={toggle}
                  column={column}
                  addHeader={addHeader}
                  editColumnHeader={editColumnHeader}
                  editCancel={editCancel}
                  editColumn={editColumn}
                />
              </div>
              {/* <div>
                                    <button className="btn btn-primary btn-air-primary mt-3 mr-2" type="button" onClick={DeleteModalConfirmToggle}> Delete Column </button>
                                </div> */}
            </div>

            <div className="d-flex justify-content-evenly">
              <div className="d-flex m-3">
                {/* <button
                                        className="btn btn-secondary btn-air-secondary mr-4"
                                        type="button"
                                        onClick={ToggleAddRowModal}
                                    >
                                        {" "}
                                        Add Row{" "}
                                    </button> */}

                {/* <button
                                        className="btn btn-secondary btn-air-secondary mr-4"
                                        type="button"
                                        onClick={deleteRow2}
                                    >
                                        {" "}
                                        Delete Row{" "}
                                    </button> */}

                <div>
                  <button
                    className="btn btn-primary btn-air-primary mr-3"
                    type="button"
                    onClick={scaleGraph}
                  >
                    {" "}
                    Show Graph{" "}
                  </button>
                </div>

                <AddRow
                  ToggleAddRowModal={ToggleAddRowModal}
                  ScrewAddRowModal={ScrewAddRowModal}
                  addRow={addRow}
                  increaseRow={increaseRow}
                />
              </div>

              <div className="form-group" style={{ width: "200px" }}>
                <label className="lbl_design"> Y-Axis: </label>
                <select
                  className="form-control digits"
                  id="Cold-Column"
                  onChange={setGrid}
                  onClick={(e) => setGrid2(`value${e.target.value}`)}
                >
                  <option value={null}>
                    {" "}
                    {column.length > 0 ? "Select Cavity" : ""}{" "}
                  </option>
                  {column.map((value, key) => (
                    <React.Fragment key={key}>
                      {value.id === 0 || value.id === 1 ? (
                        "-"
                      ) : (
                        <option value={key === 0 ? key + 1 : key}>
                          {" "}
                          {value.header}{" "}
                        </option>
                      )}
                    </React.Fragment>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-evenly">
          <div className="m-1">
            <PostMoldGrid
              PostMoldSpreadsheet={PostMoldSpreadsheet}
              column={column}
              ColToBeDeleted={ColToBeDeleted}
              setColToBeDeleted={setColToBeDeleted}
              PostMoldGridData={PostMoldGridData}
              setPostMoldGridData={setPostMoldGridData}
              colCount={colCount}
              setColCount={setColCount}
              setArgs={setArgs}
              PopulateMoldSheet={PopulateMoldSheet}
              rowCount={rowCount}
              rowToBeDeleted={rowToBeDeleted}
              setRowToBeDeleted={setRowToBeDeleted}
              setGrid2={setGrid2}
              setGridHeader={setGridHeader}
            />
          </div>

          <div>
            <div className="m-1">
              <ChartComponent
                border={{ width: 1, color: "darkblue" }}
                width="700px"
                height="350"
                primaryXAxis={{
                  title: "Time",
                  minimum: TimeniceNumbers.min,
                  maximum: TimeniceNumbers.max,
                  interval: TimeniceNumbers.step,
                }}
                primaryYAxis={{
                  title: GridHeader || "",
                  minimum: WeightniceNumbers.min,
                  maximum: WeightniceNumbers.max,
                  interval: WeightniceNumbers.step,
                }}
              >
                <Inject
                  services={[LineSeries, Category, DataLabel, StripLine]}
                />

                <SeriesCollectionDirective>
                  {/* chartData is the name of the Array which contains our data and again grid2 will be varying */}
                  <SeriesDirective
                    type="Line"
                    fill="rgb(2,0,4)"
                    width={2.5}
                    dataSource={chartData}
                    xName="minutes"
                    yName={grid2 ? grid2 : "value2"}
                    marker={{ visible: true }}
                  ></SeriesDirective>
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostMold;
