import React, { useEffect } from "react";
import "../../App.css";
import "../../../assets/custom-stylesheet/grid_stylecss.css";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  getCell,
  // getCellIndexes,
  // getCellAddress,
} from "@syncfusion/ej2-react-spreadsheet";

import { getRangeIndexes } from "@syncfusion/ej2-spreadsheet";

const BackMainGrid = ({
  column,
  BackPressureSpreadsheet,
  setColToBeDeleted,
  BackPressureGridData,
  setBackPressureGridData,
  colCount,
  setArgs,
  setAverage,
  setRange,
  setMaxPart,
  setMinPart,
  PopulateMainSheet,
  RenderHeaders,
  setColCount,
}) => {
  const protectSettings = { selectCells: true };

  const scrollSettings = { isFinite: true };

  let isPaste = false;

  // useEffect Hook to do the calculation of the data entered in the Grid
  useEffect(() => {
    const Total_Average = () => {
      const calculateAverage = (arr) => {
        if (arr.length === 0) {
          return 0;
        }

        const sum = arr.reduce((acc, num) => acc + num, 0);
        return sum;
      };

      let columnAverage = [],
        columnRange = [],
        columnMaxPart = [],
        columnMinPart = [];

      for (let i = 1; i <= column.length; i++) {
        let total = 0,
          average = 0,
          range,
          Range_Array = [],
          max,
          min;

        const compare = (a, b) => {
          return a - b;
        };

        for (let j = 1; j <= BackPressureGridData.length; j++) {
          if (parseFloat(BackPressureGridData[j - 1][`value${i}`])) {
            Range_Array.push(
              parseFloat(BackPressureGridData[j - 1][`value${i}`])
            );
          }

          total = calculateAverage(Range_Array);

          average = Number(
            parseFloat(total) / parseInt(Range_Array.length)
          ).toFixed(3);

          const Sorted_Array = Range_Array.sort(compare);

          range = Number(
            Sorted_Array[Sorted_Array.length - 1] -
              Sorted_Array[Sorted_Array.length - Sorted_Array.length]
          ).toFixed(2);

          max = Sorted_Array[Sorted_Array.length - 1];

          min =
            Sorted_Array[Sorted_Array.length - Sorted_Array.length] === 0
              ? Sorted_Array[Sorted_Array.length - Sorted_Array.length + 1]
              : Sorted_Array[Sorted_Array.length - Sorted_Array.length];
        }

        columnAverage[i - 1] = parseFloat(average);
        setAverage(columnAverage);

        columnRange[i - 1] = parseFloat(range);
        setRange(columnRange);

        columnMaxPart[i - 1] = max;
        setMaxPart(columnMaxPart);

        columnMinPart[i - 1] = min;
        setMinPart(columnMinPart);
      }
    };

    Total_Average();

    // eslint-disable-next-line
  }, [
    BackPressureGridData,
    column,
    column.length,
    setAverage,
    setMaxPart,
    setMinPart,
    setRange,
  ]);

  const getIndex = () => {
    let dataArray = [];

    // Getting the index of the selected row and storing in a variable
    let cell = BackPressureSpreadsheet.current.getActiveSheet().activeCell;

    let cellIdx = getRangeIndexes(cell);

    if (column.length < 2) {
      setColToBeDeleted(null);
    } else {
      setColToBeDeleted(cellIdx[1]);
    }

    // Getting the data from the BackPressureSpreadsheet cells
    let dataObj = {};

    var usedRowIdx =
      BackPressureSpreadsheet.current.getActiveSheet().usedRange.rowIndex;

    for (let i = 0; i < usedRowIdx + 1; i++) {
      for (let j = 1; j <= column.length; j++) {
        dataObj[`value${j}`] = !getCell(
          i,
          j - 1,
          BackPressureSpreadsheet.current.getActiveSheet()
        ).value
          ? null
          : getCell(i, j - 1, BackPressureSpreadsheet.current.getActiveSheet())
              .value;
      }

      dataArray.push(dataObj);

      dataObj = {};
    }

    setBackPressureGridData(dataArray);

    // console.log(dataArray)
  };

  const cellEditing = (args) => {
    if (args.value !== args.oldValue && !isNaN(args.value)) {
      BackPressureSpreadsheet.current.updateCell(
        { value: args.value },
        args.address
      );

      getIndex();
    }
  };

  const onCreated = () => {
    BackPressureSpreadsheet.current.refresh();

    //Applies data validation to specified range in the active sheet.
    BackPressureSpreadsheet.current.addDataValidation(
      {
        type: "Decimal",
        operator: "Between",
        value1: "-1000.0",
        value2: "1000.0",
      },
      `A1:Z${BackPressureGridData.length}`
    );
  };

  function beforeCellUpdate(args) {
    // Skip the cell update for paste action that contains characters, If you need you can skip this for all type of actions performed in the spreadsheet.
    if (isPaste) {
      let pastedValue = args.cell.value;

      // Match alphabets and special characters
      var regex = /[a-zA-Z]/g;

      if (pastedValue && pastedValue.toString().match(regex)) {
        args.cancel = true;
      }

      isPaste = false;
    }
  }

  function actionBegin(args) {
    if (args.args.eventArgs && args.args.eventArgs.requestType === "paste") {
      isPaste = true;
    }

    if (
      args.action === "clipboard" &&
      args.args.eventArgs.requestType === "paste"
    ) {
      //Set the type to 'Values' to paste only the values.
      args.args.eventArgs.type = "Values";
    }
  }

  const dialogBeforeOpen = (args) => {
    if (args.dialogName === "ValidationErrorDialog") {
      // args.content = 'Characters are not allowed in Number Column';
      args.cancel = true;
    }
  };

  function UnlockCells() {
    
    PopulateMainSheet();

    RenderHeaders();

    setColCount(column.length);

    BackPressureSpreadsheet.current.lockCells(
      `A1:Z${BackPressureGridData.length}`,
      false
    );

    BackPressureSpreadsheet.current.setColumnsWidth(120, ["A:Z"]);
  }

  const beforeCellRenderHandler = (args) => {
    setColCount(column.length);

    setArgs(args);

    RenderHeaders();
  };

  return (
    <div>
      <div
        className="spreadsheet"
        onMouseEnter={UnlockCells}
        onMouseLeave={getIndex}
      >
        <SpreadsheetComponent
          className="backGrid"
          onBlur={getIndex}
          cellEdited={getIndex}
          cellEditing={cellEditing}
          ref={BackPressureSpreadsheet}
          width={"100%"}
          height={250}
          showFormulaBar={false}
          showSheetTabs={false}
          showRibbon={false}
          scrollSettings={scrollSettings}
          beforeCellRender={beforeCellRenderHandler}
          created={onCreated}
          allowAutoFill={false}
          beforeCellUpdate={beforeCellUpdate.bind(this)}
          actionBegin={actionBegin.bind(this)}
          dialogBeforeOpen={dialogBeforeOpen.bind(this)}
          enableContextMenu={false}
          allowImage={false}
        >
          <SheetsDirective>
            <SheetDirective
              rowCount={10}
              colCount={colCount}
              isProtected={true}
              protectSettings={protectSettings}
            ></SheetDirective>
          </SheetsDirective>
        </SpreadsheetComponent>
      </div>
    </div>
  );
};

export default BackMainGrid;
