import React, { useState, useRef, useEffect } from "react";
import {
  HtmlEditor,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { nanoid } from "nanoid";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "reactstrap";
// Hook to get the parameter's from the URL
import { useParams } from "react-router-dom";
// Syncfusion chart control
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { getCell } from "@syncfusion/ej2-react-spreadsheet";
import BackEditHeader from "./BackEditHeader";
import BackAddColumn from "./BackAddColumn";
import BackMainGrid from "./BackMainGrid";
import BackCalcPressure from "./BackCalcPressureGrid";
import DataService from "../../../services/ApiService";
import Loader from "../../../assets/Loader";

const BackPressure = ({ showAlert, BackData, ToSaveBackData }) => {
  let BackPressureSpreadsheet = useRef();

  let BackPressureCalcSpreadsheet = useRef();

  // To store the session Id getting from URL
  const [SessionId, setSessionId] = useState();

  // Getting session Id from URL
  var { sessionId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  // State and Event for the Edit and Add Modal
  const [modal, setModal] = useState();
  const [modal2, setModal2] = useState();

  const toggle = () => {
    setModal(!modal);
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };

  // State and Event for the comment modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  };

  // A state variable to store and set the value of textarea
  const [Comment, setComment] = useState("");

  const [Back_Press_Units, setBack_Press_Units] = useState("ppsi");

  // To store column header data
  const [column, setColumn] = useState([
    {
      id: 0,
      header: "Back Press 1",
    },
    {
      id: 1,
      header: "Back Press 2",
    },
    {
      id: 2,
      header: "Back Press 3",
    },
    {
      id: 3,
      header: "Back Press 4",
    },
  ]);

  const [header, setHeader] = useState();

  const [BackPressureGridData, setBackPressureGridData] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);

  const [toggleEdit, setToggleEdit] = useState(true);

  const [isColumnId, setIsColumnId] = useState(null);

  const [ColToBeDeleted, setColToBeDeleted] = useState();

  const [colCount, setColCount] = useState(column.length);

  const [ColHeader, seColHeader] = useState("");

  const [args, setArgs] = useState("");

  let [Average, setAverage] = useState([]);
  let [Range, setRange] = useState([]);
  let [MaxPart, setMaxPart] = useState([]);
  let [MinPart, setMinPart] = useState([]);

  const PopulateMainSheet = () => {
    const alphabetArray = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    for (let j = 1; j <= column.length; j++) {
      for (let i = 0; i < BackPressureGridData.length; i++) {
        BackPressureSpreadsheet.current.updateCell(
          { value: BackPressureGridData[i][`value${j}`] },
          `${alphabetArray[j - 1]}${i + 1}`
        );

        // console.log(data[i][`value${j - 1}`], `${alphabetArray[j - 1]}${i}`)
      }
    }
  };

  const RenderHeaders = () => {
    // Condition to check whether the rendered element is header cell.
    for (let i = 0; i < column.length; i++) {
      if (
        args.colIndex === i &&
        args.element.classList.contains("e-header-cell")
      ) {
        const text = column[i].header;
        args.element.innerText = text;
      }
    }

    // console.log(args)
  };

  const addHeader = (e) => {
    e.preventDefault();

    setHeader(e.target.value);
  };

  const addColumn = () => {
    if (!header) {
    } else {
      const newColumn = { id: nanoid(), header: header };

      setColumn([...column, newColumn]);

      setColCount(colCount + 1);

      setHeader("");
    }
  };

  const editColumnHeader = () => {
    if (header && !toggleEdit) {
      setColumn(
        column.map((element) => {
          if (element.id === isColumnId) {
            return { ...element, header: header };
          }
          return element;
        })
      );

      BackPressureSpreadsheet.current.refresh();

      RenderHeaders();

      setHeader("");
    } else {
    }
  };

  const editColumn = (id) => {
    setIsColumnId(id);
    setToggleEdit(false);
  };

  const editCancel = () => {
    setIsColumnId(null);
    setToggleEdit(true);
  };

  // State to Toggle the Delete Confirmation Modal.
  const [DeleteAlertToggle, setDeleteAlertToggle] = useState(false);
  const [DeleteConfirmToggle, setDeleteConfirmToggle] = useState(false);

  const DeleteModalAlertToggle = () => {
    setDeleteAlertToggle(!DeleteAlertToggle);
  };

  const DeleteModalConfirmToggle = () => {
    if (column.length < 2) {
      setDeleteAlertToggle(!DeleteAlertToggle);
    } else {
      setDeleteConfirmToggle(!DeleteConfirmToggle);
      seColHeader(column[ColToBeDeleted].header);
    }
  };

  const getDatafromsheet = () => {
    let dataArray = [],
      dataObj = {};

    for (let i = 0; i < BackPressureGridData.length; i++) {
      for (let j = 1; j <= column.length; j++) {
        dataObj[`value${j}`] = !getCell(
          i,
          j - 1,
          BackPressureSpreadsheet.current.getActiveSheet()
        ).value
          ? ""
          : getCell(i, j - 1, BackPressureSpreadsheet.current.getActiveSheet())
              .value;
      }

      dataArray.push(dataObj);

      dataObj = {};
    }

    setBackPressureGridData(dataArray);

    // console.log(dataArray)
  };

  const deleteColumn = () => {
    BackPressureSpreadsheet.current.delete(
      ColToBeDeleted,
      ColToBeDeleted,
      "Column"
    );

    setColumn((prevArray) => {
      const newArray = [...prevArray];

      newArray.splice(ColToBeDeleted, 1);

      return newArray;
    });

    setColCount(colCount - 1);

    for (let j = 1; j < column.length; j++) {
      for (let i = 0; i < BackPressureGridData.length; i++) {
        delete BackPressureGridData[i][`value${ColToBeDeleted}`];
        // console.log(BackPressureGridData[i][`value${ColToBeDeleted}`])
      }
    }

    getDatafromsheet();

    BackPressureSpreadsheet.current.refresh();

    setDeleteConfirmToggle(!DeleteConfirmToggle);

    // console.log(dataArray)
  };

  // Event to set the data entered in the textarea
  const getComment = (e) => {
    if (e.target) {
      setComment(e.target.value);
    } else {
      setComment(e.value); // Get the RTE value
      // setAlert(true)
    }
  };

  const [ChartDataArray, setChartDataArray] = useState([]);

  function calculateNiceNumbers(min, max, numTicks) {
    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
      niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
      niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
      niceStep = 5 * magnitudePow;
    } else {
      niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from(
      { length: numNiceTicks },
      (_, i) => niceMin + i * niceStep
    );

    return {
      min: niceMin,
      max: niceMax,
      step: niceStep,
      ticks: niceTicks,
    };
  }

  const [RangeNiceNumbers, setRangeNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const [LowestRange, setLowestRange] = useState("N/A");

  const [IndexofLowestRange, setIndexofLowestRange] = useState("");

  // Event to set the Min, Max and Interval of graph i.e scalling the graph
  const scaleGraph = () => {
    if (Range.length > 0) {
      let lowestRange = Math.min(...Range);
      let indexOfLowest = Range.indexOf(lowestRange);

      let highestRange = Math.max(...Range);

      let TempDataArray = [],
        TempDataObj = {};

      for (let i = 0; i < Range.length; i++) {
        if (Range[i]) {
          TempDataObj = {
            id: nanoid(),
            header: column[i].header,
            Range: Range[i],
          };
        } else {
        }

        TempDataArray.push(TempDataObj);
      }

      setChartDataArray(TempDataArray);
      setIndexofLowestRange(indexOfLowest);
      setLowestRange(lowestRange);

      // console.log(`Minimum Range: ${LowestRange} grams at ${column[IndexofLowestRange].header} ${Back_Press_Units} back pressure`, indexOfLowest)

      setRangeNiceNumbers(calculateNiceNumbers(lowestRange, highestRange, 5));

      // console.log(TempDataArray, lowestRange, highestRange, column[indexOfLowest].header, RangeNiceNumbers)
    } else {
    }
  };

  const [ToPlotChart, setToPlotChart] = useState(true);

  useEffect(() => {
    if (ToPlotChart) scaleGraph();

    // eslint-disable-next-line
  }, [BackPressureGridData, Range]);

  useEffect(() => {
    const data = {
      session: SessionId,
      Column_Details: column,
      Column_Data: BackPressureGridData,
      Comment: Comment ? Comment : "N/A",
    };

    showAlert.current = true;

    BackData.current = data;

    // eslint-disable-next-line
  }, [BackPressureGridData]);

  // Event the GET current session's cavity data
  const handleGet = (SessionId) => {
    if (SessionId) {
      DataService.GetBackPressureData(SessionId)
        .then((res) => {
          if (res.data[0]) {
            ToSaveBackData.current = false;

            setColumn(res.data[0].Column_Details);
            setBackPressureGridData(res.data[0].Column_Data);
            setComment(res.data[0].Comment);

            setColCount(res.data[0].Column_Details.length);

            // console.log(res.data[0])
          } else {
            setColumn([
              {
                id: 0,
                header: "Back Press 1",
              },
              {
                id: 1,
                header: "Back Press 2",
              },
              {
                id: 2,
                header: "Back Press 3",
              },
              {
                id: 3,
                header: "Back Press 4",
              },
            ]);
            setBackPressureGridData([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
          }
        })
        .catch((err) => {});
    } else {
      setColumn([
        {
          id: 0,
          header: "Back Press 1",
        },
        {
          id: 1,
          header: "Back Press 2",
        },
        {
          id: 2,
          header: "Back Press 3",
        },
        {
          id: 3,
          header: "Back Press 4",
        },
      ]);
      setBackPressureGridData([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    }
  };

  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));

    handleGet(SessionId);

    RenderHeaders();

    // console.log(SessionId)

    // eslint-disable-next-line
  }, [sessionId, SessionId]);

  useEffect(() => {
    // Simulate loading for a few seconds (you can replace this with your actual loading logic)
    setTimeout(() => {
      setIsLoading(false)
    }, 1500);
  }, []);

  return (
    <div className="m-8">
        
      {/* {isLoading ? <Loader /> : <></>} */}

      <div>
        <Modal isOpen={DeleteAlertToggle} centered={true}>
          <ModalHeader toggle={DeleteModalAlertToggle}>
            Delete Alert.
          </ModalHeader>

          <ModalBody>
            {column.length > 2
              ? "Select a column to delete"
              : "Atleast one column is mandatory."}
          </ModalBody>

          <ModalFooter>
            <Button color="dark" onClick={DeleteModalAlertToggle}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={DeleteConfirmToggle} centered={true}>
          <ModalHeader toggle={DeleteModalConfirmToggle}>
            Delete Confirmation.
          </ModalHeader>

          <ModalBody>
            {ColHeader
              ? `Are you sure you want to delete ${ColHeader} column ?`
              : ""}
          </ModalBody>

          <ModalFooter>
            <Button color="dark" onClick={deleteColumn}>
              {" "}
              Delete{" "}
            </Button>
            <Button color="dark" onClick={DeleteModalConfirmToggle}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <section className="card meltPreparation p-3 ml-2">
        <div className="b-primary b-r-4 mb-2">
          <div className="pt-3 pb-2 pr-2 pl-1">
            <div className="d-flex col-md-12 mb-2">
              <div className="d-flex mr-2">
                <div>
                  <label htmlFor="Temp_Units" className="lbl_design pt-2">
                    {" "}
                    Back Press Units:{" "}
                  </label>
                </div>
                <div>
                  <select
                    className="ml-2 form-control"
                    style={{ width: "150px" }}
                    onChange={(e) => setBack_Press_Units(e.target.value)}
                    name="Back_Press_Units"
                  >
                    <option> psi </option>
                    <option> ppsi </option>
                    <option> Mpa </option>
                    <option> Bar </option>
                  </select>
                </div>
              </div>

              <div>
                <BackAddColumn
                  toggle2={toggle2}
                  modal2={modal2}
                  addHeader={addHeader}
                  addColumn={addColumn}
                />
              </div>

              <div>
                <BackEditHeader
                  modal={modal}
                  toggle={toggle}
                  column={column}
                  addHeader={addHeader}
                  editColumnHeader={editColumnHeader}
                  editCancel={editCancel}
                  editColumn={editColumn}
                />
              </div>

              <div>
                <button
                  className="btn btn-warning btn-air-warning mr-2"
                  type="button"
                  onClick={DeleteModalConfirmToggle}
                >
                  {" "}
                  Delete Column{" "}
                </button>
              </div>

              <div>
                <button
                  className="btn btn-primary btn-air-primary mr-2"
                  onClick={handleShow}
                  type="button"
                >
                  Comment
                </button>
              </div>

              <div>
                <button
                  className="btn btn-primary btn-air-primary mr-2"
                  onClick={scaleGraph}
                >
                  {" "}
                  Show Graph{" "}
                </button>

                <Modal isOpen={show} centered>
                  <ModalHeader toggle={handleClose}>Add Comment</ModalHeader>
                  <ModalBody>
                    <RichTextEditorComponent
                      change={getComment}
                      value={Comment}
                      saveInterval="1"
                      toolbarSettings={toolbarSettings}
                      height={250}
                    >
                      <Inject services={[Toolbar, HtmlEditor]} />
                    </RichTextEditorComponent>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="dark" onClick={handleClose}>
                      {" "}
                      Save & Close{" "}
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>

            <div>
              <div className="d-flex m-2">
                <div className="mb-2 col-md-6" style={{ width: "50%" }}>
                  <div>
                    <div className="m-2" onClick={() => setToPlotChart(false)}>
                      <BackMainGrid
                        BackPressureSpreadsheet={BackPressureSpreadsheet}
                        column={column}
                        setColToBeDeleted={setColToBeDeleted}
                        BackPressureGridData={BackPressureGridData}
                        setBackPressureGridData={setBackPressureGridData}
                        colCount={colCount}
                        setColCount={setColCount}
                        setArgs={setArgs}
                        RenderHeaders={RenderHeaders}
                        PopulateMainSheet={PopulateMainSheet}
                        setAverage={setAverage}
                        setRange={setRange}
                        setMaxPart={setMaxPart}
                        setMinPart={setMinPart}
                      />
                    </div>

                    <div>
                      <BackCalcPressure
                        BackPressureCalcSpreadsheet={
                          BackPressureCalcSpreadsheet
                        }
                        colCount={colCount}
                        Average={Average}
                        MaxPart={MaxPart}
                        MinPart={MinPart}
                        Range={Range}
                        column={column}
                        BackPressureGridData={BackPressureGridData}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="mt-2 mb-2 col-md-6"
                  style={{ border: "1px solid #573DAC", width: "50%" }}
                >
                  <div>
                    {LowestRange && IndexofLowestRange ? (
                      <span
                        style={{
                          color: "red",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        {" "}
                        Minimum Range: {LowestRange} grams at{" "}
                        {column[IndexofLowestRange].header} {Back_Press_Units}{" "}
                        back pressure{" "}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="ml-4">
                    <ChartComponent
                      className="backGrid"
                      width="100%"
                      height="420"
                      tooltip={{ enable: true }}
                      title="Range Vs Back Pressure"
                      primaryXAxis={{
                        title: `Back Pressure`,
                        valueType: "Category",
                      }}
                      primaryYAxis={{
                        title: `Weight Range`,
                        minimum: RangeNiceNumbers.min,
                        maximum: RangeNiceNumbers.max,
                        interval: RangeNiceNumbers.step,
                      }}
                    >
                      <Inject
                        services={[LineSeries, Category, DataLabel, Tooltip]}
                      />

                      <SeriesCollectionDirective>
                        <SeriesDirective
                          dataSource={ChartDataArray}
                          type="Line"
                          xName="header"
                          yName="Range"
                          marker={{ visible: true }}
                          fill="rgb(255,0,0)"
                          width={2.5}
                        ></SeriesDirective>
                      </SeriesCollectionDirective>
                    </ChartComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="card" style={{ border: "1px solid #573DAC" }}></div>
    </div>
  );
};

export default BackPressure;
