import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Button, Table } from "reactstrap";
import DataService from "../../../services/ApiService";

const DefineResponse = () => {
  const history = useHistory();
  const location = useLocation();
  const { sessionId } = useParams();
  const [sessionid, setSessionid] = useState();
  const [numRows, setNumRows] = useState(1);
  const [data, setData] = useState([{}]);
  const [moldData, setMoldData] = useState({});
  const [responseData, setResponseData] = useState([{}]);

  const mold = location.state.mold;
  // console.log("response mold", mold);

  useEffect(() => {
    DataService.GetMold(mold).then((res) => {
      setMoldData(res.data);
      // console.log("getmold response", res);
    });
    setSessionid(atob(sessionId));
  }, [sessionid]);

  const addRow = () => {
    setNumRows(numRows + 1);
    setData([...data, {}]);
  };

  const deleteRow = () => {
    if (numRows > 1) {
      setNumRows(numRows - 1);
      const newData = [...data];
      newData.pop();
      setData(newData);
    }
  };

  // console.log("molddata");

  const partData = moldData["Part_Data"];
  // console.log("partdata", partData);

  // Define partValue within the component
  let partValue = [];

  if (partData && partData.length > 0) {
    // Find the object with Cavity_Data: 'Part Description *'
    const partnumberObject = partData.find(
      (item) => item.Cavity_Data === "Part Number *"
    );

    const partRegex = /^Part\d+$/;

    // Find the key in partDescriptionObject that matches the pattern
    partValue = Object.keys(partnumberObject)
      .filter((key) => partRegex.test(key))
      .map((key) => partnumberObject[key]);

    // console.log("partValue", partValue);
  } else {
    console.log("partData is undefined or empty.");
  }

  const handleCellChange = (e, rowIndex, columnName) => {
    const newData = [...data];
    let newValue = e.target.value;

    if (partValue.includes(columnName)) {
      newValue = e.target.checked;
    }

    newData[rowIndex] = { ...newData[rowIndex], [columnName]: newValue };
    setData(newData);
  };

  const getUserData = () => {
    const userData = data.map((rowData) => {
      const userDataEntry = {
        response_name: rowData.responseName || "",
        nom: rowData.nom || "",
        plus_tol: rowData.plusTOL || "",
        minus_tol: rowData.minusTOL || "",
        samplesize: rowData.sampleSize || "",
      };

      // Add partValue fields to userDataEntry
      partValue.forEach((part) => {
        userDataEntry[part] = rowData[part] || false;
      });

      return userDataEntry;
    });

    return userData;
  };

  const userEnteredData = getUserData(); //working fine
  console.log("userentered data", userEnteredData);

  const handleSave = () => {
    // const isValid = userEnteredData.every((data) => partValue.some((part) => data[part]));
    const isValid = userEnteredData.every((data) => {
      const checkedParts = partValue.filter((part) => data[part]);
      return checkedParts.length === 1;
    });

    const isValidNumericFields = userEnteredData.every(
      (data) =>
        !isNaN(data.nom) &&
        !isNaN(data.plus_tol) &&
        !isNaN(data.minus_tol) &&
        !isNaN(data.samplesize)
    );

    if (!isValidNumericFields) {
      alert(
        "Please make sure Nom, + TOL, - TOL, and Sample Size are numeric for each row before submitting."
      );
      return;
    } else if (!isValid) {
      alert("Please select cavity for each row before submitting.");
      return;
    } else {
      setResponseData(userEnteredData);
    }
  };

  const submitData = async () => {
    var decry_SessionID = atob(sessionId);
    let tempdata = {
      session_id: decry_SessionID,
      response_details: responseData,
    };

    DataService.PostResponse(decry_SessionID, mold, tempdata)
      .then((res) => {
        console.log("Response from PostResponse API", res);
        var Session_Id = btoa(sessionid);

        history.push({
          pathname: `/dashboard/${Session_Id}/DOE/summary`,
          state: { mold: mold, session: decry_SessionID },
        });
      })
      .catch((err) => {
        console.log("Response from PostResponse API=>Error!!", err);
      });
  };

  // Map over partValue to generate <th> elements
  const thElements = partValue.map((part, index) => (
    <th key={index}>{part}</th>
  ));

  return (
    <div>
      <div style={{ overflowX: "auto" }}>
        <Table bordered>
          <thead>
            <tr>
              <th>No.</th>
              <th>Response Name</th>
              <th>Nom</th>
              <th>+ TOL</th>
              <th>- TOL</th>
              <th>Sample Size</th>
              {thElements}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: numRows }).map((_, rowIndex) => (
              <tr key={rowIndex}>
                <td>{rowIndex + 1}</td>
                <td>
                  <input
                    type="text"
                    value={data[rowIndex]?.responseName || ""}
                    onChange={(e) =>
                      handleCellChange(e, rowIndex, "responseName")
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={data[rowIndex]?.nom || ""}
                    onChange={(e) => handleCellChange(e, rowIndex, "nom")}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={data[rowIndex]?.plusTOL || ""}
                    onChange={(e) => handleCellChange(e, rowIndex, "plusTOL")}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={data[rowIndex]?.minusTOL || ""}
                    onChange={(e) => handleCellChange(e, rowIndex, "minusTOL")}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={data[rowIndex]?.sampleSize || ""}
                    onChange={(e) =>
                      handleCellChange(e, rowIndex, "sampleSize")
                    }
                  />
                </td>
                {/* <td>
                  <input
                    type="checkbox"
                    value={data[rowIndex]?.abc || true}
                    onChange={(e) => handleCellChange(e, rowIndex, "abc")}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    value={data[rowIndex]?.xyz || true}
                    onChange={(e) => handleCellChange(e, rowIndex, "xyz")}
                  />
                </td> */}
                {partValue.map((part, index) => (
                  <td key={index}>
                    <input
                      type="checkbox"
                      value={data[rowIndex]?.[part] || true}
                      onChange={(e) => handleCellChange(e, rowIndex, part)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Button onClick={addRow}>Add Row</Button>
      <Button onClick={deleteRow}>Delete Row</Button>
      <Button onClick={handleSave}>Save</Button>
      <Button onClick={submitData}>Submit</Button>
    </div>
  );
};

export default DefineResponse;
