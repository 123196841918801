import React, { useEffect } from "react";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-spreadsheet";
import { getRangeIndexes } from "@syncfusion/ej2-spreadsheet";
import "../../../assets/custom-stylesheet/grid_stylecss.css";

const InjGrid = ({
  rowCount,
  InjSpeedGridData,
  setInjSpeedGridData,
  InjSpeedSpreadsheet,
  setRowToBeDeleted,
  PopulateInjSpeedSheet,
  Shot_Size,
  Suck_Back,
  TransferPosition, showAlert
}) => {
  const protectSettings = { selectCells: true };

  const scrollSettings = {
    isFinite: true,
    enableVirtualization: false,
  };

  let isPaste = false;

  const dialogBeforeOpen = (args) => {
    if (args.dialogName === "EditAlertDialog") {
      args.cancel = true;
      // args.content = 'This cell is read only';
    }

    // Edit the dialog content using the dialogBeforeOpen event.
    if (args.dialogName === "ValidationErrorDialog") {
      args.cancel = true;
    }
  };

  const GetValues = () => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      // JavaScript
      const tdElements = document.querySelectorAll("#Inj_Speed_Sheet .e-cell");

      tdElements.forEach((td) => {
        const cellValue = td.textContent;

        if (cellValue) {
          TempArray.push(cellValue);
        } else {
          TempArray.push(null);
        }
      });

      resolve(TempArray);

      // console.log(TempArray)
    });
  };

  const ConvertToJson = (DataArray) => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      let NumberOfRow = 0;

      for (let i = 0; i < Math.ceil(DataArray.length / 5); i++) {
        TempArray.push({
          "Injection Speed (units/sec)": DataArray[0 + NumberOfRow]
            ? DataArray[0 + NumberOfRow]
            : "",
          "Displayed Fill Time (sec)": DataArray[1 + NumberOfRow]
            ? DataArray[1 + NumberOfRow]
            : "",
          "Actual Calculated Speed (units/sec)": "",
          "Expected Calculated Fill Time (sec)": "",
          "Variation in actual Speed from set Speed (%)": null,
        });

        NumberOfRow = NumberOfRow + 5;
      }

      resolve(TempArray);

      // console.table(TempArray)
    });
  };

  const PerformCalcs = (TabelObjData) => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      for (let i = 0; i < TabelObjData.length; i++) {
        TempArray.push({
          "Injection Speed (units/sec)": TabelObjData[i][
            "Injection Speed (units/sec)"
          ]
            ? parseFloat(TabelObjData[i]["Injection Speed (units/sec)"])
            : "",

          "Displayed Fill Time (sec)": TabelObjData[i][
            "Displayed Fill Time (sec)"
          ]
            ? parseFloat(TabelObjData[i]["Displayed Fill Time (sec)"])
            : "",

          "Actual Calculated Speed (units/sec)": TabelObjData[i][
            "Displayed Fill Time (sec)"
          ]
            ? parseFloat(
                Number(
                  (parseFloat(Shot_Size) +
                    parseFloat(Suck_Back) -
                    parseFloat(TransferPosition)) /
                    parseFloat(TabelObjData[i]["Displayed Fill Time (sec)"])
                ).toFixed(4)
              )
            : "",

          "Expected Calculated Fill Time (sec)": TabelObjData[i][
            "Injection Speed (units/sec)"
          ]
            ? parseFloat(
                Number(
                  (parseFloat(Shot_Size) +
                    parseFloat(Suck_Back) -
                    parseFloat(TransferPosition)) /
                    parseFloat(TabelObjData[i]["Injection Speed (units/sec)"])
                ).toFixed(4)
              )
            : "",

          "Variation in actual Speed from set Speed (%)":
            TabelObjData[i]["Injection Speed (units/sec)"] &&
            TabelObjData[i]["Displayed Fill Time (sec)"]
              ? parseFloat(
                  Number(
                    ((parseFloat(
                      TabelObjData[i]["Injection Speed (units/sec)"]
                    ) -
                      parseFloat(
                        Number(
                          (parseFloat(Shot_Size) +
                            parseFloat(Suck_Back) -
                            parseFloat(TransferPosition)) /
                            parseFloat(
                              TabelObjData[i]["Displayed Fill Time (sec)"]
                            )
                        ).toFixed(4)
                      )) /
                      parseFloat(
                        TabelObjData[i]["Injection Speed (units/sec)"]
                      )) *
                      100
                  ).toFixed(2)
                )
              : null,
        });
      }

      resolve(TempArray);

      // console.log(TempArray)
    });
  };

  function onCreated() {
    //Applies data validation to specified range in the active sheet.
    InjSpeedSpreadsheet.current.addDataValidation(
      {
        type: "Decimal",
        operator: "Between",
        value1: "-10000.0",
        value2: "10000.0",
      },
      `A1:C${InjSpeedSpreadsheet.current.getActiveSheet().rowCount}`
    );

    InjSpeedSpreadsheet.current.lockCells(
      `A1:B${InjSpeedSpreadsheet.current.getActiveSheet().rowCount}`,
      false
    );

    PopulateInjSpeedSheet(InjSpeedGridData);
  }

  const cellEditing = (args) => {
    if (args.value !== args.oldValue && !isNaN(args.value)) {
      InjSpeedSpreadsheet.current.updateCell(
        { value: args.value },
        args.address
      );

      getData();
    }
  };

  const UnlockCells = () => {
    InjSpeedSpreadsheet.current.lockCells(
      `A1:B${InjSpeedSpreadsheet.current.getActiveSheet().rowCount}`,
      false
    );

    InjSpeedSpreadsheet.current.getActiveSheet().rowCount =
      rowCount > 0 ? rowCount : 10;

    // PopulateInjSpeedSheet(InjSpeedGridData)
  };

  const getData = () => {
    // Getting the index of the selected row and storing in a variable
    let cell = InjSpeedSpreadsheet.current.getActiveSheet().activeCell;

    let cellIdx = getRangeIndexes(cell);

    setRowToBeDeleted(cellIdx[0]);

    GetValues().then((DataArray) => {
      ConvertToJson(DataArray).then((TabelObjData) => {
        PerformCalcs(TabelObjData).then((CalcData) => {
          PopulateInjSpeedSheet(CalcData);

          setInjSpeedGridData(CalcData);

          showAlert.current = true;

          // console.log(CalcData)
        });
      });
    });
  };

  function beforeCellUpdate(args) {
    // Skip the cell update for paste action that contains characters, If you need you can skip this for all type of actions performed in the spreadsheet.
    if (isPaste) {
      let pastedValue = args.cell.value;

      // Match alphabets and special characters
      var regex = /[a-zA-Z]/g;

      if (pastedValue && pastedValue.toString().match(regex)) {
        args.cancel = true;
      }

      isPaste = false;
    }
  }

  function actionBegin(args) {
    if (args.args.eventArgs && args.args.eventArgs.requestType === "paste") {
      isPaste = true;
    }

    if (
      args.action === "clipboard" &&
      args.args.eventArgs.requestType === "paste"
    ) {
      //Set the type to 'Values' to paste only the values.
      args.args.eventArgs.type = "Values";
    }
  }

  const beforeCellRenderHandler = (args) => {
    // PopulateInjSpeedSheet(InjSpeedGridData);

    // Condition to check whether the rendered element is header cell.
    if (
      args.colIndex === 0 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Injection Speed (units/sec)";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 1 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Displayed Fill Time (sec)";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 2 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Actual Calculated Speed (units/sec)";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 3 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Expected Calculated Fill Time (sec)";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 4 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Variation in actual Speed from set Speed (%)";
      args.element.innerText = text;
    }
  };

  useEffect(() => {
    PopulateInjSpeedSheet(InjSpeedGridData);

    // eslint-disable-next-line
  }, [InjSpeedGridData]);

  return (
    <>
      <div
        className="spreadsheet"
        id="Inj_Speed_Sheet"
        onMouseEnter={UnlockCells}
      >
        <SpreadsheetComponent
          className="equipmentInjGrid"
          onClick={getData}
          cellEdited={getData}
          cellEditing={cellEditing}
          ref={InjSpeedSpreadsheet}
          height={220}
          width={"100%"}
          showFormulaBar={false}
          showSheetTabs={false}
          showRibbon={false}
          dialogBeforeOpen={dialogBeforeOpen.bind(this)}
          scrollSettings={scrollSettings}
          allowAutoFill={false}
          created={onCreated}
          beforeCellRender={beforeCellRenderHandler}
          beforeCellUpdate={beforeCellUpdate.bind(this)}
          actionBegin={actionBegin.bind(this)}
          enableContextMenu={false}
          allowImage={false}
        >
          <SheetsDirective>
            <SheetDirective
              colCount={5}
              isProtected={true}
              protectSettings={protectSettings}
            >
              <ColumnsDirective>
                <ColumnDirective width={110}></ColumnDirective>
                <ColumnDirective width={110}></ColumnDirective>
                <ColumnDirective width={140}></ColumnDirective>
                <ColumnDirective width={140}></ColumnDirective>
                <ColumnDirective width={140}></ColumnDirective>
              </ColumnsDirective>
            </SheetDirective>
          </SheetsDirective>
        </SpreadsheetComponent>
      </div>
    </>
  );
};

export default InjGrid;
