import React, { useState, useRef, useEffect } from 'react'
// import { nanoid } from 'nanoid'
import { Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useParams } from "react-router-dom";
import AddRow from './AddRow';
import { getCell } from '@syncfusion/ej2-react-spreadsheet';
import EditHeader from './EditHeader';
import AttributeGrid from './AttributeGrid';
import DataService from '../../../services/ApiService';
import { toast } from "react-toastify";
import Loader from '../../../assets/Loader';

const Attribute = ({ showAlert, ToSaveAttributeData, AttributeData }) => {

    let AttributeSpreadsheet = useRef()

    const [isLoading, setIsLoading] = useState(true);

    // To store the session Id getting from URL
    const [SessionId, setSessionId] = useState();

    // // Getting session Id from URL
    var { sessionId } = useParams();

    // State and Event for the Edit and Add Modal
    const [modal, setModal] = useState();

    const toggle = () => {

        setModal(!modal);

        AttributeSpreadsheet.current.refresh()

    }

    // To store column header data
    const [column, setColumn] = useState([
        {
            "id": 0,
            "header": "Cavity",
        },
        {
            "id": 1,
            "header": "Cavity 1",
        },
        {
            "id": 2,
            "header": "Cavity 2",
        },
        {
            "id": 3,
            "header": "Cavity 3",
        },
        {
            "id": 4,
            "header": "Cavity 4",
        },
        {
            "id": 5,
            "header": "Cavity 5",
        },
        {
            "id": 6,
            "header": "Cavity 6",
        },
        {
            "id": 7,
            "header": "Cavity 7",
        },
        {
            "id": 8,
            "header": "Cavity 8",
        },
        {
            "id": 9,
            "header": "Cavity 9",
        },
        {
            "id": 10,
            "header": "Cavity 10",
        }
    ]);

    const [header, setHeader] = useState();

    const [AttributeGridData, setAttributeGridData] = useState([]);

    const [toggleEdit, setToggleEdit] = useState(true);

    const [isColumnId, setIsColumnId] = useState(null);

    const [SelectedCol, setSelectedCol] = useState()

    let [AttributecolCount, setAttributecolCount] = useState(column.length)

    const [ColHeader, seColHeader] = useState("")

    const [args, setArgs] = useState("")

    const PopulateAttributeSheet = (data) => {

        const alphabetArray = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        for (let j = 1; j < AttributecolCount; j++) {

            for (let i = 1; i <= data.length; i++) {

                AttributeSpreadsheet.current.updateCell({ value: data[i - 1][`value${j}`] }, `${alphabetArray[j - 1]}${i + 1}`)

                if (data[i - 1][`value${j}`] === 'Pass') {
                    AttributeSpreadsheet.current.cellFormat({ color: 'Green' }, `${alphabetArray[j - 1]}${i + 1}`);
                }
                else if (data[i - 1][`value${j}`] === 'Fail') {
                    AttributeSpreadsheet.current.cellFormat({ color: 'Red' }, `${alphabetArray[j - 1]}${i + 1}`);
                }
                else {

                }

                // console.log(data.length, i)

            }

        }

    }

    const RenderHeaders = () => {

        // Condition to check whether the rendered element is header cell.
        for (let i = 0; i < column.length; i++) {

            if (
                args.colIndex === i &&
                args.element.classList.contains('e-header-cell')
            ) {
                const text = column[i].header
                args.element.innerText = text;
            }

        }

    }

    const addHeader = (e) => {

        e.preventDefault();

        setHeader(e.target.value);

    }

    // const addColumn = () => {

    //     const newColumn = { id: nanoid(), "header": `Cavity ${AttributecolCount}` }

    //     setColumn([...column, newColumn]);

    //     setAttributecolCount(AttributecolCount + 1)

    // };

    const editColumnHeader = () => {

        if (header && !toggleEdit) {

            setColumn(
                column.map((element) => {
                    if (element.id === isColumnId) {
                        return { ...element, header: header }
                    }
                    return element;
                })
            )

            RenderHeaders()

            setHeader("");

        }
        else {

        }

    }

    const editColumn = (id) => {
        setIsColumnId(id)
        setToggleEdit(false)
    }

    const editCancel = () => {
        setIsColumnId(null)
        setToggleEdit(true)
    }

    // State to Toggle the Delete Confirmation Modal.
    const [DeleteAlertToggle, setDeleteAlertToggle] = useState(false)
    const [DeleteConfirmToggle, setDeleteConfirmToggle] = useState(false)

    const DeleteModalAlertToggle = () => {

        setDeleteAlertToggle(!DeleteAlertToggle);
    }

    const DeleteModalConfirmToggle = () => {

        if (!SelectedCol || column.length <= 2) {
            setDeleteAlertToggle(!DeleteAlertToggle);
        }
        else {
            setDeleteConfirmToggle(!DeleteConfirmToggle);
            seColHeader(column[SelectedCol].header)
        }

    }

    const getDatafromsheet = () => {

        let dataArray = [], dataObj = {};

        for (let i = 0; i < AttributeGridData.length; i++) {

            for (let j = 0; j < column.length; j++) {

                dataObj[`value${j}`] = !getCell(i, j, AttributeSpreadsheet.current.getActiveSheet()).value ? '' : getCell(i, j, AttributeSpreadsheet.current.getActiveSheet()).value

            }

            dataArray.push(dataObj)

            dataObj = {}

        }

        setAttributeGridData(dataArray);

        // console.log(dataArray)

    }

    const deleteColumn = () => {

        AttributeSpreadsheet.current.delete(SelectedCol, SelectedCol, 'Column');

        setColumn(prevArray => {

            const newArray = [...prevArray];

            newArray.splice(SelectedCol, 1);

            return newArray;

        });

        for (let j = 1; j < column.length; j++) {

            for (let i = 0; i < AttributeGridData.length; i++) {
                delete AttributeGridData[i][`value${SelectedCol}`]
                // console.log(AttributeGridData[i][`value${SelectedCol}`])
            }

        }

        getDatafromsheet()

        AttributeSpreadsheet.current.refresh()

        setDeleteConfirmToggle(!DeleteConfirmToggle);

    }

    const [rowCount, setRowCount] = useState(50)

    const [SelectedRow, setSelectedRow] = useState()

    // Boolean variable to switch between the save and update button
    const [showSave, setShowSave] = useState(true);

    // As the user enter's the number of row's it get's set in this variable.
    const [row, setRow] = useState(2);

    // Set's the visibility of the modal which we use to get the number of row's to be generated which is imported in Grid.
    const [ScrewAddRowModal, setScrewAddRowModal] = useState();

    const ToggleAddRowModal = () => {

        setScrewAddRowModal(!ScrewAddRowModal);

    };

    const addRow = (e) => {

        e.preventDefault();

        // Storing the number entered 
        setRow(e.target.value);
        // console.log(e.target.value);

    };

    // This is the event which gets called as the user click's ok in the add row modal.
    // what it does is it run's a loop as many times the row variable is and along with that it pushes an object containing all the key's based on the grid with an id generated using nanoid library and then set's the row1 in the main array i.e ScrewRotationGridData.
    const increaseRow = () => {

        // Updating the total rows variable
        setRowCount(parseInt(rowCount) + parseInt(row))

        setRow(null)

    };

    // This is the event which deletes the row as clicked on the delete icon, id of the row gets passed and using filter method that row is filtered out.
    // const deleteRow2 = (id) => {

    //     AttributeSpreadsheet.current.delete(SelectedRow, SelectedRow, 'Row', 0);

    //     setRowCount(parseInt(rowCount) - 1)

    //     AttributeGridData.splice(SelectedRow, 1)

    //     // console.log(AttributeGridData)

    // };

    const saveData = () => {

        const data = {
            "session": SessionId,
            "Column_Data": AttributeGridData
        };

        // console.log(data)

        DataService.SaveAttributeData(data)
            .then((res) => {

                // console.log(res)

                setShowSave(false);

                toast("Data has been saved", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            })
            .catch((err) => { });

    };

    // Event to Call the PUT request API and update the data
    const UpdateData = () => {

        const data = {
            "session": SessionId,
            "Column_Data": AttributeGridData
        };

        // console.log(data)

        DataService.UpdateAttributeData(SessionId, data)
            .then((res) => {

                // console.log(res)
                setShowSave(false);

                toast("Data has been saved", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            })
            .catch((err) => { });

    };

    const GetData = (SessionId) => {

        DataService.GetAttributeData(SessionId)
            .then((res) => {

                // console.log(res)

                if (res.data[0]) {

                    ToSaveAttributeData.current = false

                    setAttributeGridData(res.data[0].Column_Data)

                    PopulateAttributeSheet(res.data[0].Column_Data)

                    // console.log(res.data[0].Column_Data.length)

                    setShowSave(false);

                } else {

                    setAttributeGridData([])

                }

            })
            .catch((err) => { });
    };

    useEffect(() => {
        const data = {
            "session": SessionId,
            "Column_Data": AttributeGridData
        };
    
        // console.log(data)
    
        showAlert.current = true;
    
        AttributeData.current = data;
    
        // eslint-disable-next-line
      }, [AttributeGridData]);

    useEffect(() => {

        // Simulate loading for a few seconds (you can replace this with your actual loading logic)
        setTimeout(() => {

            setIsLoading(false);

        }, 1000);

    }, []);

    // Event that will be called as soon as the Page load's so that if there is data available will get fetched
    useEffect(() => {

        // On load it decrypt's the session Id
        setSessionId(atob(sessionId));

        if (SessionId) {
            GetData(SessionId);
        }
        else {
        }

        // console.log(SessionId)

        // eslint-disable-next-line
    }, [sessionId, SessionId]);

    return (
        <div>

            {isLoading ? (
                <Loader />
            ) : <></>}

            <div>

                <Modal isOpen={DeleteAlertToggle} centered={true}>

                    <ModalHeader toggle={DeleteModalAlertToggle}>
                        Delete Alert.
                    </ModalHeader>

                    <ModalBody>
                        {column.length > 2 ? 'Select a column to delete' : 'Atleast one column is mandatory.'}
                    </ModalBody>

                    <ModalFooter>
                        <Button color="dark" onClick={DeleteModalAlertToggle}> Close </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={DeleteConfirmToggle} centered={true}>

                    <ModalHeader toggle={DeleteModalConfirmToggle}>
                        Delete Confirmation.
                    </ModalHeader>

                    <ModalBody>
                        {ColHeader ? `Are you sure you want to delete ${ColHeader} column ?` : ""}
                    </ModalBody>

                    <ModalFooter>

                        <Button color="dark" onClick={deleteColumn}> Delete </Button>
                        <Button color="dark" onClick={DeleteModalConfirmToggle}> Close </Button>

                    </ModalFooter>
                </Modal>

            </div>

            <div className="card p-3 ml-2 mt-2">

                <div className='b-primary b-r-4 mb-2'>

                    <div className="m-1">

                        <div className="mb-1 d-flex">

                            <div className="d-flex ml-3">
                                {/* <div className="grid_container_btn">
                                <button className="btn btn-secondary btn-air-secondary mr-4" type="button" onClick={addColumn}> Add Column </button>
                            </div> */}
                                <div className="mt-2">
                                    <EditHeader modal={modal} toggle={toggle} column={column} addHeader={addHeader} editColumnHeader={editColumnHeader} editCancel={editCancel} editColumn={editColumn} />
                                </div>
                                {/* <div>
                                <button className="btn btn-primary btn-air-primary mt-3 mr-2" type="button" onClick={DeleteModalConfirmToggle}> Delete Column </button>
                            </div> */}
                            </div>

                            <div>

                                <div className='d-flex mt-2'>

                                    <div>
                                        {/* <button
                                        className="btn btn-secondary btn-air-secondary mr-4"
                                        type="button"
                                        onClick={ToggleAddRowModal}
                                    >
                                        {" "}
                                        Add Row{" "}
                                    </button> */}

                                        {/* <button
                                        className="btn btn-secondary btn-air-secondary mr-4"
                                        type="button"
                                        onClick={deleteRow2}
                                    >
                                        {" "}
                                        Delete Row{" "}
                                    </button> */}

                                        <AddRow
                                            ToggleAddRowModal={ToggleAddRowModal}
                                            ScrewAddRowModal={ScrewAddRowModal}
                                            addRow={addRow}
                                            increaseRow={increaseRow}
                                        />
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='d-flex'>

                        <div className='ml-3 mt-3 col-md-11'>

                            <AttributeGrid AttributeSpreadsheet={AttributeSpreadsheet} column={column} SelectedCol={SelectedCol} setSelectedCol={setSelectedCol} AttributeGridData={AttributeGridData} setAttributeGridData={setAttributeGridData} AttributecolCount={AttributecolCount} setAttributecolCount={setAttributecolCount} setArgs={setArgs} PopulateAttributeSheet={PopulateAttributeSheet} rowCount={rowCount} SelectedRow={SelectedRow} setSelectedRow={setSelectedRow} />

                        </div>

                        {/* <div className='ml-3 mt-3 col-md-4'></div> */}

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Attribute;