const ProcessRefMachineData = [
  { "Molding Parameter": "Shot Size" },
  { "Molding Parameter": "Posn1" },
  { "Molding Parameter": "Posn2" },
  { "Molding Parameter": "Posn3" },
  { "Molding Parameter": "Posn4" },
  { "Molding Parameter": "Transfer" },
  { "Molding Parameter": "Obtained Cushion" },
  { "Molding Parameter": "Injection Velocity 1" },
  { "Molding Parameter": "Injection Velocity 2" },
  { "Molding Parameter": "Injection Velocity 3" },
  { "Molding Parameter": "Injection Velocity 4" },
  { "Molding Parameter": "Injection Velocity 5" },
  { "Molding Parameter": "Injection Pressure 1" },
  { "Molding Parameter": "Injection Pressure 2" },
  { "Molding Parameter": "Injection Pressure 3" },
  { "Molding Parameter": "Injection Pressure 4" },
  { "Molding Parameter": "Injection Pressure 5" },
  { "Molding Parameter": "Pack/Hold Pressure 1" },
  { "Molding Parameter": "Pack/Hold Pressure 2" },
  { "Molding Parameter": "Pack/Hold Pressure 3" },
  { "Molding Parameter": "Pack/Hold Pressure 4" },
  { "Molding Parameter": "Pack/Hold Pressure 5" },
  { "Molding Parameter": "Pack/Hold Pressure 6" },
  { "Molding Parameter": "Pack/Hold Time 1" },
  { "Molding Parameter": "Pack/Hold Time 2" },
  { "Molding Parameter": "Pack/Hold Time 3" },
  { "Molding Parameter": "Pack/Hold Time 4" },
  { "Molding Parameter": "Pack/Hold Time 5" },
  { "Molding Parameter": "Pack/Hold Time 6" },
  { "Molding Parameter": "Screw Recovery Speed (rpm)" },
  { "Molding Parameter": "Back Pressure" },
  { "Molding Parameter": "Cooling Time" },
  { "Molding Parameter": "Mold Temperature - A side" },
  { "Molding Parameter": "Mold Temperature - B side" },
  { "Molding Parameter": "Fill Time" },
  { "Molding Parameter": "Inj Only Shot Weight" },
  { "Molding Parameter": "Actual Peak Injection Pressure" },
  { "Molding Parameter": "Cycle Time" },
  { "Molding Parameter": "Melt Temperature" },
];

const RowDataIndex = {
  shotSizeIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Shot Size"
  ),
  posn1Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Posn1"
  ),
  posn2Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Posn2"
  ),
  posn3Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Posn3"
  ),
  posn4Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Posn4"
  ),
  transferIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Transfer"
  ),
  obtainedCushionIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Obtained Cushion"
  ),
  injectionVelocity1Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Injection Velocity 1"
  ),
  injectionVelocity2Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Injection Velocity 2"
  ),
  injectionVelocity3Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Injection Velocity 3"
  ),
  injectionVelocity4Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Injection Velocity 4"
  ),
  injectionVelocity5Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Injection Velocity 5"
  ),
  injectionPressure1Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Injection Pressure 1"
  ),
  injectionPressure2Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Injection Pressure 2"
  ),
  injectionPressure3Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Injection Pressure 3"
  ),
  injectionPressure4Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Injection Pressure 4"
  ),
  injectionPressure5Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Injection Pressure 5"
  ),
  packHoldPressure1Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Pressure 1"
  ),
  packHoldPressure2Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Pressure 2"
  ),
  packHoldPressure3Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Pressure 3"
  ),
  packHoldPressure4Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Pressure 4"
  ),
  packHoldPressure5Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Pressure 5"
  ),
  packHoldPressure6Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Pressure 6"
  ),
  packHoldTime1Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Time 1"
  ),
  packHoldTime2Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Time 2"
  ),
  packHoldTime3Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Time 3"
  ),
  packHoldTime4Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Time 4"
  ),
  packHoldTime5Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Time 5"
  ),
  packHoldTime6Index: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Pack/Hold Time 6"
  ),
  screwRecoverySpeedIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Screw Recovery Speed (rpm)"
  ),
  backPressureIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Back Pressure"
  ),
  coolingTimeIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Cooling Time"
  ),
  moldTemperatureASideIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Mold Temperature - A side"
  ),
  moldTemperatureBSideIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Mold Temperature - B side"
  ),
  fillTimeIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Fill Time"
  ),
  injOnlyShotWeightIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Inj Only Shot Weight"
  ),
  actualPeakInjectionPressureIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Actual Peak Injection Pressure"
  ),
  cycleTimeIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Cycle Time"
  ),
  meltTemperatureIndex: ProcessRefMachineData.findIndex(
    (item) => item["Molding Parameter"] === "Melt Temperature"
  ),
};

const CreateProcessMachineData = ({
  PSData,
  StoredUnits,
  setProcessRefMachineData,
}) => {

  // console.log(
  //   PSData[0]?.VPS_Data.Units.PressureUnit,
  //   PSData[0]?.VPS_Data.Units.DistanceUnit,
  //   PSData[0]?.VPS_Data.Units.TemperatureUnit,
  //   PSData[0]?.VPS_Data.Units.VelosityUnit,
  //   PSData[0]?.VPS_Data.Units.TimeUnit,
  //   PSData[0]?.VPS_Data.Units.WeightUnit
  // )

  return new Promise((resolve, reject) => {
    if (PSData.length > 0) {
      setProcessRefMachineData((prevData) => {
        let UpdatedData = [...prevData];

        let Cycle_Time_Data = {
          value: PSData[0].VPS_Data.Outputs[0].fldCycleTime.paramValue,
          unit: StoredUnits?.Unit_Data[1].find(
            (unit) =>
              unit.unit_id ===
              parseInt(PSData[0]?.VPS_Data.Units.TimeUnit)
          )?.unit_name,
        };

        let Cooling_Time_Data = {
          value: PSData[0].VPS_Data.Inputs[0].fldCoolingTime
            ? PSData[0].VPS_Data.Inputs[0].fldCoolingTime.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].fldCoolingTime
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.TimeUnit
                )
            )?.unit_name
            : "NA",
        };

        let Melt_Temp_Data = {
          value: PSData[0].VPS_Data.Outputs[0].fldMeltTemp
            ? PSData[0].VPS_Data.Outputs[0].fldMeltTemp.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Outputs[0].fldMeltTemp
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(PSData[0]?.VPS_Data.Units.TemperatureUnit)
            )?.unit_name
            : "NA",
        };

        let Inj_Only_Shot_Weight_Data = {
          value: PSData[0].VPS_Data.Outputs[0].fldInjOnlyShotWeight
            ? PSData[0].VPS_Data.Outputs[0].fldInjOnlyShotWeight.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Outputs[0].fldInjOnlyShotWeight
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.WeightUnit
                )
            )?.unit_name
            : "NA",
        };

        let Fill_Time_Data = {
          value: PSData[0].VPS_Data.Outputs[0].fldFillTime
            ? PSData[0].VPS_Data.Outputs[0].fldFillTime.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Outputs[0].fldFillTime
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(PSData[0]?.VPS_Data.Units.TimeUnit)
            )?.unit_name
            : "NA",
        };

        let Back_Pressure_Data = {
          value: PSData[0].VPS_Data.Inputs[0].fldBackPress
            ? PSData[0].VPS_Data.Inputs[0].fldBackPress.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].fldBackPress
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(PSData[0]?.VPS_Data.Units.PressureUnit)
            )?.unit_name
            : "NA",
        };

        let Peak_Pressure_Data = {
          value: PSData[0].VPS_Data.Outputs[0].fldInjPressPeak
            ? PSData[0].VPS_Data.Outputs[0].fldInjPressPeak.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Outputs[0].fldInjPressPeak
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let Melt_TempA_Data = {
          value: PSData[0].VPS_Data.Inputs[0].MoldTempASide[0].fldMoldTempASide1
            ? PSData[0].VPS_Data.Inputs[0].MoldTempASide[0].fldMoldTempASide1
              .paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].MoldTempASide[0].fldMoldTempASide1
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.TemperatureUnit
                )
            )?.unit_name
            : "NA",
        };

        let Melt_TempB_Data = {
          value: PSData[0].VPS_Data.Inputs[0].MoldTempBSide[0].fldMoldTempBSide1
            ? PSData[0].VPS_Data.Inputs[0].MoldTempBSide[0].fldMoldTempBSide1
              .paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].MoldTempBSide[0].fldMoldTempBSide1
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.TemperatureUnit
                )
            )?.unit_name
            : "NA",
        };

        let Screw_Recovery_Data = {
          value: PSData[0].VPS_Data.Inputs[0].fldScrewRotationSpeed
            ? PSData[0].VPS_Data.Inputs[0].fldScrewRotationSpeed.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].fldScrewRotationSpeed
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0].VPS_Data.Inputs[0].fldScrewRotationSpeed.paramUnit
                )
            )?.unit_name
            : "NA",
        };

        let Injection_Velocity_Data1 = {
          value: PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed1
            ? PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed1
              .paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed1.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed1
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.VelosityUnit
                )
            )?.unit_name
            : "NA",
        };

        let Injection_Velocity_Data2 = {
          value: PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed2
            ? PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed2
              .paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed2.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed2
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.VelosityUnit
                )
            )?.unit_name
            : "NA",
        };

        let Injection_Velocity_Data3 = {
          value: PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed3
            ? PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed3
              .paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed3.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed3
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.VelosityUnit
                )
            )?.unit_name
            : "NA",
        };

        let Injection_Velocity_Data4 = {
          value: PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed4
            ? PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed4
              .paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed4.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed4
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.VelosityUnit
                )
            )?.unit_name
            : "NA",
        };

        let Injection_Velocity_Data5 = {
          value: PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed5
            ? PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed5
              .paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed5.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed5
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.VelosityUnit
                )
            )?.unit_name
            : "NA",
        };

        let Injection_Press_Data1 = {
          value: PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress1
            ? PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress1
              .paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress1.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress1
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let Injection_Press_Data2 = {
          value: PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress2
            ? PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress2
              .paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress2.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress2
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };
        let Injection_Press_Data3 = {
          value: PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress3
            ? PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress3
              .paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress3.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress3
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let Injection_Press_Data4 = {
          value: PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress4
            ? PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress4
              .paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress4.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress4
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let Injection_Press_Data5 = {
          value: PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress5
            ? PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress5
              .paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress5.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].InjPress[0].fldInjPress5
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let PH_Press_Data1 = {
          value: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress1
            ? PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress1.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress1.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress1
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let PH_Press_Data2 = {
          value: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress2
            ? PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress2.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress2.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress2
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let PH_Press_Data3 = {
          value: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress3
            ? PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress3.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress3.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress3
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let PH_Press_Data4 = {
          value: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress4
            ? PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress4.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress4.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress4
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let PH_Press_Data5 = {
          value: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress5
            ? PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress5.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress5.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress5
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let PH_Press_Data6 = {
          value: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress6
            ? PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress6.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress6.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHPress[0].fldPHPress6
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.PressureUnit
                )
            )?.unit_name
            : "NA",
        };

        let Hold_Time_Data1 = {
          value: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime1
            ? PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime1.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime1.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime1
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.TimeUnit
                )
            )?.unit_name
            : "NA",
        };

        let Hold_Time_Data2 = {
          value: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime2
            ? PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime2.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime2.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime2
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.TimeUnit
                )
            )?.unit_name
            : "NA",
        };

        let Hold_Time_Data3 = {
          value: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime3
            ? PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime3.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime3.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime3
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.TimeUnit
                )
            )?.unit_name
            : "NA",
        };

        let Hold_Time_Data4 = {
          value: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime4
            ? PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime4.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime4.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime4
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.TimeUnit
                )
            )?.unit_name
            : "NA",
        };
        let Hold_Time_Data5 = {
          value: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime5
            ? PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime5.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime5.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime5
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.TimeUnit
                )
            )?.unit_name
            : "NA",
        };
        let Hold_Time_Data6 = {
          value: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime6
            ? PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime6.paramValue ===
              ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime6.paramValue
            : 0,
          unit: PSData[0].VPS_Data.Inputs[0].PHTime[0].fldPHTime6
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.TimeUnit
                )
            )?.unit_name
            : "NA",
        };

        let Cushion_Data = {
          value: PSData[0].VPS_Data.Inputs[0].fldObtainedCushion.paramValue
            ? PSData[0].VPS_Data.Inputs[0].fldObtainedCushion.paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].fldObtainedCushion.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].fldObtainedCushion.paramUnit
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.DistanceUnit
                )
            )?.unit_name
            : "NA",
        };

        let Shot_Size_Data = {
          value: PSData[0].VPS_Data.Inputs[0].fldShotSize.paramValue
            ? PSData[0].VPS_Data.Inputs[0].fldShotSize.paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].fldShotSize.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].fldShotSize.paramUnit
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(PSData[0]?.VPS_Data.Units.DistanceUnit)
            )?.unit_name
            : "NA",
        };

        let Transfer_Data = {
          value: PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
            .fldTransferPosition1.paramValue
            ? PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
              .fldTransferPosition1.paramValue === ""
              ? "NA"
              : PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
                .fldTransferPosition1.paramValue
            : "NA",
          unit: PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
            .fldTransferPosition1.paramUnit
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.DistanceUnit
                )
            )?.unit_name
            : "NA",
        };

        let Posn1_Data = {
          value: PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
            .fldTransferPosition2.paramValue
            ? PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
              .fldTransferPosition2.paramValue === ""
              ? 0
              : PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
                .fldTransferPosition2.paramValue
            : 0,
          unit: PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
            .fldTransferPosition2.paramUnit
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.DistanceUnit
                )
            )?.unit_name
            : "NA",
        };

        let Posn2_Data = {
          value: PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
            .fldTransferPosition3.paramValue
            ? PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
              .fldTransferPosition3.paramValue === ""
              ? 0
              : PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
                .fldTransferPosition3.paramValue
            : 0,
          unit: PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
            .fldTransferPosition3.paramUnit
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.DistanceUnit
                )
            )?.unit_name
            : "NA",
        };

        let Posn3_Data = {
          value: PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
            .fldTransferPosition4.paramValue
            ? PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
              .fldTransferPosition4.paramValue === ""
              ? 0
              : PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
                .fldTransferPosition4.paramValue
            : 0,
          unit: PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
            .fldTransferPosition4.paramUnit
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.DistanceUnit
                )
            )?.unit_name
            : "NA",
        };

        let Posn4_Data = {
          value: PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
            .fldTransferPosition5.paramValue
            ? PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
              .fldTransferPosition5.paramValue === ""
              ? 0
              : PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
                .fldTransferPosition5.paramValue
            : 0,
          unit: PSData[0].VPS_Data.Inputs[0].TransferPosition[0]
            .fldTransferPosition5.paramUnit
            ? StoredUnits?.Unit_Data[1].find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  PSData[0]?.VPS_Data.Units.DistanceUnit
                )
            )?.unit_name
            : "NA",
        };

        UpdatedData[RowDataIndex.cycleTimeIndex] = Object.assign(
          UpdatedData[RowDataIndex.cycleTimeIndex],
          Cycle_Time_Data
        );
        UpdatedData[RowDataIndex.coolingTimeIndex] = Object.assign(
          UpdatedData[RowDataIndex.coolingTimeIndex],
          Cooling_Time_Data
        );
        UpdatedData[RowDataIndex.meltTemperatureIndex] = Object.assign(
          UpdatedData[RowDataIndex.meltTemperatureIndex],
          Melt_Temp_Data
        );
        UpdatedData[RowDataIndex.injOnlyShotWeightIndex] = Object.assign(
          UpdatedData[RowDataIndex.injOnlyShotWeightIndex],
          Inj_Only_Shot_Weight_Data
        );
        UpdatedData[RowDataIndex.fillTimeIndex] = Object.assign(
          UpdatedData[RowDataIndex.fillTimeIndex],
          Fill_Time_Data
        );
        UpdatedData[RowDataIndex.backPressureIndex] = Object.assign(
          UpdatedData[RowDataIndex.backPressureIndex],
          Back_Pressure_Data
        );
        UpdatedData[RowDataIndex.actualPeakInjectionPressureIndex] =
          Object.assign(
            UpdatedData[RowDataIndex.actualPeakInjectionPressureIndex],
            Peak_Pressure_Data
          );
        UpdatedData[RowDataIndex.moldTemperatureASideIndex] = Object.assign(
          UpdatedData[RowDataIndex.moldTemperatureASideIndex],
          Melt_TempA_Data
        );
        UpdatedData[RowDataIndex.moldTemperatureBSideIndex] = Object.assign(
          UpdatedData[RowDataIndex.moldTemperatureBSideIndex],
          Melt_TempB_Data
        );
        UpdatedData[RowDataIndex.screwRecoverySpeedIndex] = Object.assign(
          UpdatedData[RowDataIndex.screwRecoverySpeedIndex],
          Screw_Recovery_Data
        );

        UpdatedData[RowDataIndex.packHoldPressure1Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldPressure1Index],
          PH_Press_Data1
        );
        UpdatedData[RowDataIndex.packHoldPressure2Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldPressure2Index],
          PH_Press_Data2
        );
        UpdatedData[RowDataIndex.packHoldPressure3Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldPressure3Index],
          PH_Press_Data3
        );
        UpdatedData[RowDataIndex.packHoldPressure4Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldPressure4Index],
          PH_Press_Data4
        );
        UpdatedData[RowDataIndex.packHoldPressure5Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldPressure5Index],
          PH_Press_Data5
        );
        UpdatedData[RowDataIndex.packHoldPressure6Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldPressure6Index],
          PH_Press_Data6
        );

        UpdatedData[RowDataIndex.injectionVelocity1Index] = Object.assign(
          UpdatedData[RowDataIndex.injectionVelocity1Index],
          Injection_Velocity_Data1
        );
        UpdatedData[RowDataIndex.injectionVelocity2Index] = Object.assign(
          UpdatedData[RowDataIndex.injectionVelocity2Index],
          Injection_Velocity_Data2
        );
        UpdatedData[RowDataIndex.injectionVelocity3Index] = Object.assign(
          UpdatedData[RowDataIndex.injectionVelocity3Index],
          Injection_Velocity_Data3
        );
        UpdatedData[RowDataIndex.injectionVelocity4Index] = Object.assign(
          UpdatedData[RowDataIndex.injectionVelocity4Index],
          Injection_Velocity_Data4
        );
        UpdatedData[RowDataIndex.injectionVelocity5Index] = Object.assign(
          UpdatedData[RowDataIndex.injectionVelocity5Index],
          Injection_Velocity_Data5
        );

        UpdatedData[RowDataIndex.injectionPressure1Index] = Object.assign(
          UpdatedData[RowDataIndex.injectionPressure1Index],
          Injection_Press_Data1
        );
        UpdatedData[RowDataIndex.injectionPressure2Index] = Object.assign(
          UpdatedData[RowDataIndex.injectionPressure2Index],
          Injection_Press_Data2
        );
        UpdatedData[RowDataIndex.injectionPressure3Index] = Object.assign(
          UpdatedData[RowDataIndex.injectionPressure3Index],
          Injection_Press_Data3
        );
        UpdatedData[RowDataIndex.injectionPressure4Index] = Object.assign(
          UpdatedData[RowDataIndex.injectionPressure4Index],
          Injection_Press_Data4
        );
        UpdatedData[RowDataIndex.injectionPressure5Index] = Object.assign(
          UpdatedData[RowDataIndex.injectionPressure5Index],
          Injection_Press_Data5
        );
        UpdatedData[RowDataIndex.packHoldTime1Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldTime1Index],
          Hold_Time_Data1
        );
        UpdatedData[RowDataIndex.packHoldTime2Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldTime2Index],
          Hold_Time_Data2
        );
        UpdatedData[RowDataIndex.packHoldTime3Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldTime3Index],
          Hold_Time_Data3
        );
        UpdatedData[RowDataIndex.packHoldTime4Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldTime4Index],
          Hold_Time_Data4
        );
        UpdatedData[RowDataIndex.packHoldTime5Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldTime5Index],
          Hold_Time_Data5
        );
        UpdatedData[RowDataIndex.packHoldTime6Index] = Object.assign(
          UpdatedData[RowDataIndex.packHoldTime6Index],
          Hold_Time_Data6
        );
        UpdatedData[RowDataIndex.shotSizeIndex] = Object.assign(
          UpdatedData[RowDataIndex.shotSizeIndex],
          Shot_Size_Data
        );
        UpdatedData[RowDataIndex.obtainedCushionIndex] = Object.assign(
          UpdatedData[RowDataIndex.obtainedCushionIndex],
          Cushion_Data
        );
        UpdatedData[RowDataIndex.transferIndex] = Object.assign(
          UpdatedData[RowDataIndex.transferIndex],
          Transfer_Data
        );
        UpdatedData[RowDataIndex.posn1Index] = Object.assign(
          UpdatedData[RowDataIndex.posn1Index],
          Posn1_Data
        );
        UpdatedData[RowDataIndex.posn2Index] = Object.assign(
          UpdatedData[RowDataIndex.posn2Index],
          Posn2_Data
        );
        UpdatedData[RowDataIndex.posn3Index] = Object.assign(
          UpdatedData[RowDataIndex.posn3Index],
          Posn3_Data
        );
        UpdatedData[RowDataIndex.posn4Index] = Object.assign(
          UpdatedData[RowDataIndex.posn4Index],
          Posn4_Data
        );
        return UpdatedData;
      });

      resolve(ProcessRefMachineData);
    }
  });
};

export default { ProcessRefMachineData, CreateProcessMachineData };
