import React, { useState, useEffect } from "react";
import Histogram from "./Histogram";
import ComputedStatistics from "./ComputedStatistics";
import Loader from "../../../assets/Loader";
import DropDownMenu from "./DropDownMenu";

const CapabilityAnalysis = ({
  PCSpreadsheet,
  SelectedColData,
  Bin_Interval,
  yMax,
  xstart,
  xend,
  ChartData,
  HeaderArray,
  PCSheetRowCount,
  setSelectedColData,
  CalculateBin,
  CreateChartData,
  setBin_Interval,
  setChartData,
  setSelectedColHeader,
  computeClicked,
  maxBinFrequency,
  setComputeClicked,
  SelectedColHeader,
  SelectedCol, isLoading, setIsLoading, data, setData, PerformCalcs, activeTab
}) => {

  return (
    <div>
      {isLoading ? <Loader /> : <></>}

      <div>
        <div className="card p-3 ml-2 mt-2">
          <div className="b-primary b-r-4 mb-2">
            <DropDownMenu
              id={"CapabilityAnalysisDropDown"}
              HeaderArray={HeaderArray}
              SelectedColHeader={SelectedColHeader}
              PCSheetRowCount={PCSheetRowCount}
              setIsLoading={setIsLoading}
              PCSpreadsheet={PCSpreadsheet}
              setSelectedColData={setSelectedColData}
              CalculateBin={CalculateBin}
              CreateChartData={CreateChartData}
              setBin_Interval={setBin_Interval}
              setChartData={setChartData}
              setComputeClicked={setComputeClicked}
              setSelectedColHeader={setSelectedColHeader}
              SelectedCol={SelectedCol}
              PerformCalcs={PerformCalcs}
            />
          </div>

          <div className="b-primary b-r-4 mb-2">
            <div className="pt-3 pb-2 pr-2 pl-1">
              <div className="d-flex m-2">
                <div className="mb-2 col-md-6">
                  <Histogram
                    SelectedColData={SelectedColData}
                    Bin_Interval={Bin_Interval}
                    yMax={yMax}
                    xstart={xstart}
                    xend={xend}
                    ChartData={ChartData}
                    computeClicked={computeClicked}
                    maxBinFrequency={maxBinFrequency}
                    setComputeClicked={setComputeClicked}
                    setData={setData}
                    data={data}
                    SelectedCol={SelectedCol}
                  />
                </div>

                <div className="mb-2 col-md-6">
                  <ComputedStatistics
                    data={data}
                    computeClicked={computeClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapabilityAnalysis;
