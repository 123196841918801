import Home from "../../../assets/Icons/Home.png";
import Compatibility from "../../../assets/Icons/CompabilityIcon.png";
import Database from "../../../assets/Icons/database.png";
import EquipmentQualification from "../../../assets/Icons/EquipmentQualification.png";
import Projects from "../../../assets/Icons/Projects.png";
import Calculator from "../../../assets/Icons/Calculator.png";
import Conversion from "../../../assets/Icons/Conversion.png";
import Settings from "../../../assets/Icons/Options.png";
import LearningCenter from "../../../assets/Icons/LearningCenter.png";

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    path: "/database/Database",
    title: "Database",
    icon: Database,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    path: "/compatibility/Compatible",
    title: "Compatibility",
    icon: Compatibility,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    path: "/mold",
    title: "Mold",
    icon: Projects,
    type: "link",
    badgeType: "primary",
    active: false,
    // children: [
    //   {
    //     path: "/dashboard/mold",
    //     title: "MoldList",
    //     icon: FilePlus,
    //     type: "link",
    //   },

    //   {
    //     path: "/dashboard/meltpreparation/sessions",
    //     title: "Melt Preparation",
    //     icon: FilePlus,
    //     type: "link",
    //   },
    //   {
    //     path: "/dashboard/session/",
    //     title: "Six Step Study",
    //     icon: FilePlus,
    //     type: "link",
    //   },
    //   {
    //     path: "/dashboard/DOE/doe-session",
    //     title: "DOE",
    //     icon: FilePlus,
    //     type: "link",
    //   },
    //   {
    //     path: "/dashboard/PPS/sessions",
    //     title: "11+2 Plasic Process Sheet",
    //     icon: FilePlus,
    //     type: "link",
    //   },
    //   {
    //     path: "/dashboard/qualitydata/sessions",
    //     title: "Quality Data",
    //     icon: FilePlus,
    //     type: "link",
    //   },
    //   {
    //     path: "/dashboard/qualitydata/sessions",
    //     title: "Water Auxillary hook up",
    //     icon: FilePlus,
    //     type: "link",
    //   },
    //   {
    //     path: "/dashboard/qualitydata/sessions",
    //     title: "Model Trouble Shooting",
    //     icon: FilePlus,
    //     type: "link",
    //   },

    //   {
    //     path: "/dashboard/qualitydata/sessions",
    //     title: "Documents",
    //     icon: FilePlus,
    //     type: "link",
    //   },
    // ],
  },

  {
    path: "/sessions/equipmentqualification",
    title: "Equipment Qualification",
    icon: EquipmentQualification,
    type: "link",
    badgeType: "primary",
    active: false,
  },

  {
    path: "/calculators",
    title: "Calculators",
    icon: Calculator,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    path: "/optionnsettings/OptionSetting",
    title: "Option & Settings",
    icon: Settings,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    path: "/conversions",
    title: "Conversions",
    icon: Conversion,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    path: "/LearningCenter",
    title: "Learning Center",
    icon: LearningCenter,
    type: "link",
    badgeType: "primary",
    active: false,
  },
];
