import React, { useEffect, useState } from "react";
import DataService from "../../../services/ApiService";
import "../../App.css";
import { Button } from "react-scroll";

function Summary({ session, mold }) {
  const [data, setData] = useState({ anova_table: null, results: null });

  const [selectedIndex, setSelectedIndex] = useState("");

  const [noOfDimensions, setnoOfDimensions] = useState([]);

  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    // Fetch the dropdown data
    DataService.GetDefineResponse(session, mold)
      .then((res) => {
        const dimensionJSON = res.data["dimension"];
        const dataArray = Array.isArray(dimensionJSON)
          ? dimensionJSON
          : [dimensionJSON];
        setnoOfDimensions(dataArray); // Populate the dropdown options
      })
      .catch((err) => {
        console.error("Error fetching dropdown data:", err);
      });
  }, [session, mold]); // Dependencies for re-fetching if `session` or `mold` changes

  const { p_values } = data.results || {};

  // Set Index upon selection from dropdown
  const handleIndexChange = (event) => {
    setSelectedIndex(parseInt(event.target.value, 10));
    setShowTable(false);
  };

  const GetSummary = () => {
    // API Call for Anova Table Data
    DataService.Anova(session)
      .then((res) => {
        setData(res.data);
        // console.log("anova tableresponse",res)
        setShowTable(true);
      })
      .catch((err) => {
        // console.error("Error sending data to Django:", err);
      });
  };

  // API => Post selected dropdown value
  const SelectDropdown = () => {
    return new Promise(async (resolve, reject) => {
      const selectedDimension = noOfDimensions[selectedIndex];
      if (!selectedDimension) return reject("No dimension selected");

      try {
        // const decry = atob(sessionId);
        DataService.SelectDropdown(session, {
          selected: selectedDimension,
        })
          .then((res) => {})
          .catch((err) => {
            // console.error("Error sending data to Django:", err);
          });

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleClick = () => {
    SelectDropdown() // Ensure dropdown data is sent first
      .then(() => {
        return GetSummary(); // Fetch ANOVA table data
      })
      .then(() => {
        setShowTable(true); // Display the table after fetching data
      })
      .catch((err) => {
        console.error("Error during API calls:", err);
      });
  };

  return (
    <div className="m-3">
      <div className="mb-2">
        {/* Select Cavity Dropdown */}
        <select value={selectedIndex} onChange={handleIndexChange}>
          <option value="" disabled>
            Select a dimension
          </option>
          {noOfDimensions.map((dimension, index) => (
            <option key={index} value={index}>
              {dimension.charAt(0).toUpperCase() + dimension.slice(1)}
            </option>
          ))}
        </select>
      </div>

      <Button onClick={handleClick}>Compute</Button>

      <div>
        {/* Anova Table */}

        {data.anova_table && showTable && (
          <>
            <table className="summary-table" id="anovatable">
              <thead>
                <tr>
                  <th></th>
                  <th colSpan={5} style={{ textAlign: "center" }}>
                    ANOVA Table
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                      width: "20%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Factors
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      width: "5%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Sum of Square
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      width: "3%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Degrees of Freedom
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      width: "5%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Mean Square
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      width: "5%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Fvalue
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      width: "5%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pvalue
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data.anova_table).map(
                  ([key, values], index) => {
                    const newKey = key.replace(":", "*").replace(/_/g, " ");
                    return (
                      <tr key={key}>
                        <td style={{ textAlign: "left" }}>{newKey}</td>
                        <td style={{ textAlign: "right" }}>
                          {Number(values.SS).toFixed(5)}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {parseInt(values.df)}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {Number(values.MS).toFixed(5)}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {Number(values.F).toFixed(5)}
                        </td>
                        {p_values && (
                          <td style={{ textAlign: "right" }}>
                            {Number(p_values[index]).toFixed(5)}
                          </td>
                        )}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>

            <br />
          </>
        )}
      </div>
    </div>
  );
}

export default Summary;
